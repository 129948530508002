import React, { useEffect, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useAppContext } from '../../../../context/context';

import {
  fetchEndOfDayReportSettings,
  updateEndOfDayReportSettings,
  updateState,
} from '../../../../redux/endOfDayReportSettingsSlice';

import Button from '../../../utilities/button/button';
import PageTitle from '../../../utilities/page-title/page-title';
import EndOfDayReportSettingsForm from './form/end-of-day-report-settings-form';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import styles from './end-of-day-report-settings.module.scss';

function EndOfDayReportSettings() {
  const dispatch = useDispatch();
  const { token } = useAppContext();

  const dayReportSettingsStatus = useSelector((state) => state.endOfDayReportSettings.status);
  const initialDayReportSettings = useSelector((state) => state.endOfDayReportSettings.initialEndOfDayReportSettings);
  const dayReportSettings = useSelector((state) => state.endOfDayReportSettings.endOfDayReportSettings);

  const hasUnsavedChanges = useMemo(
    () => JSON.stringify(dayReportSettings) !== JSON.stringify(initialDayReportSettings),
    [dayReportSettings, initialDayReportSettings],
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchEndOfDayReportSettings({ token }));
    }
  }, [dispatch, token]);

  const handleFormChange = (id, checked) => {
    const updatedDetails = {
      ...dayReportSettings,
      [id]: checked,
    };
    dispatch(updateState(updatedDetails));
  };

  const handleSubmit = () => {
    dispatch(updateEndOfDayReportSettings({ updatedDetails: dayReportSettings, token }));
  };

  return (
    <>
      <PageTitle name={'End of Day Report Settings'} />
      {dayReportSettingsStatus === 'loading' ? <BackdropLoading /> : null}
      <div className={styles.main_content}>
        <div className={styles.form}>
          <EndOfDayReportSettingsForm settings={dayReportSettings} onFormChange={handleFormChange} />
          <Button title="Save" onClick={handleSubmit} disabled={!hasUnsavedChanges} style={{ marginBottom: 0 }} />
        </div>
      </div>
    </>
  );
}

export default EndOfDayReportSettings;
