import { IoTrash } from 'react-icons/io5';
import { MdModeEditOutline } from 'react-icons/md';

import List from '../../../utilities/list/list';

import styles from './team-roles-list.module.scss';

const TeamRolesList = ({ roles = [], onEdit, onDelete, onAssign, isLoading }) => {
  const renderEmployees = (item) => <span>{item.employees?.map((employee) => employee.name).join(', ')}</span>;
  const renderAssignEmployee = (item) => (
    <span className={styles.assign_button} onClick={() => onAssign(item.id, 'employees')}>
      Assign Employee
    </span>
  );
  const renderAssignModule = (item) => (
    <span className={styles.assign_button} onClick={() => onAssign(item.id, 'module_permission')}>
      Assign Module
    </span>
  );

  const columnConfig = [
    {
      key: 'name',
      header: 'Role',
    },
    {
      key: 'employees',
      header: 'Employees with Role',
      render: renderEmployees,
      className: styles.employees_with_role_head,
      cellClassName: styles.employees_with_role,
    },
    { key: 'assign_employee', header: '', render: renderAssignEmployee },
    { key: 'assign_module', header: '', render: renderAssignModule },
  ];

  const rowActions = [
    { icon: <MdModeEditOutline />, onClick: onEdit, label: 'Edit employee', className: styles.row_actions_button },
    { icon: <IoTrash />, onClick: onDelete, label: 'Delete employee', className: styles.row_actions_button },
  ];

  return (
    <List
      data={roles}
      columnConfig={columnConfig}
      rowActions={rowActions}
      isLoading={isLoading}
      overrideActionHeadName="Edit Permission"
    />
  );
};

export default TeamRolesList;
