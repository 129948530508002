import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { useAppContext } from '../../../../context/context';

import { removeWorkingHours } from '../../../../redux/workingHoursSlice';
import { showModal, hideModal } from '../../../../redux/modalSlice';

import { IoTrashOutline } from 'react-icons/io5';
import { IoIosArrowDown } from 'react-icons/io';

import Selector from '../../../utilities/selector/selector';

import styles from './hours.module.scss';

const WorkingDayRow = ({
  id,
  initialName = '',
  initialStatus = false,
  initialOpeningTime = '09:00 AM',
  initialClosingTime = '05:00 PM',
  onFieldChange,
}) => {
  const dispatch = useDispatch();

  const { token, locationId } = useAppContext();

  const [name, setName] = useState(initialName);
  const [status, setStatus] = useState(initialStatus);
  const [openingTime, setOpeningTime] = useState(initialOpeningTime);
  const [closingTime, setClosingTime] = useState(initialClosingTime);

  const statusValues = [
    { value: true, label: 'Open' },
    { value: false, label: 'Closed' },
  ];

  const handleSelectStatus = (newValue) => {
    const newStatus = newValue === 'true';
    setStatus(newStatus);
    onFieldChange(id, { status: newStatus });
  };

  const handleChangeOpeningTime = (newValue) => {
    setOpeningTime(newValue);
    onFieldChange(id, { openingTime: newValue });
  };

  const handleChangeCLosingTime = (newValue) => {
    setClosingTime(newValue);

    onFieldChange(id, { closingTime: newValue });
  };

  const handleDelete = useCallback(() => {
    dispatch(
      showModal({
        modalId: 'modal-delete-hours',
        data: {
          type: 'confirmation',
          title: 'Delete Confirmation',
          message: 'Are you sure you want to delete this hours',
          actions: [
            {
              title: 'Delete',
              onAction: () => {
                dispatch(removeWorkingHours({ id, token }));
                dispatch(hideModal('modal-delete-hours'));
              },
            },
            {
              title: 'Cancel',
              button_variant: 'grey',
              onAction: () => dispatch(hideModal('modal-delete-hours')),
            },
          ],
        },
      }),
    );
  }, [dispatch, id, token]);

  return (
    <div className={styles.table_row} key={id}>
      <div className={styles.day_name}>
        <span>{name}</span>
      </div>
      <Selector
        icon={IoIosArrowDown}
        items={statusValues}
        selectedItem={status}
        onSelectItem={handleSelectStatus}
        initialPlaceholderVisibility={false}
        className={styles.status_selector}
      />
      <div className={styles.opening_time}>
        <input type="text" value={openingTime} onChange={(e) => handleChangeOpeningTime(e.target.value)} />
      </div>
      <div className={styles.closing_time}>
        <input type="text" value={closingTime} onChange={(e) => handleChangeCLosingTime(e.target.value)} />
      </div>
      <div className={styles.delete_icon}>
        <button type="button" onClick={() => handleDelete(id)}>
          <IoTrashOutline />
        </button>
      </div>
    </div>
  );
};

WorkingDayRow.propTypes = {
  id: PropTypes.number,
  initialName: PropTypes.string,
  initialStatus: PropTypes.bool,
  initialOpeningTime: PropTypes.string,
  initialClosingTime: PropTypes.string,
  onFieldChange: PropTypes.func,
};

export default WorkingDayRow;
