import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import HeaderReport from '../components/HeaderReport/header-report';
import NoRecords from "../components/NoRecords/no-records";
import TransactionsRange from './TransactionsRange';
import DateRange from '../components/DateRange/date-range';
import ViewDate from '../components/view-date';
import {displayTwoDigit, orderTypeName, displayCustomerName} from '../components/helper';
import {DropdownTypes, FiltersForReports} from "../components/OrderFilters";

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './deleted-transactions.module.scss';

function DeletedTransactions() {
    const navigate = useNavigate();
    // payment.order?.customer?.full_name
    const transactions = [
        {
            id: 1,
            created_at: '2021-07-01T00:00:00Z',
            offline_id: '123456',
            order_id: 1,
            payment_type: 'Cash',
            Card: {
                last4: '1234'
            },
            amount: 100,
            tips: 10,
            tax_amount: 5,
            employee: {
                full_name: 'John Doe'
            },
            customer: {
                first_name: 'Jane',
                last_name: 'Doe'
            },
            order: {
                customer: {
                    full_name: 'John Doe'
                }
            },
        },
        {
            id: 2,
            created_at: '2021-07-01T00:00:00Z',
            offline_id: '123456',
            order_id: 1,
            payment_type: 'Cash',
            Card: {
                last4: '1234'
            },
            amount: 100,
            tips: 10,
            tax_amount: 5,
            employee: {
                full_name: 'John Doe'
            },
            customer: {
                first_name: 'Jane',
                last_name: 'Doe'
            },
            order: {
                customer: {
                    full_name: 'John Doe'
                }
            },
        },
    ];

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const downloadPdf = () => {};
    const downloadCsv = () => {};

    const handleOrderDetails = (item) => {
        const path = item.order_id ? `/reporting/deleted_transaction_details/${item.id}` : '#'
        navigate(path);
    };

    function getLast4DigitOfCard(payment) {
        return payment.Card.last4;
    }

    function TemplateRow ({item, openOrderDetails}) {
        return (
            <tr key={item.id}>
                <td>
                    <div className="connected-printer text-left">
                        <ViewDate date={item.created_at}/>
                        <p>ID: {item.offline_id}</p>
                    </div>
                </td>
                <td>{item.id}</td>
                <td>{item.payment_type} {item.Card ? `(${getLast4DigitOfCard(item)})` : ''}</td>
                <td>{orderTypeName(item)}</td>
                <td>${displayTwoDigit(item.amount + item.tips)}</td>
                <td>${displayTwoDigit(item.tax_amount)}</td>
                <td>${displayTwoDigit(item.tips)}</td>
                <td>{item.employee ? item.employee.full_name : ''}</td>
                <td>{displayCustomerName(item)}</td>
                <td>
                    <a className="text-underline text-blue1" onClick={openOrderDetails}>Details</a>
                </td>
                <td>-</td>
            </tr>
        );
    };

    return (
        <div className={styles.contentWrapper}>
            <HeaderReport
                title={"Deleted Transactions"}
                downloadPdf={downloadPdf}
                downloadCsv={downloadCsv}
            />
            <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
                <FiltersForReports
                    dropdown={[
                        DropdownTypes.employee,
                        DropdownTypes.payment_type,
                        DropdownTypes.captured_id,
                        DropdownTypes.terminal_id,
                        DropdownTypes.order_type_id,
                    ]}
                />
                <div className="row">
                    <TransactionsRange
                        payment_amount_min={0}
                        payment_amount_max={0}
                        onChange={() => {}}
                    />
                </div>
            </div>
            <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
                <DateRange
                    setEndDate={setEndDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    startDate={startDate}
                />
            </div>
            <div className="row">
                <table className="table table-bordered">
                    <thead className="thead-light">
                    <tr>
                        <th>Created</th>
                        <th>Trans#</th>
                        <th>Tender Type</th>
                        <th>Order Type</th>
                        <th>Amount</th>
                        <th>Taxes and Fees</th>
                        <th>Tip Amount</th>
                        <th>Employee</th>
                        <th>Customer</th>
                        <th>Details</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {transactions.map((row) => <TemplateRow item={row} openOrderDetails={() => handleOrderDetails(row)} />)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DeletedTransactions;
