import SelectItem from './select-item';

import Button from '../../../../utilities/button/button';
import Input from '../../../../utilities/input/input';

import styles from './team-roles-modals.module.scss';

const EditModal = ({ id, roleItems, roleName, onItemClick, onSaveChanges, onNameChange, errors }) => {
  return (
    <div className={styles.modal_content}>
      <h3 className={styles.edit_title_modal}>Edit Role</h3>
      <div className={styles.edit_wrapper_modal}>
        <div className={styles.role_name_wrapper}>
          <Input
            value={roleName}
            type="text"
            label="Role Name"
            onChange={(e, _) => onNameChange(e.target.value)}
            required
            errors={errors.name?._errors}
          />
        </div>
        <SelectItem
          roleId={id}
          items={roleItems.find((module) => module.id === id).module_permission}
          itemName="Module"
          category="module_permission"
          handleItemClick={onItemClick}
        />
        <SelectItem
          roleId={id}
          items={roleItems.find((employee) => employee.id === id).employees}
          itemName="Employee"
          category="employees"
          handleItemClick={onItemClick}
        />
      </div>
      <Button title="Save Changes" onClick={() => onSaveChanges(id, 'edit')} />
    </div>
  );
};

export default EditModal;
