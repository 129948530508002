import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getLoyaltyPrograms,
  getLoyaltyProgram,
  addLoyaltyProgram,
  updateLoyaltyProgram,
  deleteLoyaltyProgram,
} from '../api/loyalty_programs';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchLoyalties = createAsyncThunk('loyaltyPrograms/fetchLoyalties', async ({ token }) => {
  const response = await getLoyaltyPrograms(token);
  return response.data;
});

export const fetchLoyalty = createAsyncThunk('loyaltyPrograms/fetchLoyalty', async ({ id, token }) => {
  const response = await getLoyaltyProgram(id, token);
  return response.data;
});

export const addLoyalty = createAsyncThunk(
  'loyaltyPrograms/addLoyalty',
  async ({ newLoyaltyProgramDetails, token }, { dispatch }) => {
    try {
      const response = await addLoyaltyProgram(newLoyaltyProgramDetails, token);
      dispatch(
        addNotification({
          message: 'Loyalty Program successfully created',
          status: 'succeeded',
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to create loyalty program, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateLoyalty = createAsyncThunk(
  'loyaltyPrograms/updateLoyalty',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateLoyaltyProgram(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Loyalty Program successfully updated',
          status: 'succeeded',
        }),
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to update loyalty program, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeLoyalty = createAsyncThunk('loyaltyPrograms/removeLoyalty', async ({ id, token }, { dispatch }) => {
  try {
    await deleteLoyaltyProgram(id, token);
    dispatch(
      addNotification({
        message: 'Loyalty Program successfully deleted',
        status: 'succeeded',
      }),
    );
    return id;
  } catch (error) {
    dispatch(
      addNotification({
        message: `Failed to delete loyalty program, ${error?.message}`,
        status: 'failed',
      }),
    );
    throw error;
  }
});

const initialState = {
  loyaltyPrograms: [],
  currentloyaltyProgram: {},
  queryDetails: {},
  status: 'idle',
  error: null,
};

const loyaltyProgramsSlice = createSlice({
  name: 'loyaltyPrograms',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action?.error.message;
    };

    builder
      .addCase(fetchLoyalties.pending, setLoading)
      .addCase(fetchLoyalties.fulfilled, (state, action) => {
        setSucceeded(state);
        state.loyaltyPrograms = action.payload;
      })
      .addCase(fetchLoyalties.rejected, setFailed)

      .addCase(fetchLoyalty.pending, setLoading)
      .addCase(fetchLoyalty.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentloyaltyProgram = action.payload;
      })
      .addCase(fetchLoyalty.rejected, setFailed)

      .addCase(addLoyalty.pending, setLoading)
      .addCase(addLoyalty.fulfilled, (state, action) => {
        setSucceeded(state);
        state.loyaltyPrograms.push(action.payload);
      })
      .addCase(addLoyalty.rejected, setFailed)

      .addCase(updateLoyalty.pending, setLoading)
      .addCase(updateLoyalty.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedDetails } = action.payload;
        const index = state.loyaltyPrograms.findIndex((program) => program.id === updatedDetails.id);

        if (index !== -1) {
          state.loyaltyPrograms[index] = updatedDetails;
        }
      })
      .addCase(updateLoyalty.rejected, setFailed)

      .addCase(removeLoyalty.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;
        state.loyaltyPrograms = state.loyaltyPrograms.filter((program) => program.id !== id);
      })
      .addCase(removeLoyalty.pending, setLoading)
      .addCase(removeLoyalty.rejected, setFailed);
  },
});

export const { setQueryDetails } = loyaltyProgramsSlice.actions;
export default loyaltyProgramsSlice.reducer;
