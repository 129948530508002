import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getCustomers, getCustomer, addCustomer, updateCustomer, deleteCustomer } from '../api/customers';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchCustomers = createAsyncThunk('customers/fetchCustomers', async ({ token, queryDetails }) => {
  const response = await getCustomers(token, queryDetails);

  // The phones and addresses differ in the response, so we need to transform them to match the format of the backend.
  return response.data.map((customer) => {
    const { phones, addresses, ...rest } = customer;
    return {
      ...rest,
      phones_attributes: phones,
      addresses_attributes: addresses,
    };
  });
});

export const fetchCustomersForLength = createAsyncThunk('customers/fetchCustomersForLength', async ({ token }) => {
  const response = await getCustomers(token);
  return response.data;
});

export const fetchCustomer = createAsyncThunk('customers/fetchCustomer', async ({ id, token }) => {
  const response = await getCustomer(id, token);
  return response.data;
});

export const addNewCustomer = createAsyncThunk(
  'customers/addNewCustomer',
  async ({ newCustomerDetails, token }, { dispatch }) => {
    try {
      const response = await addCustomer(newCustomerDetails, token);
      dispatch(
        addNotification({
          message: 'Customer successfully created',
          status: 'succeeded',
        }),
      );

      const { phones, addresses, ...rest } = response.data;

      return {
        ...rest,
        phones_attributes: phones,
        addresses_attributes: addresses,
      };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to create customer, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateExistingCustomer = createAsyncThunk(
  'customers/updateExistingCustomer',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateCustomer(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Customer successfully updated',
          status: 'succeeded',
        }),
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to update customer, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeCustomer = createAsyncThunk('customers/removeCustomer', async ({ id, token }, { dispatch }) => {
  try {
    await deleteCustomer(id, token);
    dispatch(
      addNotification({
        message: 'Customer successfully deleted',
        status: 'succeeded',
      }),
    );
    return id;
  } catch (error) {
    dispatch(
      addNotification({
        message: `Failed to delete customer, ${error?.message}`,
        status: 'failed',
      }),
    );
    throw error;
  }
});

const initialState = {
  customers: [],
  currentCustomer: {},
  numberOfAllCustomers: 0,
  queryDetails: {},
  status: 'idle',
  error: null,
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
    clearCurrentCustomer(state) {
      state.currentCustomer = {};
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    };

    builder
      .addCase(fetchCustomers.pending, setLoading)
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        setSucceeded(state);
        state.customers = action.payload;
      })
      .addCase(fetchCustomers.rejected, setFailed)

      .addCase(fetchCustomersForLength.fulfilled, (state, action) => {
        state.numberOfAllCustomers = action.payload.length;
      })

      .addCase(fetchCustomer.pending, setLoading)
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentCustomer = action.payload;
      })
      .addCase(fetchCustomer.rejected, setFailed)

      .addCase(addNewCustomer.pending, setLoading)
      .addCase(addNewCustomer.fulfilled, (state, action) => {
        setSucceeded(state);

        const maxPage = Math.ceil(state.numberOfAllCustomers / state.queryDetails.perPage);
        const exceedsPerPage =
          'perPage' in state.queryDetails && state.queryDetails.perPage > state.numberOfAllCustomers;
        const isLastIncomplete =
          state.queryDetails.page === maxPage && state.numberOfAllCustomers / state.queryDetails.perPage !== maxPage;

        if (exceedsPerPage || isLastIncomplete) {
          state.customers.push(action.payload);
        }
      })
      .addCase(addNewCustomer.rejected, setFailed)

      .addCase(updateExistingCustomer.pending, setLoading)
      .addCase(updateExistingCustomer.fulfilled, (state, action) => {
        setSucceeded(state);

        const { id, updatedDetails } = action.payload;
        const index = state.customers.findIndex((cust) => {
          return cust.id === parseFloat(id);
        });

        if (index !== -1) {
          state.customers[index] = updatedDetails;
        }
      })
      .addCase(updateExistingCustomer.rejected, setFailed)

      .addCase(removeCustomer.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;
        state.customers = state.customers.filter((cust) => cust.id !== id);
      })
      .addCase(removeCustomer.pending, setLoading)
      .addCase(removeCustomer.rejected, setFailed);
  },
});

export const { setQueryDetails, clearCurrentCustomer } = customersSlice.actions;
export default customersSlice.reducer;
