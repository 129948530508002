import { api } from './base';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getWastageReasons = (token) => {
  return handleApiResponse(() =>
    api.get('wastage_reasons', {
      headers: {
        TOKEN: token,
      },
    }),
  );
};

export const getWastageReason = (id, token) =>
  handleApiResponse(() =>
    api.get(`wastage_reasons/${id}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const addWastageReason = (newModifierDetails, token) =>
  handleApiResponse(() =>
    api.post('wastage_reasons', newModifierDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const updateWastageReason = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`wastage_reasons/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const deleteWastageReason = (id, token) =>
  handleApiResponse(() =>
    api.delete(`wastage_reasons/${id}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const reorderWastageReason = (id, updatedDetails, token) => {
  return handleApiResponse(() =>
    api.put(
      `wastage_reasons/${id}/reorder?old_position=${updatedDetails.old_position}&new_position=${updatedDetails.new_position}`,
      {},
      {
        headers: {
          TOKEN: token,
        },
      },
    ),
  );
};
