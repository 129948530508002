import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getReceiptSettings,
  getReceiptSetting,
  addReceiptSettings,
  updateReceiptSettings,
  deleteReceiptSettings,
} from '../api/kitchen_receipt_settings';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchKitchenReceiptSettings = createAsyncThunk(
  'kitchenReceiptSettings/fetchKitchenReceiptSettings',
  async ({ token }) => {
    const response = await getReceiptSettings(token);
    return response.data;
  },
);

export const fetchKitchenReceiptSetting = createAsyncThunk(
  'kitchenReceiptSettings/fetchKitchenReceiptSetting',
  async ({ id, token }) => {
    const response = await getReceiptSetting(id, token);
    return response.data;
  },
);

export const addKitchenReceiptSetting = createAsyncThunk(
  'kitchenReceiptSettings/addKitchenReceiptSetting',
  async ({ newReceiptSetting, token }, { dispatch }) => {
    try {
      const response = await addReceiptSettings(newReceiptSetting, token);
      dispatch(
        addNotification({
          message: 'Kitchen receipt settings successfully created',
          status: 'succeeded',
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to create Kitchen receipt settings, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateKitchenReceiptSettings = createAsyncThunk(
  'kitchenReceiptSettings/updateKitchenReceiptSettings',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateReceiptSettings(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Kitchen receipt settings successfully saved',
          status: 'succeeded',
        }),
      );
      return { updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to save Kitchen receipt settings, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeKitchenReceiptSettings = createAsyncThunk(
  'kitchenReceiptSettings/updateKitchenReceiptSettings',
  async ({ id, token }, { dispatch }) => {
    try {
      await deleteReceiptSettings(id, token);
      dispatch(
        addNotification({
          message: 'Kitchen receipt settings successfully deleted',
          status: 'succeeded',
        }),
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to delete Kitchen receipt settings, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

const initialState = {
  initialKitchenReceiptSettings: {},
  kitchenReceiptSettings: {},
  currentKitchenReceiptSetting: {},
  status: 'idle',
  error: null,
};

const kitchenReceiptSettingsSlice = createSlice({
  name: 'kitchenReceiptSettings',
  initialState,
  reducers: {
    updateState(state, action) {
      state.kitchenReceiptSettings = {
        ...state.kitchenReceiptSettings,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action?.error.message;
    };

    builder
      .addCase(fetchKitchenReceiptSettings.pending, setLoading)
      .addCase(fetchKitchenReceiptSettings.fulfilled, (state, action) => {
        setSucceeded(state);
        state.initialKitchenReceiptSettings = action.payload;
        state.kitchenReceiptSettings = action.payload;
      })
      .addCase(fetchKitchenReceiptSettings.rejected, setFailed)

      .addCase(fetchKitchenReceiptSetting.pending, setLoading)
      .addCase(fetchKitchenReceiptSetting.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentKitchenReceiptSetting = action.payload;
      })
      .addCase(fetchKitchenReceiptSetting.rejected, setFailed)

      .addCase(addKitchenReceiptSetting.pending, setLoading)
      .addCase(addKitchenReceiptSetting.fulfilled, (state, action) => {
        setSucceeded(state);
      })
      .addCase(addKitchenReceiptSetting.rejected, setFailed)

      .addCase(updateKitchenReceiptSettings.pending, setLoading)
      .addCase(updateKitchenReceiptSettings.fulfilled, (state, action) => {
        setSucceeded(state);
        state.initialKitchenReceiptSettings = { ...state.kitchenReceiptSettings, ...action.payload };
        state.kitchenReceiptSettings = state.initialKitchenReceiptSettings;
      })
      .addCase(updateKitchenReceiptSettings.rejected, setFailed);
  },
});

export const { updateState } = kitchenReceiptSettingsSlice.actions;
export default kitchenReceiptSettingsSlice.reducer;
