import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../context/context';
import { fetchEmployee, updateExistingEmployee, clearCurrentEmployee } from '../../../../redux/employeesSlice';
import { fetchTeamRoles } from '../../../../redux/rolesSlice';

import { addNotification } from '../../../../redux/tooltipSlice';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Button from '../../../../components/utilities/button/button';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import EmployeeForm from './form/employee-form';

import { isObjectEmpty, valueConverter } from '../../../utilities/utils';
import useFormValidation from '../../../utilities/useFormValidation';
import { registrationSchema } from './validation';
import { convertValueByType } from '../../../utilities/utils';

import styles from './employees.module.scss';

const getEmployee = (employees, id) => {
  return employees.find((item) => item.id === parseFloat(id));
};

const EditEmployee = () => {
  const { id } = useParams();
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const employees = useSelector((state) => state.employees.employees);
  const employeesStatus = useSelector((state) => state.employees.status);
  const currentEmployee = useSelector((state) => state.employees.currentEmployee);
  const rolesOptions = useSelector((state) => state.roles.nameOptions);
  const rolesOptionsStatus = useSelector((state) => state.roles.status);
  const [employee, setEmployee] = useState(getEmployee(employees, id) || currentEmployee);

  const { errors, validate } = useFormValidation(registrationSchema);

  const statusLoading = employeesStatus === 'loading';

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (employeesStatus === 'idle') {
          await dispatch(fetchEmployee({ id, token })).unwrap();
        }
        if (rolesOptionsStatus === 'idle') {
          await dispatch(fetchTeamRoles(token)).unwrap();
        }
      } catch (error) {
        dispatch(addNotification({ message: `Error fetching data: ${error}`, status: 'failed' }));
      }
    };

    fetchData();
  }, [dispatch, token, id, employeesStatus, rolesOptionsStatus]);

  useEffect(() => {
    if (isObjectEmpty(employee) && !isObjectEmpty(currentEmployee)) {
      setEmployee(currentEmployee);
    }
  }, [currentEmployee, employee]);

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setEmployee((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  const handleCancel = () => {
    navigate('/employee/employees');
    dispatch(clearCurrentEmployee());
  };

  const handleRoleChange = (value) => {
    handleInputChange({ target: { name: 'team_role_id', value: value } }, 'number');
  };

  const handleSaveChanges = async () => {
    if (validate(employee)) {
      try {
        await dispatch(updateExistingEmployee({ id, updatedDetails: employee, token })).unwrap();
        navigate('/employee/employees');
        dispatch(clearCurrentEmployee());
      } catch {}
    }
  };

  if (!employee) {
    return null;
  }

  return (
    <section className={styles.employee_edit_section}>
      <div className={styles.content}>
        <PageTitle name={'Edit Employee'} />
        <div className={styles.form_container}>
          {statusLoading && <BackdropLoading />}
          <EmployeeForm
            employee={employee}
            roles={rolesOptions}
            onInputChange={handleInputChange}
            onRoleChange={handleRoleChange}
            errors={errors}
          />
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              disabled={statusLoading}
              title={'Save Changes'}
              onClick={handleSaveChanges}
            />
            <Button
              className={styles.button}
              variant={'grey'}
              disabled={statusLoading}
              title={'Cancel'}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditEmployee;
