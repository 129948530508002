import { api } from './base';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getPrinters = (token) => {
  return handleApiResponse(() =>
    api.get('printers', {
      headers: {
        TOKEN: token,
      },
    }),
  );
};

export const getPrinter = (id, token) =>
  handleApiResponse(() =>
    api.get(`printers/${id}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const addPrinter = (newPrinterDetails, token) =>
  handleApiResponse(() =>
    api.post('printers', newPrinterDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const updatePrinter = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`printers/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const deletePrinter = (id, deleteDetails, token) =>
  handleApiResponse(() => {
    return api.delete(`printers/${id}?change_receipt_type=${deleteDetails.change_receipt_type}`, {
      headers: {
        TOKEN: token,
      },
    });
  });

export const updatePrinterStatus = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(
      `printers/${id}/update_printer_status?status=${updatedDetails.status}`,
      {},
      {
        headers: {
          TOKEN: token,
        },
      },
    ),
  );
