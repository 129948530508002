import React from 'react';

const RefundVoids = ({ shiftReportData }) => {

    shiftReportData = shiftReportData || {
        refund_and_void_details: [
            [
                {
                    key: "Total Refund",
                    value: 0
                },
                {
                    key: "Total Void",
                    value: 0
                }
            ]
        ]
    };

    const displayTwoDigitWithDelimiter = (value) => {
        return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    };

    return (
        <div>
            <h4 className="admin-main-title text-darkgray1 fw-700 text-center fs-20 py-3 bg-custom-gray border-bottom mb-4 pb-2">
                Refund/Voids
            </h4>
            <div className="admin-table bg-white table-responsive mt-4">
                <table className="table table-bordered">
                    <thead className="thead-dark">
                    <tr>
                        <th width="25%">Order Id</th>
                        <th width="25%"></th>
                        <th width="25%"></th>
                        <th width="25%">Item Count</th>
                        <th width="25%">Amount</th>
                    </tr>
                    </thead>
                    <tbody className="eod_shift_report">
                    {shiftReportData.refund_and_void_details && shiftReportData.refund_and_void_details.length > 0 ? (
                        shiftReportData.refund_and_void_details.map((data, index) => {
                            const klasss = data[0].key === "Total Refund" ? "fw-700" : "";
                            return (
                                <tr key={index}>
                                    <td className={klasss}>
                                        {data[0].key}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td className={klasss}>
                                        {displayTwoDigitWithDelimiter(data[1].value)}
                                    </td>
                                    <td className={klasss}>
                                        ${displayTwoDigitWithDelimiter(data[0].value)}
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td className="text-center" colSpan="4">No Records found</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RefundVoids;
