import React, { useState, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../context/context';

import { addLoyalty } from '../../../../redux/loyaltyProgramsSlice';

import PageTitle from '../../../utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Button from '../../../utilities/button/button';
import Switch from '../../../utilities/switch/switch';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import { convertValueByType } from '../../../utilities/utils';

import useFormValidation from '../../../utilities/useFormValidation';
import { registrationSchema } from './validation';

import clsx from 'clsx';
import styles from './loyalty-programs.module.scss';

const AddLoyaltyProgram = () => {
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errors, validate } = useFormValidation(registrationSchema);

  const LoyaltyProgramsStatus = useSelector((state) => state.loyaltyPrograms.status);
  const [loyaltyProgram, setLoyaltyProgram] = useState({});

  const statusLoading = LoyaltyProgramsStatus === 'loading';

  const handleInputChange = useCallback(
    (e, type) => {
      const { name, value } = e.target;
      setLoyaltyProgram((prev) => ({
        ...prev,
        [name]: convertValueByType(value, type),
      }));
    },
    [setLoyaltyProgram],
  );

  const handleCancel = () => {
    navigate('/business/loyalty_programs');
  };

  const handleSaveChanges = async () => {
    if (validate(loyaltyProgram)) {
      try {
        await dispatch(addLoyalty({ newLoyaltyProgramDetails: loyaltyProgram, token })).unwrap();
        navigate('/business/loyalty_programs');
      } catch {}
    }
  };

  return (
    <section className={styles.section}>
      <div className={styles.content}>
        <PageTitle name={'Create Loyalty Program'} />
        <div className={styles.form_container}>
          <div className={styles.fields}>
            {statusLoading ? <BackdropLoading /> : null}
            <div className={styles.row}>
              <div className={styles.field}>
                <Input
                  label={'Loyalty Name'}
                  value={loyaltyProgram.name}
                  onChange={handleInputChange}
                  name={'name'}
                  required={true}
                  errors={errors.name?._errors}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <Input
                  label={'Loyalty Target'}
                  value={loyaltyProgram.amount}
                  onChange={handleInputChange}
                  name={'amount'}
                  type="number"
                  required={true}
                  errors={errors.amount?._errors}
                />
              </div>
              <div className={styles.field}>
                <Input
                  label={'Reward Amount'}
                  value={loyaltyProgram.reward_point}
                  onChange={handleInputChange}
                  name={'reward_point'}
                  type="number"
                  required={true}
                  errors={errors.reward_point?._errors}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={clsx(styles.field, styles.switcher)}>
                <Switch
                  id={'is_enable'}
                  isActive={loyaltyProgram.is_enable}
                  onSwitch={(name, value) => handleInputChange({ target: { name, value } }, 'boolean')}
                />
                <span>Active</span>
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button className={styles.button} disabled={statusLoading} title={'Save'} onClick={handleSaveChanges} />
            <Button
              className={styles.button}
              variant={'grey'}
              disabled={statusLoading}
              title={'Cancel'}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddLoyaltyProgram;
