import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from 'react-router-dom';
import {useAppContext} from "../../../../context/context";
import Modal from "../../../utilities/modal/modal";
import {HeaderReport} from "../components/HeaderReport";
import OrderDetailTemplate from "./OrderDetailTemplate";
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';
import {fetchOrderDetails} from "../../../../redux/reportsSetSlice";

import styles from './orders.module.scss';

function OrderDetail() {
    const { token } = useAppContext();
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const order = useSelector((state) => state.reports.orderDetails);

    useEffect(() => {
        if (id) {
            dispatch(fetchOrderDetails({token, orderId: id}));
        }
    }, []);


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

  return (
    <div className={styles.contentWrapper}>
        <HeaderReport title={"Order Details"} showBack={true} onNavigate={() => navigate(-1)} />
        {order ? <OrderDetailTemplate order={order} /> : <BackdropLoading />}
        <Modal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
        >
            <span>Resipe</span>
        </Modal>
    </div>
  );
}

export default OrderDetail;