import React from "react";
import Chartkick from "react-chartkick";
import { LineChart } from "react-chartkick";
import { Chart } from "chart.js";

import BackdropLoading from "../../../../utilities/backdrop-loading/backdrop-loading";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineController, // Add this import
} from "chart.js";

import styles from "./hourly-new-revenue.module.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineController
);
Chartkick.addAdapter(ChartJS);

const HourlyRevenueLineChart = ({ data, isLoading }) => {
  return (
    <div className={styles.chart_wrapper}>
      {isLoading && <BackdropLoading />}
      <LineChart
        data={data}
        id="line-chart-dashboard"
        prefix="$"
        thousands=","
        round={2}
        colors={["#ED5950"]}
        messages={{ empty: "No Records available." }}
      />
    </div>
  );
};

export default HourlyRevenueLineChart;
