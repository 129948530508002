import { IoTrash } from 'react-icons/io5';
import { MdModeEditOutline } from 'react-icons/md';
import { MdContentCopy } from 'react-icons/md';

import List from '../../../utilities/list/list';

import styles from './modifier-sets-list.module.scss';

const OrderTypesList = ({ modifiers, onClone, onEdit, onDelete, onDrag, isLoading, isFailed }) => {
  const renderModifiers = (modifiers) => (
    <span>{modifiers.modifiers?.map((modifier) => modifier.name).join(', ')}</span>
  );

  const columnConfig = [
    {
      key: 'name',
      header: 'Modifier Set Name',
    },
    {
      key: 'modifiers',
      header: 'Modifiers',
      render: renderModifiers,
    },
  ];

  const rowActions = [
    { icon: <MdContentCopy />, onClick: onClone, label: 'Clone modifier', className: styles.row_actions_button },
    { icon: <MdModeEditOutline />, onClick: onEdit, label: 'Edit modifier', className: styles.row_actions_button },
    { icon: <IoTrash />, onClick: onDelete, label: 'Delete modifier', className: styles.row_actions_button },
  ];

  return (
    <List
      data={modifiers}
      columnConfig={columnConfig}
      rowActions={rowActions}
      isLoading={isLoading}
      isFailed={isFailed}
      isDragable={true}
      onDragged={onDrag}
    />
  );
};

export default OrderTypesList;
