import React from 'react';
import {displayTwoDigit} from "../components/helper";
import ViewDate from "../components/view-date";


export default function ReportTable({data, currentLocation}) {

    const getOrderID = (location, order) => `${location}-${order.id}`;

    return (
        <table className="table table-bordered">
            <thead className="thead-light">
            <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Order ID</th>
                <th>Order Total</th>
                <th>Loyalty Amount</th>
                <th>Loyalty Points Used</th>
                <th>Loyalty Balance</th>
                <th>Created</th>
            </tr>
            </thead>
            <tbody>
            {data.length > 0 ? (
                data.map((order) => (
                    <tr key={order.id}>
                        <td>{order.customer?.first_name}</td>
                        <td>{order.customer?.last_name}</td>
                        <td>
                            <a href={`/order_details?order_id=${order.id}&start_date=&end_date=`}
                               className="text-blue1">
                                {getOrderID(currentLocation, order)}
                            </a>
                        </td>
                        <td>${displayTwoDigit(order.total_amount)}</td>
                        <td>${displayTwoDigit(order.loyalty_amount)}</td>
                        <td>{order.used_reward_points}</td>
                        <td>{order.loyalty_balance}</td>
                        <td><ViewDate date={order.created_at} /> </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan="7">
                        <center>No Records available.</center>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    );
}
