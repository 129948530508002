import ViewDate from "../components/view-date";
import {displayTwoDigitWithDelimiter} from '../components/helper';


export default function ReportTable({payments}) {

    const SurCharge = 'SurCharge';
    const CashDiscount = 'CashDiscount';

    return (
        <table className="table table-bordered">
            <thead className="thead-light">
            <tr>
                <th>Created</th>
                <th>Order#</th>
                <th>Employee</th>
                <th>Card</th>
                <th className="text-center">Subtotal</th>
                <th className="text-center">Tip Amount</th>
                <th>Tip Name</th>
                <th className="text-center">Tip Percentage</th>
                <th className="text-center">Service Charge</th>
                <th className="text-center">Discount</th>
                <th className="text-center">Tax</th>
                <th className="text-center">Surcharge</th>
                <th className="text-center">Cash Discount</th>
                <th className="text-center">Total</th>
            </tr>
            </thead>
            <tbody className="tips_list">
            {payments.length > 0 ? (
                payments.map((payment, index) => (
                    <tr key={index}>
                        <td>
                            <div className="connected-printer text-left">
                                <h6 className="text-gray2 fw-600">
                                    <ViewDate date={payment.created_at} />
                                </h6>
                            </div>
                        </td>
                        <td>
                            {payment.order.offline_id ? (
                                <a
                                    href={payment.order.short_receipt_url}
                                    className="fs-14 change-default text-blue1"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {payment.order.offline_id}
                                </a>
                            ) : (
                                payment.order.offline_id
                            )}
                        </td>
                        <td>{payment.employee?.full_name}</td>
                        <td>
                            {payment.card_name ? (
                                <>
                                    {payment.card_name}{' '}
                                    {payment.Card ? `(${payment.card_name.slice(-4)})` : ''}
                                </>
                            ) : (
                                '-'
                            )}
                        </td>
                        <td className="text-center">
                            ${displayTwoDigitWithDelimiter(payment.sub_total)}
                        </td>
                        <td className="text-center">
                            ${displayTwoDigitWithDelimiter(payment.tips)}
                        </td>
                        {payment.tip_setting ? (
                            <>
                                <td>{payment.tip_setting.name}</td>
                                <td className="text-center">
                                    {displayTwoDigitWithDelimiter(payment.tip_setting.rate)}%
                                </td>
                            </>
                        ) : (
                            <>
                                <td>Custom</td>
                                <td className="text-center">
                                    {displayTwoDigitWithDelimiter(payment.tips / payment.sub_total * 100)}%
                                </td>
                            </>
                        )}
                        <td className="text-center">
                            ${displayTwoDigitWithDelimiter(payment.service_charge_amount)}
                        </td>
                        <td className="text-center">
                            ${displayTwoDigitWithDelimiter(payment.total_discount)}
                        </td>
                        <td className="text-center">
                            ${displayTwoDigitWithDelimiter(payment.tax_amount)}
                        </td>
                        <td className="text-center">
                            ${payment.cash_discount_type === SurCharge
                            ? displayTwoDigitWithDelimiter(payment.cash_discount_or_surcharge)
                            : '0.00'}
                        </td>
                        <td className="text-center">
                            ${payment.cash_discount_type === CashDiscount
                            ? displayTwoDigitWithDelimiter(payment.cash_discount_or_surcharge)
                            : '0.00'}
                        </td>
                        <td className="text-center">
                            ${displayTwoDigitWithDelimiter(payment.amount + payment.tips)}
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td className="text-center" colSpan="12">
                        No Records found
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    );
}
