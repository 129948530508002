import styles from './backdrop-loading.module.scss';
import Spinner from '../spinner/spinner';

// This render conditional approach, allowing as to use this BackdropLoading
// as a separate component (for independent display) and as a wrapper for
// component to hide the content and show spinner when status is loading.

const BackdropLoading = ({ status, children }) => {
  return (
    <>
      {children && (status === false || status === undefined || status === null || typeof status === 'string') ? (
        status === 'loading' || status === true ? (
          <div className={styles.backdrop}>
            <Spinner />
          </div>
        ) : (
          children
        )
      ) : (
        <div className={styles.backdrop}>
          <Spinner />
        </div>
      )}
    </>
  );
};

export default BackdropLoading;
