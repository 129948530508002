import React from 'react';
import styles from './report-by-modifiers.module.scss';
import HeaderReport from "../components/HeaderReport/header-report";
import clsx from "clsx";
import DateRange from "../components/DateRange/date-range";
import ReportTable from "./report-table";

function ReportByModifiers() {
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const downloadPdf = () => {};
    const downloadCsv = () => {};

    const fakeData = [
        { id: 1, name: 'Modifier 1', modifier_set: 'Set A', quantity: 10 },
        { id: 2, name: 'Modifier 2', modifier_set: 'Set B', quantity: 5 },
        { id: 3, name: 'Modifier 3', modifier_set: 'Set C', quantity: 8 },
    ];
  return (
      <div className={styles.contentWrapper}>
          <HeaderReport
              title={"Modifiers"}
              downloadPdf={downloadPdf}
              downloadCsv={downloadCsv}
          />
          <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
              <DateRange
                  setEndDate={setEndDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  startDate={startDate}
              />
          </div>
          <div className="bg-white table-responsive mt-4">
              <ReportTable modifiers={fakeData} />
          </div>
      </div>
  )
}

export default ReportByModifiers;
