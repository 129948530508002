import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';

import { useDispatch } from 'react-redux';
import useFormValidation from '../../utilities/useFormValidation';

import { resetPassword } from '../../../api/auth';
import { addNotification } from '../../../redux/tooltipSlice';

import { registrationSchema } from './validation';

import Input from '../../utilities/input/input';
import Button from '../../utilities/button/button';

import { convertValueByType } from '../../utilities/utils';

import { FaEyeSlash } from 'react-icons/fa6';
import { FaEye } from 'react-icons/fa';

import Logo from '../../../assets/img/crunch-logo-512px.png';
import styles from './reset-password.module.scss';

const checkValueForEmpty = (value) => value === '' || value === undefined || value === null;

function ResetPassword() {
  const [passwordState, setPasswordState] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { errors, validate } = useFormValidation(registrationSchema);

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setEmail(params.get('email'));
    setToken(params.get('token'));
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate(passwordState)) {
      const hashedPassword = CryptoJS.SHA256(passwordState?.newPassword).toString(CryptoJS.enc.Base64);

      try {
        const response = await resetPassword(email, hashedPassword, token);
        console.log('Reset password passed successfully:', response);

        dispatch(
          addNotification({
            message: response.message || 'Password reset successfully.',
            status: 'succeeded',
          }),
        );
        navigate('/login');
      } catch (error) {
        dispatch(
          addNotification({
            message: `Error resetting password: ${error?.message}`,
            status: 'failed',
          }),
        );
      }
    }
  };

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setPasswordState((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.reset_password}>
        <img className={styles.logo} src={Logo} draggable={false} alt="Crunch POS logo" />
        <span className={styles.title}>Serving efficiency</span>
        <div className={styles.field}>
          <Input
            value={passwordState.newPassword}
            id="newPassword"
            name="newPassword"
            type={showNewPassword ? 'text' : 'password'}
            autoComplete="password"
            onChange={handleInputChange}
            suffixIcon={
              !checkValueForEmpty(passwordState?.newPassword) ? showNewPassword ? <FaEye /> : <FaEyeSlash /> : null
            }
            suffixButtonCallback={() => setShowNewPassword((prev) => !prev)}
            placeholder={'Enter your new password'}
            errors={errors.newPassword?._errors}
          />
        </div>
        <div className={styles.field}>
          <Input
            value={passwordState.confirmPassword}
            id="confirmPassword"
            name="confirmPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            autoComplete="password"
            onChange={handleInputChange}
            suffixIcon={
              !checkValueForEmpty(passwordState?.confirmPassword) ? (
                showConfirmPassword ? (
                  <FaEye />
                ) : (
                  <FaEyeSlash />
                )
              ) : null
            }
            suffixButtonCallback={() => setShowConfirmPassword((prev) => !prev)}
            placeholder={'Confirm your new password'}
            errors={errors.confirmPassword?._errors}
          />
        </div>
        <Button title={'Reset Password'} onClick={handleSubmit} style={{ width: '100%', fontSize: '1rem' }} />
        <div className={styles.return_login}>
          <Link to="/login">Return to authentication</Link>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
