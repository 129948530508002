import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getLocations,
  updateDailyReportTime,
  updateBatchCloseTime,
  updateTimeZone,
  setGiftCardPosition,
  setOtherSettings,
  setCustomerDisplaySettings,
} from "../api/location";

import { addNotification } from "./tooltipSlice";

// Thunks
export const fetchLocations = createAsyncThunk(
  "locations/fetchLocations",
  async (token) => {
    const response = await getLocations(token);
    return response.data;
  }
);

export const updateExistingDailyReportTime = createAsyncThunk(
  "locations/updateDailyReportTime",
  async ({ updatedTime, token, locationId }, { dispatch }) => {
    try {
      await updateDailyReportTime(updatedTime, token);
      dispatch(
        addNotification({
          message: "Daily report time was updated successfully",
          status: "succeeded",
        })
      );
      return { updatedTime, locationId };
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to update daily report time",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

export const updateExistingBatchCloseTime = createAsyncThunk(
  "locations/updateBatchCloseTime",
  async ({ updatedTime, token, locationId }, { dispatch }) => {
    try {
      await updateBatchCloseTime(updatedTime, token);
      dispatch(
        addNotification({
          message: "Batch close time was updated successfully",
          status: "succeeded",
        })
      );
      return { updatedTime, locationId };
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to update batch close time",
          status: "failed",
        })
      );
      throw error;
    }
  }
);


export const updateExistingTimeZone = createAsyncThunk(
  "locations/updateExistingTimeZone",
  async ({ updatedTimeZone, token, locationId }, { dispatch }) => {
    try {
      await updateTimeZone(updatedTimeZone, token);
      dispatch(
        addNotification({
          message: "Time zone was updated successfully",
          status: "succeeded",
        })
      );
      return { updatedTimeZone, locationId };
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to update time zone",
          status: "failed",
        })
      );
      throw error;
    }
  }
);


export const updateGiftCardPosition = createAsyncThunk(
  "locations/updateGiftCardPosition",
  async ({ isGiftCardAtFirstPosition, token, locationId }, { dispatch }) => {
    try {
      await setGiftCardPosition(isGiftCardAtFirstPosition, token);
      dispatch(
        addNotification({
          message: "Gift Card position was updated successfully",
          status: "succeeded",
        })
      );
      return { isGiftCardAtFirstPosition, locationId };
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to update gift card position",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

export const updateOtherSettings = createAsyncThunk(
  "locations/updateOtherSettings",
  async (
    {
      isPrinterQueueEnabled,
      isOrderNumberStartingFromOne,
      isOnlyShowPriceGreaterThanZero,
      token,
      locationId,
    },
    { dispatch }
  ) => {
    try {
      await setOtherSettings(
        isPrinterQueueEnabled,
        isOrderNumberStartingFromOne,
        isOnlyShowPriceGreaterThanZero,
        token
      );
      dispatch(
        addNotification({
          message: "Other settings was updated successfully",
          status: "succeeded",
        })
      );
      return {
        isPrinterQueueEnabled,
        isOrderNumberStartingFromOne,
        isOnlyShowPriceGreaterThanZero,
        locationId,
      };
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to update other settings",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

export const updateCustomerDisplaySettings = createAsyncThunk(
  "locations/updateCustomerDisplaySettings",
  async (
    {
      isCustomerSignatureRequired,
      isShowCashBifurcation,
      isShowTipScreenBeforePayment,
      token,
      locationId,
    },
    { dispatch }
  ) => {
    try {
      await setCustomerDisplaySettings(
        isCustomerSignatureRequired,
        isShowCashBifurcation,
        isShowTipScreenBeforePayment,
        token
      );
      dispatch(
        addNotification({
          message: "Customer display settings was updated successfully",
          status: "succeeded",
        })
      );
      return {
        isCustomerSignatureRequired,
        isShowCashBifurcation,
        isShowTipScreenBeforePayment,
        locationId,
      };
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to update customer display settings",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

const initialState = {
  locations: [],
  status: "idle",
  error: null,
};

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = "loading";
    };

    const setSucceeded = (state) => {
      state.status = "succeeded";
    };

    const setFailed = (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    };

    builder
      .addCase(fetchLocations.pending, setLoading)
      .addCase(fetchLocations.fulfilled, (state, action) => {
        setSucceeded(state);
        state.locations = action.payload;
      })
      .addCase(fetchLocations.rejected, setFailed)

      .addCase(updateExistingDailyReportTime.pending, setLoading)
      .addCase(updateExistingDailyReportTime.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedTime, locationId } = action.payload;
        const locationToUpdate = state.locations.find(
          (location) => String(location.id) === String(locationId)
        );

        if (locationToUpdate) {
          locationToUpdate.daily_report_sending_time = updatedTime;
        }
      })
      .addCase(updateExistingDailyReportTime.rejected, setFailed)

      .addCase(updateExistingBatchCloseTime.pending, setLoading)
      .addCase(updateExistingBatchCloseTime.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedTime, locationId } = action.payload;
        const locationToUpdate = state.locations.find(
          (location) => String(location.id) === String(locationId)
        );

        if (locationToUpdate) {
          locationToUpdate.batch_close_time = updatedTime;
        }
      })
      .addCase(updateExistingBatchCloseTime.rejected, setFailed)

      .addCase(updateExistingTimeZone.pending, setLoading)
      .addCase(updateExistingTimeZone.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedTimeZone, locationId } = action.payload;
        const locationToUpdate = state.locations.find(
          (location) => String(location.id) === String(locationId)
        );

        if (locationToUpdate) {
          locationToUpdate.time_zone = updatedTimeZone;
        }
      })
      .addCase(updateExistingTimeZone.rejected, setFailed)


      .addCase(updateGiftCardPosition.pending, setLoading)
      .addCase(updateGiftCardPosition.fulfilled, (state, action) => {
        setSucceeded(state);
        const { isGiftCardAtFirstPosition, locationId } = action.payload;
        const locationToUpdate = state.locations.find(
          (location) => String(location.id) === String(locationId)
        );

        if (locationToUpdate) {
          locationToUpdate.show_gift_card_at_first_position = isGiftCardAtFirstPosition;
        }
      })
      .addCase(updateGiftCardPosition.rejected, setFailed)

      .addCase(updateOtherSettings.pending, setLoading)
      .addCase(updateOtherSettings.fulfilled, (state, action) => {
        setSucceeded(state);
        const {
          isPrinterQueueEnabled,
          isOrderNumberStartingFromOne,
          isOnlyShowPriceGreaterThanZero,
          locationId,
        } = action.payload;
        const locationToUpdate = state.locations.find(
          (location) => String(location.id) === String(locationId)
        );

        if (locationToUpdate) {
          locationToUpdate.is_printer_queue_enable = isPrinterQueueEnabled;
          locationToUpdate.order_number_starting_from_one =
            isOrderNumberStartingFromOne;
          locationToUpdate.only_show_price_greater_than_zero =
            isOnlyShowPriceGreaterThanZero;
        }
      })
      .addCase(updateOtherSettings.rejected, setFailed)

      .addCase(updateCustomerDisplaySettings.pending, setLoading)
      .addCase(updateCustomerDisplaySettings.fulfilled, (state, action) => {
        setSucceeded(state);
        const {
          isCustomerSignatureRequired,
          isShowCashBifurcation,
          isShowTipScreenBeforePayment,
          locationId,
        } = action.payload;
        const locationToUpdate = state.locations.find(
          (location) => String(location.id) === String(locationId)
        );

        if (locationToUpdate) {
          locationToUpdate.customer_sign_required_on_cd = isCustomerSignatureRequired; 
          locationToUpdate.show_cash_credit_price_on_cd = isShowCashBifurcation; 
          locationToUpdate.show_tip_screen_before_payment = isShowTipScreenBeforePayment; 
        }
      })
      .addCase(updateCustomerDisplaySettings.rejected, setFailed);
  },
});

export default locationsSlice.reducer;
