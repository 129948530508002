import ViewDate from "../components/view-date";
import {orderTypeName, displayTwoDigit, displayCustomerName} from "../components/helper"



export default function ReportTable({ payments }) {

    const getLast4DigitOfCard = (payment) => payment.card_number?.slice(-4);
    const deleteCashLogPath = (payment) => `/delete/${payment.id}`;
    const transactionDetailsReportPath = (payment) => `/transaction/${payment.id}`;

    return (
        <table className="table table-bordered">
            <thead className="thead-light">
            <tr>
                <th>Created</th>
                <th>Trans#</th>
                <th>Tender Type</th>
                <th>Order Type</th>
                <th>Amount</th>
                <th>Taxes and Fees</th>
                <th>Service Charge</th>
                <th>Tip Amount</th>
                <th>Discount</th>
                <th>Employee</th>
                <th>Customer</th>
                <th>Details</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody className="transactions_list">
            {payments.length > 0 ? (
                payments.map((payment) => {
                    const order = payment.order;
                    return (
                        <tr key={payment.id}>
                            <td>
                                <div className="connected-printer text-left">
                                    <h6 className="text-gray2 fw-600 mb-1">
                                        <ViewDate date={payment.created_at} />
                                    </h6>
                                    <p>ID: {payment.offline_id}</p>
                                </div>
                            </td>
                            <td>{payment.id}</td>
                            <td>
                                {payment.payment_type} {payment.card_number ? `(${payment.card_number})` : ''}
                            </td>
                            <td>{orderTypeName(order)}</td>
                            <td>${displayTwoDigit(payment.amount + payment.tips)}</td>
                            <td>${displayTwoDigit(payment.tax_amount)}</td>
                            <td>${displayTwoDigit(payment.service_charge_amount)}</td>
                            <td>${displayTwoDigit(payment.tips)}</td>
                            <td>${displayTwoDigit(payment.total_discount)}</td>
                            <td>{payment.employee_id || 'N/A'}</td>
                            <td>{displayCustomerName(payment)}</td>
                            <td>
                                <a href={transactionDetailsReportPath(payment)} className="text-underline text-blue1">
                                    Details
                                </a>
                            </td>
                            <td>
                                {payment.order && payment.order.type === 'Order' && !payment.Card ? (
                                    <a
                                        href={deleteCashLogPath(payment)}
                                        className="text-underline text-blue1"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (window.confirm('Are you sure, you want to delete this Order?')) {
                                                // Handle delete action
                                            }
                                        }}
                                    >
                                        Delete
                                    </a>
                                ) : (
                                    '-'
                                )}
                            </td>
                        </tr>
                    );
                })
            ) : (
                <tr>
                    <td className="text-center" colSpan="13">
                        No Records found
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    );
}
