import { z } from 'zod';

export const registrationSchema = (discountType) =>
  z.object({
    name: z
      .string({ required_error: 'Please enter Discount Name' })
      .min(1, { message: 'Please enter Discount Name' })
      .max(60, { message: 'Please enter 60 characters or fewer' })
      .regex(/^[-a-zA-Z0-9-()]+(\s[-a-zA-Z0-9-()]+)*$/, { message: 'Please enter valid value' }),
    percentage: z
      .number({ required_error: 'Please enter Discount Rate', invalid_type_error: 'Only digits are allowed.' })
      .min(0.01, { message: 'Rate must be greater than 0.0' })
      .max(discountType === 'Percentage' ? 100.0 : undefined, { message: 'Rate must not exceed 100' }),
    // .refine(
    //   (val) => {
    //     if (discountType === 'Percentage') {
    //       return val <= 100;
    //     }
    //     return true;
    //   },
    //   { message: 'Rate must not exceed 100' },
    // )
    // .refine(
    //   (val) => {
    //     if (discountType !== 'Percentage') {
    //       const fixedRateRegex = /^\d{0,4}(\.\d{1,2})?$/;
    //       return fixedRateRegex.test(val.toString());
    //     }
    //     return true;
    //   },
    //   { message: 'Please enter valid value' },
    // ),
  });
