import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../context/context';
import { addOrderType } from '../../../../redux/orderTypesSlice';

import { IoIosArrowDown } from 'react-icons/io';

import useFormValidation from '../../../utilities/useFormValidation';
import { registrationSchema } from './validation';

import PageTitle from '../../../utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Selector from '../../../utilities/selector/selector';
import Button from '../../../utilities/button/button';
import Switch from '../../../utilities/switch/switch';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import { convertValueByType } from '../../../utilities/utils';

import clsx from 'clsx';
import styles from './order-types.module.scss';

const OPEN_ORDER_TYPES = [
  { value: 'TakeOut', label: 'Take Out' },
  { value: 'OpenOrder', label: 'Open Order' },
];

const AddOrderType = () => {
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const orderTypesStatus = useSelector((state) => state.orderTypes.status);
  const [order, setOrder] = useState({
    is_active: false,
  });

  const { errors, validate } = useFormValidation(registrationSchema);

  const statusLoading = orderTypesStatus === 'loading';

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setOrder((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  const handleCancel = () => {
    navigate('/business/order_types');
  };

  const handleSaveChanges = async () => {
    if (validate(order)) {
      try {
        await dispatch(addOrderType({ newOrderDetails: order, token })).unwrap();
        navigate('/business/order_types');
      } catch {}
    }
  };

  return (
    <section className={styles.order_edit_section}>
      <div className={styles.content}>
        <PageTitle name={'Create Order Type'} />
        <div className={styles.form_container}>
          <div className={styles.fields}>
            {statusLoading ? <BackdropLoading /> : null}
            <div className={styles.row}>
              <div className={styles.field}>
                <Input
                  label={'Order Type Name'}
                  value={order.name}
                  onChange={handleInputChange}
                  name={'name'}
                  required={true}
                  errors={errors.name?._errors}
                />
              </div>
              <div className={styles.field}>
                <Selector
                  label={'Order Type'}
                  icon={IoIosArrowDown}
                  items={OPEN_ORDER_TYPES}
                  selectedItem={order.order_type}
                  onSelectItem={(value) => handleInputChange({ target: { name: 'order_type', value } }, 'text')}
                  name={'order_type'}
                  required={true}
                  errors={errors.order_type?._errors}
                />
              </div>
              <div className={styles.field}>
                <Input
                  label={'Kitchen Color Code'}
                  value={order.kitchen_color_code || '#000000'}
                  type="color"
                  onChange={handleInputChange}
                  name={'kitchen_color_code'}
                  required={true}
                  errors={errors.kitchen_color_code?._errors}
                />
              </div>
            </div>
            <div className={clsx(styles.field, styles.switcher)}>
              <Switch
                id={'is_active'}
                isActive={order.is_active}
                onSwitch={(name, value) => handleInputChange({ target: { name, value } }, 'boolean')}
              />
              <span>Active</span>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              disabled={statusLoading}
              title={'Add Order Type'}
              onClick={handleSaveChanges}
            />
            <Button
              className={styles.button}
              variant={'grey'}
              disabled={statusLoading}
              title={'Cancel'}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddOrderType;
