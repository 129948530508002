import { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { nanoid } from 'nanoid';

import { convertValueByType, isObject, isEqual, isArrayEmpty } from '../../utils';

const checkForDragRequirements = (arr) => arr.every((item) => isObject(item.value) && item.value.id && item.value.sort);

const findMaxValueByKey = (arr, key) =>
  Math.max(0, ...arr.map((item) => (isObject(item.value) ? item.value?.[key] : 0)));

function sortTableItems(items, key, order = 'asc') {
  return items.sort((a, b) => {
    if (a.value[key] < b.value[key]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a.value[key] > b.value[key]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });
}

export function useFieldManagement(value, structuredData, Item, fieldType, isDragable, maxFields) {
  const [fields, setFields] = useState(structuredData);

  useEffect(() => {
    const inputData = structuredData?.map((item) => item.value); // Here we use only the ‘value’ from the item, because with key: nanoid() this equality will never be true
    const actualData = fields?.map((item) => item.value);

    if (!isArrayEmpty(inputData) && isArrayEmpty(actualData) && !isEqual(inputData, actualData)) {
      setFields(structuredData);
      updateMaxKeys();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structuredData]);

  const findMaxSortElements = useCallback(
    () => ({
      maxId: findMaxValueByKey(fields, 'id') || 0,
      maxSort: findMaxValueByKey(fields, 'sort') || 0,
    }),
    [fields],
  );

  const sortKeyElements = useRef(findMaxSortElements());

  const structuredFields = useMemo(() => {
    if (checkForDragRequirements(fields) && isDragable) {
      return sortTableItems(fields, 'sort');
    }

    return fields;
  }, [fields, isDragable]);

  const updateMaxKeys = () => {
    sortKeyElements.current = findMaxSortElements();
  };

  const handleAddField = useCallback(
    (passedItemAttributes) => {
      if (!maxFields || fields.length < maxFields) {
        const newMaxId = sortKeyElements.current.maxId + 1;
        const newMaxSort = sortKeyElements.current.maxSort + 1;

        setFields((prev) => [
          ...prev,
          {
            key: nanoid(),
            item: Item,
            value:
              Array.isArray(value) || value === undefined
                ? isDragable
                  ? { id: newMaxId, sort: newMaxSort, ...passedItemAttributes }
                  : { id: newMaxId, ...passedItemAttributes }
                : convertValueByType('', fieldType),
          },
        ]);
      }
    },
    [Item, fieldType, fields.length, isDragable, maxFields, value],
  );

  const handleRemoveField = useCallback((key) => {
    setFields((prev) => prev.filter((field) => field.key !== key));
  }, []);

  const handleOnChange = useCallback(
    (key, e) => {
      const newValue = e.target?.value ?? e;
      setFields((prevFields) =>
        prevFields.map((item) =>
          item.key === key ? { ...item, value: convertValueByType(newValue, fieldType) } : item,
        ),
      );
    },
    [fieldType],
  );

  const handleMoveField = useCallback((dragIndex, hoverIndex) => {
    setFields((prevRows) => {
      const updatedRows = prevRows.map((row, index) => {
        if (index === dragIndex) {
          return { ...row, value: { ...row.value, sort: prevRows[hoverIndex].value.sort } };
        } else if (index === hoverIndex) {
          return { ...row, value: { ...row.value, sort: prevRows[dragIndex].value.sort } };
        }
        return row;
      });
      return updatedRows;
    });
  }, []);

  return {
    structuredFields,
    handleAddField,
    handleRemoveField,
    handleOnChange,
    handleMoveField,
    updateMaxKeys,
  };
}
