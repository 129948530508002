import React from 'react';
import styles from './sales-by-category.module.scss';
import HeaderReport from "../components/HeaderReport/header-report";
import clsx from "clsx";
import DateRange from "../components/DateRange/date-range";
import ReportTable from "./report-table";

function SalesByCategory() {

    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const downloadPdf = () => {
    }
    const downloadCsv = () => {}

    const fakeData = {
        category_sales: [
            ["Category 1", [
                ["Item 1", 10, 1000.50, 2000.75, 3000.25, 4000.10],
                ["Item 2", 20, 1100.50, 2100.75, 3100.25, 4100.10],
                ["Total", 30, 2100.50, 4100.75, 6100.25, 8100.10]
            ]],
            ["Category 2", [
                ["Item 3", 15, 1200.50, 2200.75, 3200.25, 4200.10],
                ["Item 4", 25, 1300.50, 2300.75, 3300.25, 4300.10],
                ["Total", 40, 2500.50, 4500.75, 6500.25, 8500.10]
            ]]
        ]
    };

  return (
      <div className={styles.contentWrapper}>
          <HeaderReport
              title={"Category Sales"}
              downloadPdf={downloadPdf}
              downloadCsv={downloadCsv}
          />
          <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
              <DateRange
                  setEndDate={setEndDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  startDate={startDate}
              />
          </div>
          <div className="bg-white table-responsive mt-4">
              <ReportTable categorySales={fakeData}/>
          </div>
      </div>
  )
}

export default SalesByCategory;
