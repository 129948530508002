import { api } from './base';
// import { api } from './mocked/cancel-order-reasons/mockedAxiosApi';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getCancelReasons = (token) => {
  return handleApiResponse(() =>
    api.get('cancel_order_reasons', {
      headers: {
        TOKEN: token,
      },
    }),
  );
};

export const getCancelReason = (id, token) =>
  handleApiResponse(() =>
    api.get(`cancel_order_reasons/${id}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const addCancelReason = (newCancelReasonDetails, token) =>
  handleApiResponse(() =>
    api.post('cancel_order_reasons', newCancelReasonDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const updateCancelReason = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`cancel_order_reasons/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const deleteCancelReason = (id, token) =>
  handleApiResponse(() =>
    api.delete(`cancel_order_reasons/${id}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const reorderCancelReason = (id, { old_position, new_position }, token) => {
  return handleApiResponse(() =>
    api.put(
      `cancel_order_reasons/${id}/reorder?old_position=${old_position}&new_position=${new_position}`,
      {},
      {
        headers: {
          TOKEN: token,
        },
      },
    ),
  );
};
