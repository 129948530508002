import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styles from './customers.module.scss';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { showModal, hideModal } from '../../../redux/modalSlice';

import {
  fetchCustomers,
  fetchCustomersForLength,
  updateExistingCustomer,
  removeCustomer,
  setQueryDetails,
} from '../../../redux/customersSlice';

import { useAppContext } from '../../../context/context';

import PageTitle from '../../../components/utilities/page-title/page-title';
import Search from '../../../components//utilities/search/search';
import Pagination from '../../../components/utilities/pagination/pagination';
import Button from '../../../components/utilities/button/button';

import CustomerList from './customer-list';

const PAGINATION_OPTIONS = [
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];

const PAGINATION = {
  search: '',
  page: 1,
  perPage: 5,
};

const isObjectEmpty = (objectName) => {
  return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
};

function Customers() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isInitialized, setIsInitialized] = useState(false);

  const currentPage = useMemo(() => parseFloat(searchParams.get('page') || PAGINATION.page), [searchParams]);

  const queryDetails = useSelector((state) => {
    return state.customers.queryDetails;
  });
  const customersStatus = useSelector((state) => state.customers.status);
  const customers = useSelector((state) => state.customers.customers);
  const numberOfAllCustomers = useSelector((state) => state.customers.numberOfAllCustomers);

  const dispatch = useDispatch();
  const { token } = useAppContext();

  useEffect(() => {
    if (!isInitialized) {
      if (isObjectEmpty(queryDetails)) {
        dispatch(setQueryDetails({ ...PAGINATION, page: currentPage }));
      }
      setIsInitialized(true);
    }

    if (isInitialized && token) {
      dispatch(fetchCustomers({ token, queryDetails }));
      dispatch(fetchCustomersForLength({ token }));
    }

    if (isInitialized && currentPage !== queryDetails.page) {
      setQueryDetails({ page: currentPage });
    }
  }, [dispatch, queryDetails, isInitialized, token, currentPage]);

  const handleSetResult = (result) => {
    dispatch(setQueryDetails({ search: result }));
  };

  const handleToggleActive = useCallback(
    (id, value) => {
      const updatedDetails = {
        ...customers.find((cust) => cust.id === id),
        is_active: value,
      };
      dispatch(updateExistingCustomer({ id, updatedDetails, token }));
    },
    [dispatch, token, customers],
  );

  const handleSelectPageCount = (value) => {
    dispatch(setQueryDetails({ perPage: value }));
  };

  const handleClickPrev = () => {
    if (queryDetails.page > 1) {
      dispatch(setQueryDetails({ page: queryDetails.page - 1 }));
    }
  };

  const handleClickNext = () => {
    dispatch(setQueryDetails({ page: queryDetails.page + 1 }));
  };

  const handleAddNewCustomer = () => {
    navigate(`/customers/new`);
  };

  const handleEditCustomer = (id) => {
    navigate(`/customers/${id}/edit`);
  };

  const handleDeleteCustomer = (id) => {
    dispatch(
      showModal({
        modalId: 'modal-delete-customer',
        data: {
          type: 'confirmation',
          title: 'Delete Confirmation',
          message: 'Are you sure you want to delete this customer',
          actions: [
            {
              title: 'Delete',
              onAction: () => {
                dispatch(removeCustomer({ id, token }));
                dispatch(hideModal('modal-delete-customer'));
              },
            },
            { title: 'Cancel', button_variant: 'grey', onAction: () => dispatch(hideModal('modal-delete-customer')) },
          ],
        },
      }),
    );
  };

  const handleSearchClean = () => {
    dispatch(setQueryDetails({ search: '' }));
  };

  return (
    <>
      <PageTitle name={'Customers'} />
      <div className={styles.list_controller}>
        <div className={styles.filter_container}>
          <div className={styles.role_selector}></div>
          <div className={styles.search}>
            <Search
              placeholder="Search Customers"
              onSearchResults={handleSetResult}
              onClear={handleSearchClean}
              autoFilter={false}
            />
          </div>
        </div>
        <div>
          <Button title={'Create Customer'} onClick={handleAddNewCustomer} />
        </div>
      </div>
      <CustomerList
        customers={customers}
        onEdit={handleEditCustomer}
        onDelete={handleDeleteCustomer}
        onToggleActive={handleToggleActive}
        isLoading={customersStatus === 'loading'}
      />
      <Pagination
        pageSizeOptions={PAGINATION_OPTIONS}
        numberOfItems={numberOfAllCustomers}
        currentPage={queryDetails.page}
        currentPageSize={queryDetails.perPage}
        onPageSizeChange={handleSelectPageCount}
        onPrev={handleClickPrev}
        onNext={handleClickNext}
      />
    </>
  );
}

export default Customers;
