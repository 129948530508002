import { api } from './base';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getOrders = (token) => {
  return handleApiResponse(() =>
    api.get('order_types', {
      headers: {
        TOKEN: token,
      },
    }),
  );
};

export const getOrder = (id, token) =>
  handleApiResponse(() =>
    api.get(`order_types/${id}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const addOrder = (newOrderDetails, token) =>
  handleApiResponse(() =>
    api.post('order_types', newOrderDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const updateOrder = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`order_types/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const deleteOrder = (id, token) =>
  handleApiResponse(() =>
    api.delete(`order_types/${id}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );
