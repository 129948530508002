import React from 'react';

import './HeaderReport.scss';
import "./../typographyReport.scss";
import download from './../../../../../assets/svg/download.svg';
import exportCSV from './../../../../../assets/svg/export.svg';
import {FaArrowLeft} from "react-icons/fa";

export default function HeaderReport({ title, showDownLoad = false, downloadPdf, downloadCsv, showBack = false, onNavigate, })
{
    return (
        <div className="row">
            <div className="col-8 d-flex align-items-center">
                {showBack && <button onClick={() => onNavigate()} className="btn btn-link mr-3">
                    <FaArrowLeft color={'black'} />
                </button>}
                <h2 className="fw-600 fs-20 mt-2">{title}</h2>
            </div>
            <div className="col-4">
                <div
                    className="back-area inventory-top bg-white d-sm-flex justify-content-between col-sm-9 pb-2 pt-0">
                    {showDownLoad && (
                        <div className="download-area">
                            <a onClick={downloadPdf}
                               className="exportCancelOrdersPdf text-darkgray1 fw-500 fs-14 d-flex align-items-center mr-2">
                                <img src={download} className="mr-2" alt="Download PDF"/> Download pdf
                            </a>
                            <a onClick={downloadCsv}
                               className="exportCancelOrders text-darkgray1 fw-500 fs-14 d-flex align-items-center">
                                <img src={exportCSV} className="mr-2" alt="Download CSV"/> Download CSV
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>

    );
}
