import React from "react";
import Chartkick, { PieChart } from "react-chartkick";
import { Chart } from "chart.js";

import BackdropLoading from "../../../../utilities/backdrop-loading/backdrop-loading";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  PieController,
} from "chart.js";

import styles from "./payment-methods.module.scss";

ChartJS.register(ArcElement, Tooltip, Legend, PieController);
Chartkick.addAdapter(ChartJS);

const PaymentMethodsDonutChart = ({ data, isLoading }) => {
  const allZero = data && Object.values(data).every((value) => value === 0);

  const adjustedData = allZero
  ? { "No data for the selected time period": 1 }
  : data;

  return (
    <div className={styles.chart_wrapper}>
      {isLoading && <BackdropLoading />}
      <div className={styles.chart}>
          <PieChart
            data={adjustedData}
            id="payment-method-chart"
            donut={true}
            messages={{ empty: "No Records available." }}
            prefix="$ "
            thousands=","
            round={2}
            colors={allZero ? ["#d3d3d3"] : [
              "#ED5950",
              "#70c043",
              "#eac30b",
              "#a25d9d",
              "#cd3950",
              "#42d7ae",
            ]}
            library={{
              cutout: "50%",
              plugins: {
                legend: {
                  position: "bottom",
                  labels: {
                    usePointStyle: true,
                    boxWidth: 7,
                    padding: 20,
                  },
                },
                tooltip: {
                  callbacks: {
                    label: (context) =>
                      allZero
                        ? "No Data"
                        : `${context.label}: $${context.raw}`,
                  },
                },
              },
            }}
          />
      </div>
    </div>
  );
};

export default PaymentMethodsDonutChart;
