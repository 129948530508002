import { z } from 'zod';

export const registrationSchema = z.object({
  name: z
    .string({ required_error: 'Please enter Name' })
    .min(1, { message: 'Name must contain at least 1 characters' })
    .max(60, { message: 'Name can contain a maximum of 60 characters' })
    .regex(/^[-a-zA-Z0-9-()]+(\s[-a-zA-Z0-9-()]+)*$/, { message: 'Please enter valid value' }),

  percentage: z
    .number({ required_error: 'Please enter Service Rate' })
    .min(0.01, { message: 'Service Rate should be greater than 0.01' })
    .max(100, { message: 'Service Rate should not exceed 100' }),

  // min_guest_count: z
  //   .number({ required_error: 'Please enter Min guest count' })
  //   .min(1, { message: 'Min guest count should be greater than 1' })
  //   .max(100, { message: 'Max guest count should not exceed 100' }),

  // max_guest_count: z
  //   .number({ required_error: 'Please enter Max guest count' })
  //   .min(1, { message: 'Min guest count should be greater than 1' })
  //   .max(100, { message: 'Max guest count should not exceed 100' }),
});
