import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";

import { useAppContext } from "../../../../context/context";
import {
  fetchLocations,
  updateExistingBatchCloseTime,
  updateExistingTimeZone,
} from "../../../../redux/locationSlice";

import PageTitle from "../../../utilities/page-title/page-title";
import Selector from "../../../utilities/selector/selector";
import Label from "../../../utilities/label/label";
import Button from "../../../utilities/button/button";

import { IoIosArrowDown } from "react-icons/io";

import clsx from "clsx";
import styles from "./batch-close-time.module.scss";

const SELECTOR_OPTIONS = [
  { value: "00:30 AM", label: "00:30 AM" },
  { value: "01:00 AM", label: "01:00 AM" },
  { value: "01:30 AM", label: "01:30 AM" },
  { value: "02:00 AM", label: "02:00 AM" },
  { value: "02:30 AM", label: "02:30 AM" },
  { value: "03:00 AM", label: "03:00 AM" },
  { value: "03:30 AM", label: "03:30 AM" },
  { value: "04:00 AM", label: "04:00 AM" },
  { value: "04:30 AM", label: "04:30 AM" },
  { value: "05:00 AM", label: "05:00 AM" },
  { value: "05:30 AM", label: "05:30 AM" },
  { value: "06:00 AM", label: "06:00 AM" },
  { value: "06:30 AM", label: "06:30 AM" },
  { value: "07:00 AM", label: "07:00 AM" },
  { value: "07:30 AM", label: "07:30 AM" },
  { value: "08:00 AM", label: "08:00 AM" },
  { value: "08:30 AM", label: "08:30 AM" },
  { value: "09:00 AM", label: "09:00 AM" },
  { value: "09:30 AM", label: "09:30 AM" },
  { value: "10:00 AM", label: "10:00 AM" },
  { value: "10:30 AM", label: "10:30 AM" },
  { value: "11:00 AM", label: "11:00 AM" },
  { value: "11:15 AM", label: "11:15 AM" },
  { value: "11:30 AM", label: "11:30 AM" },
  { value: "12:00 AM", label: "12:00 AM" },
  { value: "12:30 AM", label: "12:30 AM" },
  { value: "01:00 PM", label: "01:00 PM" },
  { value: "01:30 PM", label: "01:30 PM" },
  { value: "02:00 PM", label: "02:00 PM" },
  { value: "02:30 PM", label: "02:30 PM" },
  { value: "03:00 PM", label: "03:00 PM" },
  { value: "03:30 PM", label: "03:30 PM" },
  { value: "04:00 PM", label: "04:00 PM" },
  { value: "04:30 PM", label: "04:30 PM" },
  { value: "05:00 PM", label: "05:00 PM" },
  { value: "05:30 PM", label: "05:30 PM" },
  { value: "06:00 PM", label: "06:00 PM" },
  { value: "06:30 PM", label: "06:30 PM" },
  { value: "07:00 PM", label: "07:00 PM" },
  { value: "07:30 PM", label: "07:30 PM" },
  { value: "08:00 PM", label: "08:00 PM" },
  { value: "08:30 PM", label: "08:30 PM" },
  { value: "09:00 PM", label: "09:00 PM" },
  { value: "09:30 PM", label: "09:30 PM" },
  { value: "10:00 PM", label: "10:00 PM" },
  { value: "10:30 PM", label: "10:30 PM" },
  { value: "11:00 PM", label: "11:00 PM" },
  { value: "11:30 PM", label: "11:30 PM" },
  { value: "00:00 PM", label: "00:00 PM" },
];

const timezones = moment.tz.names().map((timezone) => {
  const offset = moment.tz(timezone).format("Z");
  return {
    label: `(GMT${offset}) ${timezone}`,
    value: `${timezone}`,
  };
});

function BatchCloseTime() {
  // Global State
  const locations = useSelector((state) => state.locations.locations);
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  // Local State
  const [selectedBatchCloseTime, setSelectedBatchCloseTime] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [isBatchCloseTimeWasChanged, setIsBatchCloseTimeWasChanged] =
    useState(false);
  const [isTimeZoneWasChanged, setIsTimeZoneWasChanged] = useState(false);

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchLocations(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (locations.length > 0) {
      const { batch_close_time, time_zone } = locations.find(
        (location) => location.id === locationId
      );
      setSelectedBatchCloseTime(batch_close_time);
      setSelectedTimeZone(time_zone);
    }
  }, [locations, locationId]);

    // Handlers
  const handleSelectReportTime = (selectedValue) => {
    setSelectedBatchCloseTime(selectedValue);
    setIsBatchCloseTimeWasChanged(true);
  };

  const handleSelectTimeZone = (selectedValue) => {
    setSelectedTimeZone(selectedValue);
    setIsTimeZoneWasChanged(true);
  };

  const handleUpdateBatchCloseTime = () => {
    if (isBatchCloseTimeWasChanged) {
      dispatch(
        updateExistingBatchCloseTime({
          updatedTime: selectedBatchCloseTime,
          token,
          locationId,
        })
      );
      setIsBatchCloseTimeWasChanged(false);
    }
  };

  const handleUpdateTimeZone = () => {
    if (isTimeZoneWasChanged) {
      dispatch(
        updateExistingTimeZone({
          updatedTimeZone: selectedTimeZone,
          token,
          locationId,
        })
      );
      setIsTimeZoneWasChanged(false);
    }
  };

  const handleSave = async () => {
    handleUpdateBatchCloseTime();
    handleUpdateTimeZone();
  };

  return (
    <div className={styles.batch_close_time}>
      <PageTitle name={"Batch Close Reporting Time"} />
      <div className={styles.selector_wrapper}>
        <Label text="Batch Close Time" />
        <Selector
          icon={IoIosArrowDown}
          items={SELECTOR_OPTIONS}
          placeholder={selectedBatchCloseTime}
          onSelectItem={handleSelectReportTime}
          selectedItem={selectedBatchCloseTime}
        />
      </div>
      <div className={styles.time_zone_wrapper}>
        <Label text="Time Zone" />
        <Selector
          icon={IoIosArrowDown}
          items={timezones}
          placeholder={selectedTimeZone}
          onSelectItem={handleSelectTimeZone}
          selectedItem={selectedTimeZone}
        />
      </div>
      <Button
        className={clsx(
          styles.save_button,
          isBatchCloseTimeWasChanged === false && isTimeZoneWasChanged === false
            ? styles.disabled
            : null
        )}
        title={"Save"}
        onClick={handleSave}
      />
    </div>
  );
}

export default BatchCloseTime;
