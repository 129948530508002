import { IoTrash } from 'react-icons/io5';
import { MdModeEditOutline } from 'react-icons/md';
import Switch from '../../../utilities/switch/switch';
import List from '../../../utilities/list/list';

import { format } from 'date-fns';

import styles from './empolyees-list.module.scss';

const DATE_FROMAT = "MMMM d, yyyy 'at' hh:mm a";

const EmployeeList = ({ employees, roles = [], onEdit, onDelete, onToggleActive, isLoading }) => {
  const renderFullName = (item) => <span className={styles.full_name}>{item.full_name}</span>;
  const renderTeamRole = (item) => roles.find((role) => role.value === item.team_role_id)?.label ?? item.team_role_id;
  const renderSwitcher = (item) => (
    <Switch id={item.id} roleName={'User'} isActive={item.is_active} onSwitch={onToggleActive} />
  );
  const renderTime = (item) => <span>{format(item.created_at, DATE_FROMAT)}</span>;

  const columnConfig = [
    {
      key: 'full_name',
      header: 'Full Name',
      render: renderFullName,
    },
    {
      key: 'team_role_id',
      header: 'Role',
      render: renderTeamRole,
    },
    { key: 'email', header: 'Email' },
    { key: 'phone_number', header: 'Phone' },
    { key: 'passcode', header: 'Passcode' },
    {
      key: 'is_active',
      header: 'Status',
      render: renderSwitcher,
    },
    { key: 'created_at', header: 'Created At', render: renderTime },
  ];

  const rowActions = [
    { icon: <MdModeEditOutline />, onClick: onEdit, label: 'Edit employee', className: styles.row_actions_button },
    { icon: <IoTrash />, onClick: onDelete, label: 'Delete employee', className: styles.row_actions_button },
  ];

  return <List data={employees} columnConfig={columnConfig} rowActions={rowActions} isLoading={isLoading} />;
};

export default EmployeeList;
