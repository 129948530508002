import React, {useState} from 'react';
import HeaderReport from "../components/HeaderReport/header-report";
import clsx from "clsx";
import {FiltersForReports} from "../components/OrderFilters";
import {DateRange} from "../components/DateRange";
import ReportTable from "./ReportTable";

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './gift-card-customer-usage.module.scss';

function GiftCardCustomerUsage() {
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);

    const downloadPdf = () => {}
    const downloadCsv = () => {}
    const giftCardCustomerUsage = [
        {
            order_id: 1,
            date_time: '2020-01-01',
            amount: 100,
            balance: 100
        },
        {
            order_id: 2,
            date_time: '2020-01-02',
            amount: 200,
            balance: 200
        },
        {
            order_id: 3,
            date_time: '2020-01-03',
            amount: 300,
            balance: 300
        }
    ];


  return (
      <div className={styles.contentWrapper}>
          <HeaderReport
              title={"Gift Card Customer Usage"}
              downloadPdf={downloadPdf}
              downloadCsv={downloadCsv}
          />
          <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
              <FiltersForReports
                  dropdown={[]}
              />
          </div>
          <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
              <DateRange
                  endDate={endDate}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
              />
          </div>
        <ReportTable
            giftCardCustomerUsage={giftCardCustomerUsage}
            startDate={startDate}
            endDate={endDate}
        />
      </div>
  )
}

export default GiftCardCustomerUsage;
