import { z } from 'zod';
import { isValid, parseISO } from 'date-fns';

const addressRegistrationSchema = z.object({
  address1: z
    .string()
    .regex(/^[-a-zA-Z0-9,]+(\s[-a-zA-Z0-9,]+)*$/, 'Please enter valid value.')
    .max(60, { message: 'Please enter 60 characters or fewer.' })
    .nullable()
    .optional(),
  address2: z
    .string()
    .regex(/^[-a-zA-Z0-9,]+(\s[-a-zA-Z0-9,]+)*$/, 'Please enter valid value.')
    .max(60, { message: 'Please enter 60 characters or fewer.' })
    .nullable()
    .optional(),
  state: z
    .string()
    .regex(/^[-a-zA-Z0-9,]+(\s[-a-zA-Z0-9,]+)*$/, 'Please enter valid value.')
    .max(30, { message: 'Please enter 30 characters or fewer.' })
    .nullable()
    .optional(),
  city: z
    .string()
    .regex(/^[-a-zA-Z0-9,]+(\s[-a-zA-Z0-9,]+)*$/, 'Please enter valid value.')
    .max(30, { message: 'Please enter 30 characters or fewer.' })
    .nullable()
    .optional(),
  postcode: z
    .string()
    .regex(/^[-a-zA-Z0-9,]+(\s[-a-zA-Z0-9,]+)*$/, 'Please enter valid value.')
    .max(10, { message: 'Please enter 10 characters or fewer.' })
    .nullable()
    .optional(),
});

export const registrationSchema = z.object({
  first_name: z
    .string({ required_error: 'Please enter First Name' })
    .min(1, { message: 'Please enter First Name' })
    .max(20, { message: 'First Name can contain a maximum of 20 characters' }),
  last_name: z
    .string()
    .max(20, { message: 'Last Name can contain maximum upto 20 characters' })
    .nullable()
    .optional(),
  email: z.string({ required_error: 'Email is required' }).min(1).email('Please enter valid Email Address'),
  birth_date: z
    .string()
    .regex(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/, 'Birth date should be in YYYY-MM-DD format')
    .refine((date) => isValid(parseISO(date)), 'Incorrect date')
    .optional(),
  addresses_attributes: z.array(addressRegistrationSchema),
});
