import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAppContext } from '../../../../context/context';

import { fetchTeamRoles, createTeamRole, fetchModules, fetchEmployees } from '../../../../redux/rolesSlice';

import AssignRolesList from './assign-roles-list';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Input from '../../../../components/utilities/input/input';
import Button from '../../../../components/utilities/button/button';
import BackdropLoading from '../../../../components/utilities/backdrop-loading/backdrop-loading';

import { isArrayEmpty } from '../../../utilities/utils';
import useFormValidation from '../../../utilities/useFormValidation';
import { registrationSchema } from './validation';

import clsx from 'clsx';
import styles from './team-roles.module.scss';

function Roles() {
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  const rolesStatus = useSelector((state) => state.roles.status);
  const roles = useSelector((state) => state.roles.roles);
  const modules = useSelector((state) => state.roles.modules);
  const employees = useSelector((state) => state.roles.employees);

  const { errors, validate } = useFormValidation(registrationSchema);

  const [newRoleName, setNewRoleName] = useState('');
  const [selectedModules, setSelectedModules] = useState([]);
  const [rightBlockModules, setRightBlockModules] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [rightBlockEmployees, setRightBlockEmployees] = useState([]);

  useEffect(() => {
    if (!roles.length || !modules.length || !employees.length) {
      Promise.all([
        dispatch(fetchTeamRoles(token)),
        dispatch(fetchModules(token)),
        dispatch(fetchEmployees(token)),
      ]).catch((error) => console.error('Error fetching data', error));
    }
  }, [dispatch, token, roles.length, modules.length, employees.length]);

  const handleItemClick = (id, selectedItems, setSelectedItems) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(id) ? prevSelectedItems.filter((itemId) => itemId !== id) : [...prevSelectedItems, id],
    );
  };

  const handleAddAll = (allItems, selectedItems, rightBlockItems, setRightBlockItems, setSelectedItems) => (event) => {
    event.preventDefault();

    const newItems = allItems.filter(
      (item) => selectedItems.includes(item.id) && !rightBlockItems.some((rightItem) => rightItem.id === item.id),
    );

    setRightBlockItems([...rightBlockItems, ...newItems]);
    setSelectedItems([]);
  };

  const handleRemoveAll = (setter) => (event) => {
    event.preventDefault();
    setter([]);
  };

  const handleSaveAddForm = async (event) => {
    event.preventDefault();

    const maxId = roles.reduce((max, role) => (role.id > max ? role.id : max), 0);
    const employeeIds = rightBlockEmployees.map((employee) => employee.id);
    const moduleIds = rightBlockModules.map((module) => module.id);

    const newDetails = {
      id: maxId + 1,
      name: newRoleName,
      location_id: locationId,
      employee_ids: employeeIds,
      module_ids: moduleIds,
    };

    if (validate(newDetails)) {
      try {
        await dispatch(createTeamRole({ newDetails, token })).unwrap();
        resetForm();
      } catch {}
    }
  };

  const handleCancelAddForm = (event) => {
    event.preventDefault();
    resetForm();
  };

  const resetForm = () => {
    setNewRoleName('');
    setRightBlockModules([]);
    setRightBlockEmployees([]);
  };

  return (
    <>
      <div className={styles.roles}>
        <div className={styles.header}>
          <PageTitle name="Roles" />
        </div>
        <form className={styles.create_role}>
          <div className={styles.role_name}>
            <Input
              label="Add Role Name"
              type="text"
              value={newRoleName}
              placeholder="Role Name"
              onChange={(e) => setNewRoleName(e.target.value)}
              required
              errors={errors.name?._errors}
            />
          </div>
          <div className={styles.select_module}>
            <h5 className={styles.subtitle}>Select module</h5>
            <div className={styles.wrapper}>
              <div className={styles.modules_list}>
                {rolesStatus === 'loading' && <BackdropLoading />}
                {modules
                  .slice()
                  .sort((a, b) => a.id - b.id)
                  .map((module) => (
                    <div
                      key={module.id}
                      onClick={() => handleItemClick(module.id, selectedModules, setSelectedModules)}
                      className={clsx(styles.module, {
                        [styles.module_selected]: selectedModules.includes(module.id),
                      })}
                    >
                      {module.name}
                    </div>
                  ))}
              </div>

              <div className={styles.buttons}>
                <Button
                  title="Add All"
                  onClick={handleAddAll(
                    modules,
                    selectedModules,
                    rightBlockModules,
                    setRightBlockModules,
                    setSelectedModules,
                  )}
                />
                <Button title="Remove All" onClick={handleRemoveAll(setRightBlockModules)} variant="grey" />
              </div>

              <div className={styles.selected_modules}>
                {rightBlockModules
                  .slice()
                  .sort((a, b) => a.id - b.id)
                  .map((module) => (
                    <div key={module.id} className={styles.right_item}>
                      {module.name}
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className={styles.select_employee}>
            <h5 className={styles.subtitle}>Select employee</h5>
            <div className={styles.wrapper}>
              <div className={styles.employee_list}>
                {rolesStatus === 'loading' && <BackdropLoading />}
                {employees
                  .slice()
                  .sort((a, b) => a.id - b.id)
                  .map((employee) => (
                    <div
                      key={employee.id}
                      onClick={() => handleItemClick(employee.id, selectedEmployees, setSelectedEmployees)}
                      className={clsx(styles.employee, {
                        [styles.employee_selected]: selectedEmployees.includes(employee.id),
                      })}
                    >
                      {employee.name}
                    </div>
                  ))}
              </div>

              <div className={styles.buttons}>
                <Button
                  title="Add All"
                  onClick={handleAddAll(
                    employees,
                    selectedEmployees,
                    rightBlockEmployees,
                    setRightBlockEmployees,
                    setSelectedEmployees,
                  )}
                />
                <Button title="Remove All" onClick={handleRemoveAll(setRightBlockEmployees)} variant="grey" />
              </div>

              <div className={styles.selected_employees}>
                {rightBlockEmployees
                  .slice()
                  .sort((a, b) => a.id - b.id)
                  .map((employee) => (
                    <div key={employee.id} className={styles.right_item}>
                      {employee.name}
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className={styles.submit_buttons}>
            <Button title="Save" onClick={handleSaveAddForm} />
            <Button title="Cancel" onClick={handleCancelAddForm} />
          </div>
        </form>
        {!isArrayEmpty(roles) && !isArrayEmpty(employees) && !isArrayEmpty(modules) && (
          <AssignRolesList roles={roles} all_employees={employees} all_modules={modules} />
        )}
      </div>
    </>
  );
}

export default Roles;
