import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getDayReportSettings, updateDayReportSettings } from '../api/end_of_day_report_settings';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchEndOfDayReportSettings = createAsyncThunk(
  'endOfDayReportSettings/fetchEndOfDayReportSettings',
  async ({ token }) => {
    const response = await getDayReportSettings(token);
    return response.data;
  },
);

export const updateEndOfDayReportSettings = createAsyncThunk(
  'endOfDayReportSettings/updateEndOfDayReportSettings',
  async ({ updatedDetails, token }, { dispatch }) => {
    try {
      await updateDayReportSettings(updatedDetails, token);
      dispatch(
        addNotification({
          message: 'End of day report settings successfully saved',
          status: 'succeeded',
        }),
      );
      return { updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to save End of day report settings, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

const initialState = {
  initialEndOfDayReportSettings: {},
  endOfDayReportSettings: {},
  status: 'idle',
  error: null,
};

const endOfDayReportSettingsSlice = createSlice({
  name: 'endOfDayReportSettings',
  initialState,
  reducers: {
    updateState(state, action) {
      state.endOfDayReportSettings = {
        ...state.endOfDayReportSettings,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action?.error.message;
    };

    builder
      .addCase(fetchEndOfDayReportSettings.pending, setLoading)
      .addCase(fetchEndOfDayReportSettings.fulfilled, (state, action) => {
        setSucceeded(state);
        state.initialEndOfDayReportSettings = action.payload;
        state.endOfDayReportSettings = action.payload;
      })
      .addCase(fetchEndOfDayReportSettings.rejected, setFailed)

      .addCase(updateEndOfDayReportSettings.pending, setLoading)
      .addCase(updateEndOfDayReportSettings.fulfilled, (state, action) => {
        setSucceeded(state);
        state.initialEndOfDayReportSettings = { ...state.endOfDayReportSettings, ...action.payload };
        state.endOfDayReportSettings = state.initialEndOfDayReportSettings;
      })
      .addCase(updateEndOfDayReportSettings.rejected, setFailed);
  },
});

export const { updateState } = endOfDayReportSettingsSlice.actions;
export default endOfDayReportSettingsSlice.reducer;
