import { useState } from 'react';

import Input from '../../../../utilities/input/input';
import Button from '../../../../utilities/button/button';

import useFormValidation from '../../../../utilities/useFormValidation';
import { registrationSchema } from './validation';

import styles from '../wastage-reasons.module.scss';

const WastageForm = ({ onAdd }) => {
  const [reasonName, setReasonName] = useState('');

  const { errors, validate } = useFormValidation(registrationSchema);

  const handleSubmit = () => {
    if (validate({ name: reasonName })) {
      onAdd(reasonName);
      setReasonName('');
    }
  };

  return (
    <div className={styles.list_controller}>
      <div className={styles.input_wastage}>
        <Input
          label="Wastage Reason Name"
          placeholder="Add Wastage Reason Name"
          value={reasonName}
          onChange={(e) => setReasonName(e.target.value)}
          style={{ marginBottom: 0, width: '100%' }}
          required
          errors={errors.name?._errors}
        />
      </div>
      <Button title="Add Wastage" onClick={handleSubmit} className={styles.add_button} />
    </div>
  );
};

export default WastageForm;
