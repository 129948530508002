import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  fetchEmployees,
  fetchEmployeesForLength,
  updateExistingEmployee,
  removeEmployee,
  setQueryDetails,
} from '../../../../redux/employeesSlice';

import { fetchTeamRoles } from '../../../../redux/rolesSlice';
import { showModal, hideModal } from '../../../../redux/modalSlice';

import { IoIosArrowDown } from 'react-icons/io';

import { useAppContext } from '../../../../context/context';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Selector from '../../../../components//utilities/selector/selector';
import Search from '../../../../components//utilities/search/search';
import Pagination from '../../../../components/utilities/pagination/pagination';
import Button from '../../../../components/utilities/button/button';

import EmployeeList from './employees-list';

import styles from './employees.module.scss';

const PAGINATION_OPTIONS = [
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];

const PAGINATION = {
  search: '',
  page: 1,
  perPage: 5,
};

const isObjectEmpty = (objectName) => {
  return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
};

function Employees() {
  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState(false);

  const queryDetails = useSelector((state) => state.employees.queryDetails);
  const employeesStatus = useSelector((state) => state.employees.status);
  const employees = useSelector((state) => state.employees.employees);
  const rolesOptions = useSelector((state) => state.roles.nameOptions);
  const numberOfAllEmployees = useSelector((state) => state.employees.numberOfAllEmployees);

  const [selectedRole, setSelectedRole] = useState({});
  
  const dispatch = useDispatch();
  const { token } = useAppContext();

  useEffect(() => {
    if (!isInitialized) {
      if (isObjectEmpty(queryDetails)) {
        dispatch(setQueryDetails(PAGINATION));
        dispatch(fetchTeamRoles(token));
      }
      setIsInitialized(true);
    }

    if (isInitialized && token) {
      dispatch(fetchEmployees({ token, queryDetails }));
      dispatch(fetchEmployeesForLength({ token }));
    }
  }, [dispatch, queryDetails, isInitialized, token]);

  const handleSetResult = (result) => {
    dispatch(setQueryDetails({ search: result }));
  };

/*   const handleFilterByRole = (result) => {
    dispatch(setQueryDetails({ team_role_id: result }));
  }; */

  const handleSelectRole = (value) => {
    setSelectedRole({ label: value, value: +value });
    dispatch(setQueryDetails({ team_role_id: value }));
  };

  const handleToggleActive = useCallback(
    (id, value) => {
      const updatedDetails = {
        ...employees.find((employee) => employee.id === id),
        is_active: value,
      };
      dispatch(updateExistingEmployee({ id, updatedDetails, token }));
    },
    [dispatch, token, employees],
  );

  const handleSelectPageCount = (value) => {
    dispatch(setQueryDetails({ perPage: value }));
  };

  const handleClickPrev = () => {
    if (queryDetails.page > 1) {
      dispatch(setQueryDetails({ page: queryDetails.page - 1 }));
    }
  };

  const handleClickNext = () => {
    dispatch(setQueryDetails({ page: queryDetails.page + 1 }));
  };

  const handleAddNewEmployee = () => {
    navigate(`/employee/employees/new`);
  };

  const handleEditEmployee = (id) => {
    navigate(`/employee/employees/${id}/edit`);
  };

  const handleDeleteEmployee = (id) => {
    dispatch(
      showModal({
        modalId: 'modal-delete-employee',
        data: {
          type: 'confirmation',
          title: 'Delete Confirmation',
          message: 'Are you sure you want to delete this employee',
          actions: [
            {
              title: 'Delete',
              onAction: () => {
                dispatch(removeEmployee({ id, token }));
                dispatch(hideModal('modal-delete-employee'));
              },
            },
            { title: 'Cancel', button_variant: 'grey', onAction: () => dispatch(hideModal('modal-delete-employee')) },
          ],
        },
      }),
    );
  };

  const handleSearchClean = () => {
    dispatch(setQueryDetails({ search: '' }));
  };

  return (
    <>
      <PageTitle name={'Employees'} />
      <div className={styles.list_controller}>
        <div className={styles.filter_container}>
          <div className={styles.role_selector}>
            <Selector
              icon={IoIosArrowDown}
              items={rolesOptions}
              selectedItem={selectedRole.label}
              placeholder={'Select Role'}
              //onSelectItem={handleFilterByRole}
              onSelectItem={handleSelectRole}
            />
          </div>
          <div className={styles.search}>
            <Search
              placeholder="Search Employees"
              onSearchResults={handleSetResult}
              onClear={handleSearchClean}
              autoFilter={false}
            />
          </div>
        </div>
        <div>
          <Button title={'Create Employee'} onClick={handleAddNewEmployee} />
        </div>
      </div>
      <EmployeeList
        employees={employees}
        roles={rolesOptions}
        onEdit={handleEditEmployee}
        onDelete={handleDeleteEmployee}
        onToggleActive={handleToggleActive}
        isLoading={employeesStatus === 'loading'}
      />
      <Pagination
        pageSizeOptions={PAGINATION_OPTIONS}
        numberOfItems={numberOfAllEmployees}
        currentPage={queryDetails.page}
        currentPageSize={queryDetails.perPage}
        onPageSizeChange={handleSelectPageCount}
        onPrev={handleClickPrev}
        onNext={handleClickNext}
      />
    </>
  );
}

export default Employees;
