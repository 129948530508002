import { api, handleApiResponse } from "./base";

export const getTeamRoles = (token) =>
  handleApiResponse(() =>
    api.get("team_roles", {
      headers: {
        TOKEN: token,
      },
    })
  );

export const addTeamRole = (newDetails, token) =>
  handleApiResponse(() =>
    api.post("team_roles", newDetails, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const updateTeamRole = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`team_roles/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const deleteTeamRole = (id, token) =>
  handleApiResponse(() =>
    api.delete(`team_roles/${id}`, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const getModules = (token) =>
  handleApiResponse(() =>
    api.get("team_roles/modules", {
      headers: {
        TOKEN: token,
      },
    })
  );

export const getEmployees = (token) =>
  handleApiResponse(() =>
    api.get("employees", {
      headers: {
        TOKEN: token,
      },
    })
  );
