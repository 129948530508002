import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { addNotification } from './tooltipSlice';

import {
  getEmployees,
  getEmployee,
  addEmployee,
  updateEmployee,
  deleteEmployee,
  getAllEmployee,
} from '../api/employees';

// Thunks
export const fetchEmployees = createAsyncThunk('employees/fetchEmployees', async ({ token, queryDetails }) => {
  const response = await getEmployees(token, queryDetails);
  return response.data;
});

export const fetchEmployeesForLength = createAsyncThunk('employees/fetchEmployeesForLength', async ({ token }) => {
  const response = await getEmployees(token);
  return response.data;
});

export const fetchEmployee = createAsyncThunk('employees/fetchEmployee', async ({ id, token }) => {
  const response = await getEmployee(id, token);
  return response.data;
});

export const fetchAllEmployee = createAsyncThunk('employees/fetchAllEmployee', async ({ token }) => {
  const response = await getAllEmployee(token);
  return response.data;
});

export const addNewEmployee = createAsyncThunk(
  'employees/addEmployee',
  async ({ newEmployeeDetails, token }, { dispatch }) => {
    try {
      const response = await addEmployee(newEmployeeDetails, token);
      dispatch(
        addNotification({
          message: 'Employee successfully created',
          status: 'succeeded',
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to create employee, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateExistingEmployee = createAsyncThunk(
  'employees/updateExistingEmployee',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateEmployee(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Employee successfully updated',
          status: 'succeeded',
        }),
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to update employee, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeEmployee = createAsyncThunk('employees/removeEmployee', async ({ id, token }, { dispatch }) => {
  try {
    await deleteEmployee(id, token);
    dispatch(
      addNotification({
        message: 'Employee successfully deleted',
        status: 'succeeded',
      }),
    );
    return id;
  } catch (error) {
    dispatch(
      addNotification({
        message: `Failed to delete employee, ${error?.message}`,
        status: 'failed',
      }),
    );
    throw error;
  }
});

const initialState = {
  employees: [],
  currentEmployee: {},
  numberOfAllEmployees: 0,
  queryDetails: {},
  status: 'idle',
  error: null,
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
    clearCurrentEmployee(state) {
      state.currentEmployee = {};
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    };

    builder
      .addCase(fetchEmployees.pending, setLoading)
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        setSucceeded(state);
        state.employees = action.payload;
      })
      .addCase(fetchEmployees.rejected, setFailed)

      .addCase(fetchEmployeesForLength.fulfilled, (state, action) => {
        state.numberOfAllEmployees = action.payload.length;
      })

      .addCase(fetchEmployee.pending, setLoading)
      .addCase(fetchEmployee.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentEmployee = action.payload;
      })
      .addCase(fetchEmployee.rejected, setFailed)

      .addCase(fetchAllEmployee.pending, setLoading)
      .addCase(fetchAllEmployee.fulfilled, (state, action) => {
        setSucceeded(state);
        state.employees = action.payload;
      })
      .addCase(fetchAllEmployee.rejected, setFailed)

      .addCase(addNewEmployee.pending, setLoading)
      .addCase(addNewEmployee.fulfilled, (state, action) => {
        setSucceeded(state);
        const maxPage = Math.ceil(state.numberOfAllEmployees / state.queryDetails.perPage);
        const hasMoreEmployeesThanPerPage =
          'perPage' in state.queryDetails && state.queryDetails.perPage > state.numberOfAllEmployees;
        const isLastPageWithIncompleteEmployees =
          state.queryDetails.page === maxPage && state.numberOfAllEmployees / state.queryDetails.perPage !== maxPage;

        if (hasMoreEmployeesThanPerPage || isLastPageWithIncompleteEmployees) {
          state.employees.push(action.payload);
        }
      })
      .addCase(addNewEmployee.rejected, setFailed)

      .addCase(updateExistingEmployee.pending, setLoading)
      .addCase(updateExistingEmployee.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedDetails } = action.payload;
        const index = state.employees.findIndex((emp) => emp.id === updatedDetails.id);

        if (index !== -1) {
          state.employees[index] = updatedDetails;
        }
      })
      .addCase(updateExistingEmployee.rejected, setFailed)

      .addCase(removeEmployee.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;
        state.employees = state.employees.filter((employee) => employee.id !== id);
      })
      .addCase(removeEmployee.pending, setLoading)
      .addCase(removeEmployee.rejected, setFailed);
  },
});

export const { setQueryDetails, clearCurrentEmployee } = employeesSlice.actions;
export default employeesSlice.reducer;
