import React from 'react';
import HeaderReport from "../components/HeaderReport/header-report";
import clsx from "clsx";
import DateRange from "../components/DateRange/date-range";
import {DropdownTypes, FiltersForReports} from "../components/OrderFilters";

import styles from './tips.module.scss';
import ReportTable from "./report-table";


function Tips() {
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const downloadPdf = () => {};
    const downloadCsv = () => {};

    const fakePayments = [
        {
            created_at: new Date(),
            order: {
                offline_id: null,
                short_receipt_url: 'http://example.com',
            },
            employee: { full_name: 'John Doe' },
            card_name: 'Visa',
            Card: true,
            sub_total: 100.0,
            tips: 15.0,
            tip_setting: { name: 'Standard', rate: 10.0 },
            service_charge_amount: 5.0,
            total_discount: 2.0,
            tax_amount: 8.0,
            cash_discount_type: 'SurCharge',
            cash_discount_or_surcharge: 1.0,
            amount: 120.0,
        },
        {
            created_at: new Date(),
            order: {
                offline_id: 1,
                short_receipt_url: null,
            },
            employee: { full_name: 'Jane Doe' },
            card_name: 'MasterCard',
            Card: false,
            sub_total: 200.0,
            tips: 30.0,
            tip_setting: null,
            service_charge_amount: 10.0,
            total_discount: 5.0,
            tax_amount: 16.0,
            cash_discount_type: 'CashDiscount',
            cash_discount_or_surcharge: 2.0,
            amount: 250.0,
        }
    ];

  return (
      <div className={styles.contentWrapper}>
          <HeaderReport
              title={"Tips"}
              downloadPdf={downloadPdf}
              downloadCsv={downloadCsv}
          />
          <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
              <FiltersForReports
                  searchShow={false}
                  dropdown={[
                      DropdownTypes.employee,
                  ]}
              />
          </div>
          <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
              <DateRange
                  setEndDate={setEndDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  startDate={startDate}
              />
          </div>
          <div className="bg-white table-responsive mt-4">
              <ReportTable payments={fakePayments} />
          </div>
      </div>
   )
}

export default Tips;
