import { z } from 'zod';

export const registrationSchema = z.object({
  name: z
    .string({ required_error: 'Please enter Order type name' })
    .min(1, { message: 'Please enter Order type name' }),
  order_type: z.string({ required_error: 'Please select Order type' }).min(1, { message: 'Please select Order type' }),
  kitchen_color_code: z
    .string({ required_error: 'Please select kitchen color' })
    .min(1, { message: 'Please select kitchen color' }),
});
