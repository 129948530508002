import React from 'react';

import clsx from 'clsx';
import styles from './page-title.module.scss';

const PageTitle = ({ name, className }) => {
  return <h2 className={clsx(styles.title, className)}>{name}</h2>;
};

export default PageTitle;
