import { api, handleApiResponse } from "./base";

export const getAllItems = ({token}) =>
    handleApiResponse(() =>
        api.get(`/items`, {
            headers: {
                TOKEN: token,
            },
        }),
    );
