import React, { useState } from "react";
import HeaderReport from "../components/HeaderReport/header-report";
import clsx from "clsx";
import { DateRange } from "../components/DateRange";
import { FiltersForReports, DropdownTypes } from "../components/OrderFilters";
import ShiftReportBody from "./shift-report-body";

import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./employee-shift-report.module.scss";

function EmployeeShiftReport() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const downloadPdf = () => {};
  const downloadCsv = () => {};

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport
        title={"Employee Shift"}
        downloadPdf={downloadPdf}
        downloadCsv={downloadCsv}
      />
      <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
        <FiltersForReports
          searchShow={false}
          dropdown={[DropdownTypes.employee]}
        />
      </div>
      <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
        <DateRange
          setEndDate={setEndDate}
          endDate={endDate}
          setStartDate={setStartDate}
          startDate={startDate}
        />
      </div>
      <ShiftReportBody shiftReportData={null} />
    </div>
  );
}

export default EmployeeShiftReport;
