import {displayTwoDigit} from './helper';
export const TaxType = {
    PERCENTAGE: 'percentage',
    DOLLAR: 'dollar'
};

export const TaxesHelper = {
    getTaxType: (tax) => {
        return tax.tax_type.toUpperCase() === TaxType.PERCENTAGE ? '%' : '$';
    },

    taxTypeAmount: (amount, taxType) => {
        if (taxType.toUpperCase() === TaxType.PERCENTAGE) {
            return `${displayTwoDigit(amount)}%`;
        } else if (taxType.toUpperCase() === TaxType.DOLLAR) {
            return `$${displayTwoDigit(amount)}`;
        }
    },

    getTaxItemCount: (tax) => {
        return tax.items.length;
    }
};


