import React, {useState} from 'react';
import HeaderReport from "../components/HeaderReport/header-report";
import clsx from "clsx";
import {FiltersForReports, DropdownTypes} from "../components/OrderFilters";
import {displayTwoDigitWithDelimiter} from "../components/helper";
import {DateRange} from "../components/DateRange";
import ReportTable from "./ReportTable";

import styles from './non-cash-adjustments.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

function NonCashAdjustment() {
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);

    const downloadPdf = () => {}
    const downloadCsv = () => {}

    const totalCashDiscount = 1234.56; // Fake data
    const totalSurcharge = 789.01; // Fake data

    const payments = [
        {
            id: 1,
            created_at: '2023-10-01T12:00:00Z',
            offline_id: '12345',
            payment_type: 'Card',
            amount: 100.00,
            tips: 10.00,
            tax_amount: 5.00,
            employee: { full_name: 'John Doe' },
            cash_discount_type: 'Type A',
            cash_discount_or_surcharge: 2.00,
            terminal: { name: 'Terminal 1' },
            payable_type: 'Invoice',
            payable_id: 1,
            order: {
                customer: { full_name: 'Jane Smith' },
                order_type_name: 'Online'
            }
        },
        {
            id: 2,
            created_at: '2023-10-01T12:00:00Z',
            offline_id: '12346',
            payment_type: 'Cash',
            amount: 200.00,
            tips: 20.00,
            tax_amount: 10.00,
            employee: { full_name: 'John Doe' },
            cash_discount_type: 'Type B',
            cash_discount_or_surcharge: 3.00,
            terminal: { name: 'Terminal 2' },
            payable_type: 'Invoice',
            payable_id: 2,
            order: {
                customer: { full_name: 'Jane Smith' },
                order_type_name: 'Online'
            }
        }
    ];

    return (
        <div className={styles.contentWrapper}>
            <HeaderReport
                title={"Non-Cash Adjustments/SurCharge"}
                downloadPdf={downloadPdf}
                downloadCsv={downloadCsv}
            />
            <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
                <FiltersForReports
                    dropdown={[
                        DropdownTypes.employee,
                        DropdownTypes.payment_type,
                        DropdownTypes.card_type,
                        DropdownTypes.terminal_id,
                        DropdownTypes.order_type_id,
                        DropdownTypes.cashDiscountSurCharge
                    ]}
                    searchTitle={"Search Transactions by ID, Offline ID, Amount and last 4 digits on Payment Card ID"}
                />
            </div>
            <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
                <DateRange
                    endDate={endDate}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                />
            </div>
            <div className={clsx(styles.boxBody, styles.borderBox, "row align-items-end inquiry-form mb-4 ml-1")}>
                    <div className="col-xl-4 col-md-6">
                        <h5 className="form-group mb-2">
                            Total CashDiscount: <b>${displayTwoDigitWithDelimiter(totalCashDiscount)}</b>
                        </h5>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <h5 className="form-group mb-2">
                            Total SurCharge: <b>${displayTwoDigitWithDelimiter(totalSurcharge)}</b>
                        </h5>
                    </div>
            </div>
            <div className="bg-white table-responsive mt-4">
                <ReportTable payments={payments}/>
            </div>
        </div>
    );
}

export default NonCashAdjustment;
