import React from "react";
import { IoTrash } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";

import Switch from "../../../utilities/switch/switch";
import Checkbox from "../../../utilities/checkbox/checkbox";
import List from "../../../utilities/list/list";

import styles from "./hidden-category-list.module.scss";

const CategoriesList = ({
  categories,
  onEdit,
  onDelete,
  onToggleActive,
  onAssignItemsClick,
  selectedCategoriesIds,
  setSelectedCategoriesIds,
  isLoading,
}) => {
  const handleCheckboxChange = (id, value) => {
    setSelectedCategoriesIds((prev) => {
      if (value) {
        return [...prev, id];
      } else {
        return prev.filter((item) => item !== id);
      }
    });
  };

  const renderCheckbox = (item) => (
    <Checkbox
      item={item}
      handleOnChange={handleCheckboxChange}
      isChecked={selectedCategoriesIds.includes(item.id)}
    />
  );

  const renderSwitcher = (item) => (
    <Switch
      id={item.id}
      roleName={"User"}
      isActive={item.active}
      onSwitch={onToggleActive}
    />
  );

  const renderLink = (item) => (
    <span
      className={styles.assign_item_link}
      onClick={(e) => onAssignItemsClick(item.id)}
    >
      Assign Items
    </span>
  );

  const columnConfig = [
    {
      key: "sort",
      header: "Sort",
      render: renderCheckbox,
    },
    {
      key: "name",
      header: "Category Name",
    },
    {
      key: "active",
      header: "Status",
      render: renderSwitcher,
    },
    {
      key: "item_ids",
      header: "",
      render: renderLink,
    },
  ];

  const rowActions = [
    {
      icon: <MdModeEditOutline />,
      onClick: onEdit,
      label: "Edit category",
      className: styles.row_actions_button,
    },
    {
      icon: <IoTrash />,
      onClick: onDelete,
      label: "Delete category",
      className: styles.row_actions_button,
    },
  ];

  return (
    <List
      data={categories}
      columnConfig={columnConfig}
      rowActions={rowActions}
      isLoading={isLoading}
      className={styles.categories_list}
      rowClassName={styles.categories_row}
    />
  );
};

export default CategoriesList;
