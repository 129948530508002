import React from "react";
import ViewDate from "../components/view-date";

export default function OrderItem({order, openOrderDetails}) {

    const orderItems = (order) => order.payments.reduce((sum, payment) => {
        const paymentTotal = payment.amount + payment.tips;
        const refundTotal = payment.refunds.reduce((refundSum, refund) => refundSum + refund.amount, 0);
        return sum + paymentTotal - refundTotal;
    }, 0);

    const calcPayment = (payment) => (payment.amount + payment.tips) - payment.refunds.reduce((refundSum, refund) => refundSum + refund.amount, 0);

    const getLast4DigitOfCard = (payment) => payment.Card.last4;

    return (
        <tr key={order.id}>
            <td>
                <p>ID: {order.offline_id}</p>
            </td>
            <td>
                <ViewDate date={order.created_at} />
            </td>
            <td>
                {order.customer_name}
            </td>
            <td>
                {order.employee_name}
            </td>
            <td>{order.total_tax_amount}</td>
            <td>{order.order_status}</td>
            <td>
                <div onClick={() => openOrderDetails(order)}>
                    <span className="text-blue1 text-underline">Details</span>
                </div>
            </td>
        </tr>
    );
}
