import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getCategories,
  addCategory,
  getCategory,
  updateCategory,
  deleteCategory,
  updateCategoryOrder,
  updateCategoryStatus,
} from '../api/categories';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchCategories = createAsyncThunk('categories/fetchCategories', async (token) => {
  const response = await getCategories(token);
  return response.data;
});

export const createCategory = createAsyncThunk(
  'categories/createCategory',
  async ({ newDetails, token }, { dispatch }) => {
    try {
      const response = await addCategory(newDetails, token);
      dispatch(addNotification({ message: 'Category created successfully', status: 'succeeded' }));
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: 'Failed to create category', status: 'failed' }));
      throw error;
    }
  },
);

export const fetchCategory = createAsyncThunk('categories/fetchCategory', async ({ id, token }) => {
  const response = await getCategory(id, token);
  return response.data;
});

export const updateExistingCategory = createAsyncThunk(
  'categories/updateExistingCategory',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateCategory(id, updatedDetails, token);
      dispatch(addNotification({ message: 'Category updated successfully', status: 'succeeded' }));
      return { id, updatedDetails };
    } catch (error) {
      dispatch(addNotification({ message: 'Failed to update category', status: 'failed' }));
      throw error;
    }
  },
);

export const removeCategory = createAsyncThunk('categories/removeCategory', async ({ id, token }, { dispatch }) => {
  try {
    await deleteCategory(id, token);
    dispatch(addNotification({ message: 'Category deleted successfully', status: 'succeeded' }));
    return id;
  } catch (error) {
    dispatch(addNotification({ message: 'Failed to delete category', status: 'failed' }));
    throw error;
  }
});

export const updateExistingCategoryOrder = createAsyncThunk(
  'categories/updateExistingCategoryOrder',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      const response = await updateCategoryOrder(id, updatedDetails, token);
      dispatch(fetchCategories(token));
      dispatch(addNotification({ message: 'Category order was changed successfully', status: 'succeeded' }));
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({ message: `Failed to change category order: ${error?.message || error}`, status: 'failed' }),
      );
      throw error;
    }
  },
);

export const updateExistingCategoryStatus = createAsyncThunk(
  'categories/updateExistingCategoryStatus',
  async ({ id, is_active, token }, { dispatch }) => {
    try {
      await updateCategoryStatus(id, is_active, token);
      dispatch(addNotification({ message: 'Category status was changed successfully', status: 'succeeded' }));
      return { id, is_active };
    } catch (error) {
      dispatch(addNotification({ message: 'Failed to change category status', status: 'failed' }));
      throw error;
    }
  },
);

const initialState = {
  categories: [],
  category: {},
  status: 'idle',
  error: null,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    };

    builder
      .addCase(fetchCategories.pending, setLoading)
      .addCase(fetchCategories.fulfilled, (state, action) => {
        setSucceeded(state);
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, setFailed)

      .addCase(createCategory.pending, setLoading)
      .addCase(createCategory.fulfilled, (state, action) => {
        setSucceeded(state);
        state.categories.push(action.payload);
      })
      .addCase(createCategory.rejected, setFailed)

      .addCase(fetchCategory.pending, setLoading)
      .addCase(fetchCategory.fulfilled, (state, action) => {
        setSucceeded(state);
        state.category = action.payload;
      })
      .addCase(fetchCategory.rejected, setFailed)

      .addCase(updateExistingCategory.pending, setLoading)
      .addCase(updateExistingCategory.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, updatedDetails } = action.payload;
        const existingCategory = state.categories.find((item) => item.id === id);

        if (existingCategory) {
          Object.assign(existingCategory, updatedDetails);
        }
      })
      .addCase(updateExistingCategory.rejected, setFailed)

      .addCase(removeCategory.pending, setLoading)
      .addCase(removeCategory.fulfilled, (state, action) => {
        setSucceeded(state);
        state.categories = state.categories.filter((item) => item.id !== action.payload);
      })
      .addCase(removeCategory.rejected, setFailed)

      .addCase(updateExistingCategoryOrder.pending, setLoading)
      .addCase(updateExistingCategoryOrder.fulfilled, setSucceeded)
      .addCase(updateExistingCategoryOrder.rejected, setFailed)

      .addCase(updateExistingCategoryStatus.pending, setLoading)
      .addCase(updateExistingCategoryStatus.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, is_active } = action.payload;
        const existingCategory = state.categories.find((item) => item.id === id);
        if (existingCategory) {
          existingCategory.active = is_active;
        }
      })
      .addCase(updateExistingCategoryStatus.rejected, setFailed);
  },
});

export default categoriesSlice.reducer;
