import { useState, useMemo } from 'react';

const usePagination = (items, initialPage = 1, initialPerPage = 5) => {
  const [page, setPage] = useState(initialPage);
  const [perPage, setPerPage] = useState(initialPerPage);
  const startIndex = (page - 1) * perPage;
  const endIndex = startIndex + perPage;

  const currentPageItems = useMemo(() => items.slice(startIndex, endIndex), [endIndex, items, startIndex]);

  const maxPage = Math.ceil(items.length / perPage);

  const handleSelectPageCount = (value) => {
    setPerPage(value);
    setPage(1);
  };

  const handleClickPrev = () => {
    setPage((prev) => Math.max(prev - 1, 1));
  };

  const handleClickNext = () => {
    setPage((prev) => Math.min(prev + 1, maxPage));
  };

  return {
    currentPageItems,
    page,
    perPage,
    maxPage,
    isPrevActive: page > 1,
    isNextActive: page < maxPage,
    handleSelectPageCount,
    handleClickPrev,
    handleClickNext,
  };
};

export default usePagination;
