import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Button from '../../../../utilities/button/button';
import {FaRegCalendarAlt} from "react-icons/fa"
import "./../typographyReport.scss";
import "./../commonReports.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from "./DateRange.module.scss";

import setting from "../../../../../assets/svg/setting.svg";

const DateRange = ({ startDate, endDate, onChange, showPreset=false }) => {
    const navigate = useNavigate();

    const [localStartDate, setLocalStartDate] = useState(startDate);
    const [localEndDate, setLocalEndDate] = useState(endDate);

    const handleOpenReportSetting = () => {
        navigate('/report_setting_account_settings');
    };

    const handleUpdateDate = () => {
        onChange({startDate: localStartDate, endDate: localEndDate});
    };
    const presets = ["Today", "Yesterday", "This Week", "Last Week", "Last Month", "Last Year", "Custom Date Range"];
    const [preset, setPreset] = useState("Custom Date Range");
    const handlePresetChange = (event) => {
        const selectedPreset = event.target.value;
        setPreset(selectedPreset);

        const now = new Date();
        let newStartDate = new Date();
        let newEndDate = new Date();

        switch (selectedPreset) {
            case "Today":
                newStartDate = new Date(now.setHours(0, 0, 0, 0));
                newEndDate = new Date(now.setHours(23, 59, 59, 999));
                break;
            case "Yesterday":
                newStartDate = new Date(now.setDate(now.getDate() - 1));
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(now.setHours(23, 59, 59, 999));
                break;
            case "This Week":
                newStartDate = new Date(now.setDate(now.getDate() - now.getDay()));
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(now.setDate(now.getDate() + (6 - now.getDay())));
                newEndDate.setHours(23, 59, 59, 999);
                break;
            case "Last Week":
                newStartDate = new Date(now.setDate(now.getDate() - now.getDay() - 7));
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(now.setDate(now.getDate() + (6 - now.getDay())));
                newEndDate.setHours(23, 59, 59, 999);
                break;
            case "Last Month":
                newStartDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                newEndDate = new Date(now.getFullYear(), now.getMonth(), 0);
                break;
            case "Last Year":
                newStartDate = new Date(now.getFullYear() - 1, 0, 1);
                newEndDate = new Date(now.getFullYear() - 1, 11, 31);
                break;
            default:
                break;
        }

        setLocalStartDate(newStartDate);
        setLocalEndDate(newEndDate);
        onChange({ startDate: newStartDate, endDate: newEndDate });
    };
    return (
        <div className="row">
            <div className="col-md-12 mb-4">
                <div className="d-sm-flex align-items-center justify-content-between pb-2">
                    <h4 className="fw-500 fs-18 pb-2 mt-2 pl-0 ml-0">Date Range</h4>
                    <a onClick={handleOpenReportSetting}>
                        <div className={styles.actionArea}>
                            <div className="small-circle-icon mr-2">
                                <img src={setting} width={34} height={34} alt=""/>
                            </div>
                            <span className="fs-16 fw-500 text-blue1">Change default reporting time</span>
                        </div>
                    </a>
                </div>
                <div className={styles.tableTopBorder}></div>
            </div>
            {showPreset && (
                <div className="col-md-12 mb-4">
                    <div className="d-sm-flex align-items-center justify-content-between pb-2">
                        <div className="btn-group" role="group">
                            {presets.map((presetOption) => (
                                <button
                                    key={presetOption}
                                    type="button"
                                    className={`btn btn-link ${preset === presetOption ? 'active' : ''}`}
                                    onClick={() => handlePresetChange({target: {value: presetOption}})}
                                >
                                    {presetOption}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <div className="col-md-12">
                <div className="row align-items-end">
                    <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-2">
                            <label className="form-label text-capitalize">From Date</label>
                            <div className="input-group align-items-center">
                                <FaRegCalendarAlt size={24} className={"mr-2"}/>
                                <DatePicker
                                    selected={localStartDate}
                                    onChange={date => setLocalStartDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="form-group date-time-input mb-2">
                            <label className="orm-label text-capitalize">To Date</label>
                            <div className="input-group align-items-center">
                                <FaRegCalendarAlt size={24} className={"mr-2"}/>
                                <DatePicker
                                    selected={localEndDate}
                                    onChange={date => setLocalEndDate(date)}
                                    className="form-control datetimepicker"
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="form-group mb-2 text-xl-left">
                            <Button title={'Go Reports'} onClick={handleUpdateDate}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DateRange;
