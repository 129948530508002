import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

const AppContext = createContext();

export const ContextProvider = ({ children }) => {
  const token = useSelector((state) => state.auth.loginState.auth_token);
  const locationId = useSelector((state) => state.auth.loginState.location_id);

  return <AppContext.Provider value={{ token, locationId }}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
