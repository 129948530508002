import { z } from 'zod';

export const registrationSchema = z.object({
  name: z
    .string({ required_error: 'Please enter loyalty program name' })
    .min(1, { message: 'Please enter loyalty program name' })
    .max(60, { message: 'Please enter 60 characters or fewer' })
    .regex(/^[-a-zA-Z0-9-()]+(\s[-a-zA-Z0-9-()]+)*$/, { message: 'Please enter valid value' }),
  reward_point: z
    .number({ required_error: 'Please enter Loyalty Target', invalid_type_error: 'Only digits are allowed.' })
    .min(1, { message: 'Loyalty Target must be more than 0' })
    .refine((num) => num.toString().length <= 5, {
      message: 'Please enter 5 characters or fewer',
    }),
  amount: z
    .number()
    .min(0.01, { message: 'The value must be more than 0' })
    .max(100, { message: 'Percentage must not be more than 100' })
    .refine((num) => num.toString().length <= 7, {
      message: 'Please enter 7 characters or fewer',
    })
    .optional(),
});
