import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getCustomerReceiptSettings,
  updateCustomerReceiptSettings as updateReceiptSettings,
} from '../api/customer_receipt_settings';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchCustomerReceiptSettings = createAsyncThunk(
  'customerReceiptSettings/fetchCustomerReceiptSettings',
  async ({ token }) => {
    const response = await getCustomerReceiptSettings(token);
    return response.data;
  },
);

export const updateCustomerReceiptSettings = createAsyncThunk(
  'customerReceiptSettings/updateCustomerReceiptSettings',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateReceiptSettings(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Customer Receipt Settings successfully saved',
          status: 'succeeded',
        }),
      );
      return { updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to save Customer Receipt Settings, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

const initialState = {
  initialCustomerReceiptSettings: {},
  customerReceiptSettings: {},
  status: 'idle',
  error: null,
};

const customerReceiptSettingsSlice = createSlice({
  name: 'customerReceiptSettings',
  initialState,
  reducers: {
    updateState(state, action) {
      state.customerReceiptSettings = {
        ...state.customerReceiptSettings,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action?.error.message;
    };

    builder
      .addCase(fetchCustomerReceiptSettings.pending, setLoading)
      .addCase(fetchCustomerReceiptSettings.fulfilled, (state, action) => {
        setSucceeded(state);
        state.initialCustomerReceiptSettings = action.payload;
        state.customerReceiptSettings = action.payload;
      })
      .addCase(fetchCustomerReceiptSettings.rejected, setFailed)

      .addCase(updateCustomerReceiptSettings.pending, setLoading)
      .addCase(updateCustomerReceiptSettings.fulfilled, (state, action) => {
        setSucceeded(state);
        state.initialCustomerReceiptSettings = { ...state.customerReceiptSettings, ...action.payload };
        state.customerReceiptSettings = state.initialCustomerReceiptSettings;
      })
      .addCase(updateCustomerReceiptSettings.rejected, setFailed);
  },
});

export const { updateState } = customerReceiptSettingsSlice.actions;
export default customerReceiptSettingsSlice.reducer;
