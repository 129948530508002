import React from 'react';
import OrderSalesDetails from './reports/OrderSalesDetails';
import SalesSummary from "./reports/SalesSummary";
import PaymentDetails from "./reports/PaymentDetails";
import TipDetails from "./reports/TipDetails";
import TaxDetails from "./reports/TaxDetails";
import RefundVoids from "./reports/RefundVoids";
import TotalCreditPayment from "./reports/TotalCreditPayment";
import TotalCashPayments from "./reports/TotalCashPayments";
import TotalPayments from "./reports/TotalPayments";
import ServiceChargeDetails from "./reports/ServiceChargeDetails";
import CreditTipAudit from "./reports/CreditTipAudit";
import ClockInClockOut from "./reports/ClockInClockOut";
import CashLogDetails from "./reports/CashLogDetails";
import OtherDetails from "./reports/OtherDetails";

import 'bootstrap/dist/css/bootstrap.min.css';

const ShiftReportBody = ({ shiftReportData }) => {
    return (
        <div>
            <OrderSalesDetails shiftReportData={shiftReportData} />
            <SalesSummary shiftReportData={shiftReportData} />
            <PaymentDetails shiftReportData={shiftReportData} />
            <TipDetails shiftReportData={shiftReportData} />
            <TaxDetails shiftReportData={shiftReportData} />
            <RefundVoids shiftReportData={shiftReportData} />
            <TotalCreditPayment shiftReportData={shiftReportData} />
            <TotalCashPayments shiftReportData={shiftReportData} />
            <TotalPayments shiftReportData={shiftReportData} />
            <ServiceChargeDetails shiftReportData={shiftReportData} />
            <CreditTipAudit shiftReportData={shiftReportData} />
            <ClockInClockOut shiftReportData={shiftReportData} />
            <CashLogDetails shiftReportData={shiftReportData} />
            <OtherDetails shiftReportData={shiftReportData} />
        </div>
    );
};

export default ShiftReportBody;
