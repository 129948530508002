import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getCancelReasons,
  getCancelReason,
  addCancelReason,
  updateCancelReason,
  deleteCancelReason,
  reorderCancelReason,
} from '../api/cancel_order_reasons';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchCancelOrdersReasons = createAsyncThunk(
  'cancelOrderReasons/fetchCancelOrdersReasons',
  async ({ token }) => {
    const response = await getCancelReasons(token);
    return response.data;
  },
);

export const fetchCancelOrdersReason = createAsyncThunk(
  'cancelOrderReasons/fetchCancelOrdersReason',
  async ({ id, token }) => {
    const response = await getCancelReason(id, token);
    return response.data;
  },
);

export const addCancelOrdersReason = createAsyncThunk(
  'cancelOrderReasons/addCancelOrdersReason',
  async ({ newCancelReasonDetails, token }, { dispatch }) => {
    try {
      const response = await addCancelReason(newCancelReasonDetails, token);
      dispatch(
        addNotification({
          message: 'Cancel order reason successfully created',
          status: 'succeeded',
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to create cancel order reason, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateExistingCancelOrdersReason = createAsyncThunk(
  'cancelOrderReasons/updateExistingCancelOrdersReason',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateCancelReason(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Cancel order reason successfully updated',
          status: 'succeeded',
        }),
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to update cancel order reason, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeCancelOrdersReason = createAsyncThunk(
  'cancelOrderReasons/removeCancelOrdersReason',
  async ({ id, token }, { dispatch }) => {
    try {
      await deleteCancelReason(id, token);
      dispatch(
        addNotification({
          message: 'Cancel order reason successfully deleted',
          status: 'succeeded',
        }),
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to delete cancel order reason: ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const reorderCancelOrdersReason = createAsyncThunk(
  'cancelOrderReasons/reorderCancelOrdersReason',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await reorderCancelReason(id, updatedDetails, token);
      await dispatch(fetchCancelOrdersReasons({ token }));
      dispatch(addNotification({ message: 'Cancel order reason was successfully reordered', status: 'succeeded' }));
      return updatedDetails;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to reorder cancel order reason: ${error?.message || error}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

const initialState = {
  cancelOrderReasons: [],
  currentcCancelOrderReason: {},
  queryDetails: {},
  status: 'idle',
  error: null,
};

const cancelOrderReasonsSlice = createSlice({
  name: 'cancelOrderReasons',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action?.error.message;
    };

    builder
      .addCase(fetchCancelOrdersReasons.pending, setLoading)
      .addCase(fetchCancelOrdersReasons.fulfilled, (state, action) => {
        setSucceeded(state);
        state.cancelOrderReasons = action.payload;
      })
      .addCase(fetchCancelOrdersReasons.rejected, setFailed)

      .addCase(fetchCancelOrdersReason.pending, setLoading)
      .addCase(fetchCancelOrdersReason.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentcCancelOrderReason = action.payload;
      })
      .addCase(fetchCancelOrdersReason.rejected, setFailed)

      .addCase(addCancelOrdersReason.pending, setLoading)
      .addCase(addCancelOrdersReason.fulfilled, (state, action) => {
        setSucceeded(state);
        state.cancelOrderReasons.push(action.payload);
      })
      .addCase(addCancelOrdersReason.rejected, setFailed)

      .addCase(updateExistingCancelOrdersReason.pending, setLoading)
      .addCase(updateExistingCancelOrdersReason.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedDetails } = action.payload;
        const index = state.cancelOrderReasons.findIndex((reason) => reason.id === updatedDetails.id);

        if (index !== -1) {
          state.cancelOrderReasons[index] = updatedDetails;
        }
      })
      .addCase(updateExistingCancelOrdersReason.rejected, setFailed)

      .addCase(removeCancelOrdersReason.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;
        state.cancelOrderReasons = state.cancelOrderReasons.filter((reason) => reason.id !== id);
      })
      .addCase(removeCancelOrdersReason.pending, setLoading)
      .addCase(removeCancelOrdersReason.rejected, setFailed)

      .addCase(reorderCancelOrdersReason.pending, setLoading)
      .addCase(reorderCancelOrdersReason.fulfilled, setSucceeded)
      .addCase(reorderCancelOrdersReason.rejected, setFailed);
  },
});

export const { setQueryDetails } = cancelOrderReasonsSlice.actions;
export default cancelOrderReasonsSlice.reducer;
