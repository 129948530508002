import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchLocations,
  updateCustomerDisplaySettings,
} from "../../../../redux/locationSlice";
import { useAppContext } from "../../../../context/context";

import PageTitle from "../../../utilities/page-title/page-title";
import Checkbox from "../../../utilities/checkbox/checkbox";
import Button from "../../../utilities/button/button";

import styles from './customer-display-settings.module.scss';

function CustomerDisplaySettings() {
  // Global State
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();
  const locations = useSelector((state) => state.locations.locations);

  // Local State
  const [currentLocation, setCurrentLocation] = useState({});
  const [isSignatureRequired, setIsSignatureRequired] = useState(false);
  const [showCashBifurcation, setShowCashBifurcation] = useState(false);
  const [showTipScreenBeforePayment, setShowTipScreenBeforePayment] = useState(false);
  const [flag, setFlag] = useState(false);

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchLocations(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (locations) {
      const location = locations.find((location) => location.id === locationId);
      if (location) setCurrentLocation(location);
    }
  }, [locations, locationId]);

  useEffect(() => {
    if (currentLocation) {
      setIsSignatureRequired(currentLocation.customer_sign_required_on_cd || false);
      setShowCashBifurcation(currentLocation.show_cash_credit_price_on_cd || false);
      setShowTipScreenBeforePayment(currentLocation.show_tip_screen_before_payment || false);
    }
  }, [currentLocation]);

// Handlers
const handleCheckboxChange = (setter) => {
  setter((prev) => !prev);
  setFlag(true);
};

const handleSave = () => {
  if (flag) {
    dispatch(
      updateCustomerDisplaySettings({
        isCustomerSignatureRequired: isSignatureRequired,
        isShowCashBifurcation: showCashBifurcation,
        isShowTipScreenBeforePayment: showTipScreenBeforePayment,
        token,
        locationId,
      })
    );
    setFlag(false);
  }
};

  return (
    <div className={styles.menu_settings}>
      <PageTitle name={"Customer Display Settings"} />
      <div className={styles.menu_item}>
        <Checkbox
          item={{ name: "Customer signature required on Customer Display?" }}
          handleOnChange={() => handleCheckboxChange(setIsSignatureRequired)}
          isChecked={isSignatureRequired}
        />
        <span
          className={styles.menu_item_name}
          onClick={() => handleCheckboxChange(setIsSignatureRequired)}
        >
          Customer signature required on Customer Display?
        </span>
      </div>
      <div className={styles.menu_item}>
        <Checkbox
          item={{ name: "Show cash/credit bifurcation on Customer Display?" }}
          handleOnChange={() => handleCheckboxChange(setShowCashBifurcation)}
          isChecked={showCashBifurcation}
        />
        <span
          className={styles.menu_item_name}
          onClick={() => handleCheckboxChange(setShowCashBifurcation)}
        >
          Show cash/credit bifurcation on Customer Display?
        </span>
      </div>
      <div className={styles.menu_item}>
        <Checkbox
          item={{ name: "Show tip screen before payment?" }}
          handleOnChange={() => handleCheckboxChange(setShowTipScreenBeforePayment)}
          isChecked={showTipScreenBeforePayment}
        />
        <span
          className={styles.menu_item_name}
          onClick={() => handleCheckboxChange(setShowTipScreenBeforePayment)}
        >
          Show tip screen before payment?
        </span>
      </div>
      <Button
        className={styles.save_button}
        title={"Save"}
        onClick={handleSave}
      />
    </div>
  )
}

export default CustomerDisplaySettings;