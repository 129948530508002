import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchOptions, removeOption, setQueryDetails } from '../../../../redux/optionSetsSlice';
import { showModal, hideModal } from '../../../../redux/modalSlice';

import { useAppContext } from '../../../../context/context';
import usePagination from '../../business/order-types/usePagination';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Pagination from '../../../../components/utilities/pagination/pagination';
import Button from '../../../../components/utilities/button/button';
import Search from '../../../../components/utilities/search/search';

import OptionSetsList from './option-sets-list';

import styles from './options.module.scss';

const PAGINATION_OPTIONS = [
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];

const PAGINATION = {
  page: 1,
  perPage: 5,
};

function Options() {
  const navigate = useNavigate();

  const optionSetsTypesStatus = useSelector((state) => state.optionSets.status);
  const optionSets = useSelector((state) => state.optionSets.optionSets);

  const dispatch = useDispatch();
  const { token } = useAppContext();

  const {
    currentPageItems,
    page,
    perPage,
    isPrevActive,
    isNextActive,
    handleSelectPageCount,
    handleClickPrev,
    handleClickNext,
  } = usePagination(optionSets, PAGINATION.page, PAGINATION.perPage);

  useEffect(() => {
    if (token) {
      dispatch(fetchOptions({ token }));
    }
  }, [dispatch, token]);

  const handleSetResult = (result) => {
    dispatch(setQueryDetails({ search: result }));
  };

  const handleSearchClean = () => {
    dispatch(setQueryDetails({ search: '' }));
  };

  const handleAddNewOptionSet = () => {
    navigate(`/menu/options/new`);
  };

  const handleEditOptionSet = (id) => {
    navigate(`/menu/options/${id}/edit`);
  };

  const handleDeleteOptionSet = (id) => {
    dispatch(
      showModal({
        modalId: 'modal-delete-option',
        data: {
          type: 'confirmation',
          title: 'Delete Confirmation',
          message: 'Are you sure you want to delete this option',
          actions: [
            {
              title: 'Delete',
              onAction: () => {
                dispatch(removeOption({ id, token }));
                dispatch(hideModal('modal-delete-option'));
              },
            },
            {
              title: 'Cancel',
              button_variant: 'grey',
              onAction: () => dispatch(hideModal('modal-delete-option')),
            },
          ],
        },
      }),
    );
  };
  return (
    <>
      <PageTitle name={'Options'} />
      <div className={styles.list_controller}>
        <div className={styles.filter_container}>
          <div className={styles.search}>
            <Search
              placeholder="Search Option by Set Name"
              onSearchResults={handleSetResult}
              onClear={handleSearchClean}
              autoFilter={false}
            />
          </div>
        </div>
        <div>
          <Button title={'Add New Option'} onClick={handleAddNewOptionSet} />
        </div>
      </div>
      <OptionSetsList
        options={currentPageItems}
        onEdit={handleEditOptionSet}
        onDelete={handleDeleteOptionSet}
        isLoading={optionSetsTypesStatus === 'loading'}
      />
      <Pagination
        pageSizeOptions={PAGINATION_OPTIONS}
        numberOfItems={optionSets.length}
        currentPage={page}
        currentPageSize={perPage}
        onPageSizeChange={handleSelectPageCount}
        inputPaginationControl={{
          isActive: true,
          isPrevActive,
          isNextActive,
        }}
        onPrev={handleClickPrev}
        onNext={handleClickNext}
      />
    </>
  );
}

export default Options;
