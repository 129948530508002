import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchPrinters, removeExistingPrinter, updateExistingPrinterStatus } from '../../../../redux/printersSlice';
import { showModal, hideModal } from '../../../../redux/modalSlice';

import { useAppContext } from '../../../../context/context';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import BackdropLoading from '../../../../components/utilities/backdrop-loading/backdrop-loading';
import Button from '../../../../components/utilities/button/button';

import PrintersList from './printer-list/printers-list';

import styles from './printers.module.scss';

const Printers = () => {
  const printersStatus = useSelector((state) => state.printers.status);
  const printers = useSelector((state) => state.printers.printers);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useAppContext();

  useEffect(() => {
    if (token) {
      dispatch(fetchPrinters({ token }));
    }
  }, [dispatch, token]);

  const handleAddNewPrinter = () => {
    navigate(`/business/printers/new`);
  };

  const handleconfirmAndDeletePrinter = useCallback(
    (id, type) => {
      dispatch(
        showModal({
          modalId: 'modal-delete-printer',
          data: {
            type: 'confirmation',
            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete this printer',
            actions: [
              {
                title: 'Delete',
                onAction: () => {
                  dispatch(removeExistingPrinter({ id, deleteDetails: { change_receipt_type: type }, token }));
                  dispatch(hideModal('modal-delete-printer'));
                },
              },
              { title: 'Cancel', button_variant: 'grey', onAction: () => dispatch(hideModal('modal-delete-printer')) },
            ],
          },
        }),
      );
    },
    [dispatch, token],
  );

  const handleTogglePrinterActive = (id, active, status) => {
    dispatch(updateExistingPrinterStatus({ id, updatedDetails: { id, terminal_id: '', status: active }, token }));
  };

  const handleEditPrinter = (id, printerCategory) => {
    navigate(`/business/printers/${id}/edit?category=${printerCategory}`);
  };

  return (
    <div className={styles.main_container}>
      {printersStatus === 'loading' && <BackdropLoading />}
      <PageTitle name="Printers" />
      <div>
        <Button title={'Add New Printer'} onClick={handleAddNewPrinter} />
      </div>
      <div className={styles.section_title}>Customer Receipt Printer</div>
      <PrintersList
        category="customer_receipt_printers"
        printers={printers.customer_receipt_printers}
        onDelete={(id) => handleconfirmAndDeletePrinter(id, 'Customer')}
        onEdit={handleEditPrinter}
        onToggleActive={(id, active) => handleTogglePrinterActive(id, active, 'customer')}
      />
      <div className={styles.section_title}>Kitchen Receipt Printer</div>
      <PrintersList
        category="kitchen_receipt_printers"
        printers={printers.kitchen_receipt_printers}
        onDelete={(id) => handleconfirmAndDeletePrinter(id, 'Kitchen')}
        onEdit={handleEditPrinter}
        onToggleActive={(id, active) => handleTogglePrinterActive(id, active, 'kitchen')}
      />
    </div>
  );
};

export default Printers;
