import React, { useState, useEffect } from 'react';

import { convertValueByType } from '../../../../utilities/utils';
import Input from '../../../../utilities/input/input';
import useDebounce from '../../../../utilities/useDebounce';

import styles from './modifier-form.module.scss';

const ModifierForm = ({ data, onChange }) => {
  const [modifier, setModifier] = useState(data ?? {});

  const debouncedAddress = useDebounce(modifier, 500);

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setModifier((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  useEffect(() => {
    onChange(modifier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddress]);

  return (
    <div className={styles.section}>
      <Input
        type="text"
        name="name"
        placeholder="New Modifier"
        value={modifier?.name}
        onChange={handleInputChange}
        inputProps={{ style: { border: 'none' } }}
        style={{ width: '100%' }}
      />
      <Input
        type="text"
        name="price"
        placeholder="$0.00"
        value={modifier?.price}
        onChange={handleInputChange}
        inputProps={{ style: { border: 'none' } }}
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default ModifierForm;
