import React from 'react';

import List from '../../../utilities/list/list';
import Switch from '../../../utilities/switch/switch';

import { IoTrash } from 'react-icons/io5';
import styles from './tips-list.module.scss';

const TipsList = ({ items, onEdit, onDelete, onToggleActive, onDrag, isLoading }) => {
  const renderInputName = (item) => (
    <input
      type="text"
      id={item.id}
      value={item?.name}
      placeholder="Add Email Address"
      onChange={(e) => onEdit(item.id, 'name', e.target.value)}
      className={styles.tip_input}
    />
  );

  const renderInputRate = (item) => (
    <input
      type="number"
      id={item.id}
      value={item?.rate}
      placeholder="Add Email Address"
      onChange={(e) => onEdit(item.id, 'rate', Number(e.target.value))}
      className={styles.rate_input}
    />
  );

  const renderSwitcher = (item) => (
    <Switch id={item.id} roleName={'User'} isActive={item?.is_active || false} onSwitch={onToggleActive} />
  );

  const columnConfig = [
    {
      key: 'name',
      header: 'Tip Name',
      render: renderInputName,
    },
    {
      key: 'rate',
      header: 'Tip %',
      render: renderInputRate,
    },
    {
      key: 'is_active',
      header: 'Status',
      render: renderSwitcher,
    },
  ];

  const rowActions = [
    {
      icon: <IoTrash />,
      onClick: onDelete,
      label: 'Delete category',
      className: styles.row_actions_button,
    },
  ];

  return (
    <List
      isDragable={true}
      data={items}
      columnConfig={columnConfig}
      rowActions={rowActions}
      isLoading={isLoading}
      onDragged={onDrag}
    />
  );
};

export default TipsList;
