import React from 'react';
import styles from './privacy-policy.module.scss';

function PrivacyPolicy() {
  return (
    <div className={styles.privacyPolicy}>
      <h1 className={styles.title} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Privacy Policy</h1>
      
      <section className={styles.section}>
        <h2 className={styles.subTitle} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Introduction</h2>
        <p style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
          Welcome to CRUNCH POC. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. Please read this policy carefully to understand our views and practices regarding your personal data.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Information We Collect</h2>
        <p style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
          We may collect information about you in a variety of ways. The information we may collect on the site includes:
        </p>
        <ul className={styles.list}>
          <li style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Personal Data: Personally identifiable information, such as your name, shipping address, email address, and telephone number.</li>
          <li style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Derivative Data: Information our servers automatically collect when you access the site, such as your IP address, browser type, and operating system.</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>How We Use Your Information</h2>
        <p style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
          We may use the information we collect from you for various purposes, including:
        </p>
        <ul className={styles.list}>
          <li style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>To provide and maintain our services.</li>
          <li style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>To notify you about changes to our services.</li>
          <li style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>To allow you to participate in interactive features of our service when you choose to do so.</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Sharing Your Information</h2>
        <p style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
          We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
        </p>
        <ul className={styles.list}>
          <li style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>By Law or to Protect Rights: If we believe the release of information about you is necessary to respond to legal processes, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others.</li>
          <li style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Your Rights</h2>
        <p style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
          Depending on your location, you may have certain rights regarding your personal data. These may include the right to access, correct, or delete your personal data, as well as the right to object to or restrict certain processing activities.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Contact Us</h2>
        <p style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
          If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:privacy@crunchpoc.com" className={styles.link}>privacy@crunchpoc.com</a>.
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
