import React from "react";
import ReactTooltip from "react-tooltip";
import {displayTwoDigitWithDelimiter} from "../components/helper";
import icInfo from "./../../../../assets/svg/ic_info.svg";

export default function ReportTable({records}) {
    return (
        <div className="table-striped-main bg-white table-responsive">
            <table className="table table-striped table-striped-reverse">
                <tbody>
                <tr>
                    <td className="text-left fs-14 fw-600">Gross Sales
                        <img src={icInfo} className="ml-1" alt=""
                             data-tip="Total amount of item sales, including extras such as modifiers. This is the gross subtotal before discounts and refunds. Does not include tips, taxes, service charges."/>
                        <ReactTooltip />
                    </td>
                    <td className="text-right fs-14 fw-600">${displayTwoDigitWithDelimiter(records.gross_sale)}</td>
                </tr>
                <tr>
                    <td className="text-left fs-14">Discounts
                        <img src={icInfo} className="ml-1" alt=""
                             data-tip="Total amount of discounts applied to orders and/or individual items. Does not include refunded discounts."/>
                        <ReactTooltip />
                    </td>
                    <td className="text-right text-red fs-14">-${displayTwoDigitWithDelimiter(records.discount)}</td>
                </tr>
                <tr>
                    <td className="text-left fs-14">Refunds
                        <img src={icInfo} className="ml-1" alt=""
                             data-tip="Total amount of refunds, including manual refunds, applied against orders and/or individual items. Does not include voided payments."/>
                        <ReactTooltip/>
                    </td>
                    <td className="text-right text-red fs-14">${displayTwoDigitWithDelimiter(records.refund)}</td>
                </tr>
                <tr>
                    <td className="text-left fs-14">Wastage</td>
                    <td className="text-right text-red fs-14">-${displayTwoDigitWithDelimiter(records.wastage)}</td>
                </tr>
                <tr>
                    <td className="text-left fs-14 fw-600">Net Sales
                        <img src={icInfo} className="ml-1" alt=""
                             data-tip="Amount of item sales remaining after all discounts, refunds and loyalty rewards have been deducted. Does not include tips, taxes, service charges."/>
                        <ReactTooltip/>
                    </td>
                    <td className="text-right fs-14 fw-600">${displayTwoDigitWithDelimiter(records.net_sales)}</td>
                </tr>
                <tr>
                    <td className="text-left fs-14">Taxes & Fees
                        <img src={icInfo} className="ml-1" alt=""
                             data-tip="Total amount of taxes and flat fees collected on all items revenue."/>
                        <ReactTooltip/>
                    </td>
                    <td className="text-right fs-14">${displayTwoDigitWithDelimiter(records.total_tax)}</td>
                </tr>
                <tr>
                    <td className="text-left fs-14">Refunded Tax & Fees
                        <img src={icInfo} className="ml-1" alt="" data-tip="Total Tax & Fees Refunded."/>
                        <ReactTooltip />
                    </td>
                    <td className="text-right text-red fs-14">${displayTwoDigitWithDelimiter(records.tax_refunded)}</td>
                </tr>
                <tr>
                    <td className="text-left fs-14 fw-600">Tips
                        <img src={icInfo} className="ml-1" alt=""
                             data-tip="Total amount of tips collected. Does not include service charges or other fees. Also referred to as 'Net Tips'."/>
                        <ReactTooltip />
                    </td>
                    <td className="text-right fs-14 fw-600">${displayTwoDigitWithDelimiter(records.total_tips)}</td>
                </tr>
                <tr>
                    <td className="text-left fs-14">Tips Refunded
                        <img src={icInfo} className="ml-1" alt="" data-tip="Total tips Refunded."/>
                        <ReactTooltip />
                    </td>
                    <td className="text-right text-red fs-14">${displayTwoDigitWithDelimiter(records.tips_refund)}</td>
                </tr>
                <tr>
                    <td className="text-left fs-14">Service Charges
                        <img src={icInfo} className="ml-1" alt=""
                             data-tip="Total amount of service charges. Does not include tips."/>
                        <ReactTooltip />
                    </td>
                    <td className="text-right fs-14">${displayTwoDigitWithDelimiter(records.total_service_charge)}</td>
                </tr>
                <tr>
                    <td className="text-left fs-14">Service Charges Refunded
                        <img src={icInfo} className="ml-1" alt=""
                             data-tip="Total amount of service charges that were refunded."/>
                        <ReactTooltip />
                    </td>
                    <td className="text-right text-red fs-14">${displayTwoDigitWithDelimiter(records.service_charge_refunded)}</td>
                </tr>
                <tr>
                    <td className="text-left fs-14">Loyalty Rewards</td>
                    <td className="text-right text-red fs-14">-${displayTwoDigitWithDelimiter(records.loyalty_rewards)}</td>
                </tr>
                {records.total_cash_discount > 0.0 && (
                    <tr>
                        <td className="text-left fs-14">Non-Cash Adjustments</td>
                        <td className="text-right text-red fs-14">-${displayTwoDigitWithDelimiter(records.total_cash_discount)}</td>
                    </tr>
                )}
                {records.total_surcharge > 0.0 && (
                    <tr>
                        <td className="text-left fs-14">Sur Charge</td>
                        <td className="text-right fs-14">${displayTwoDigitWithDelimiter(records.total_surcharge)}</td>
                    </tr>
                )}
                {records.convenience_fee > 0.0 && (
                    <tr>
                        <td className="text-left fs-14 fw-400">Convenience Fee</td>
                        <td className="text-right fs-14 fw-400">${displayTwoDigitWithDelimiter(records.convenience_fee)}</td>
                    </tr>
                )}
                {records.delivery_fee > 0.0 && (
                    <tr>
                        <td className="text-left fs-14 fw-400">Delivery Fee</td>
                        <td className="text-right fs-14 fw-400">${displayTwoDigitWithDelimiter(records.delivery_fee)}</td>
                    </tr>
                )}
                {records.cash_adjustment > 0.0 && (
                    <tr>
                        <td className="text-left fs-14">Non Cash Adj</td>
                        <td className="text-right fs-14 fw-400">${displayTwoDigitWithDelimiter(records.cash_adjustment)}</td>
                    </tr>
                )}
                <tr>
                    <td className="text-left fs-14 fw-600">Amount Collected
                        <img src={icInfo} className="ml-1" alt=""
                             data-tip="The final sale amount collected including taxes, tips, Sur Charge, Non-Cash Adjustments and service charges."/>
                        <ReactTooltip />
                    </td>
                    <td className="text-right fs-14 fw-600">${displayTwoDigitWithDelimiter(records.total_amount_collected)}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}
