import List from '../../../utilities/list/list';

import styles from './printer-queues-list.module.scss';

const PrinterQueuesList = ({ queues, isLoading }) => {
  const renderTerminals = (terminals) => {
    return <span>{terminals.printer_terminals?.join(', ')}</span>;
  };
  const columnConfig = [
    {
      key: 'name',
      header: 'Printer Name',
    },
    {
      key: 'printer_type',
      header: 'Orders to be print ID',
    },
    {
      key: 'printer_terminals',
      header: 'To be print orders count',
      render: renderTerminals,
    },
  ];

  return <List data={queues} columnConfig={columnConfig} isLoading={isLoading} isDragable={false} />;
};

export default PrinterQueuesList;
