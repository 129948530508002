import { IoTrash } from 'react-icons/io5';

import Switch from '../../../utilities/switch/switch';
import Input from '../../../utilities/input/input';
import List from '../../../utilities/list/list';

import styles from './terminal-settings-list.module.scss';

const TerminalSettingsList = ({ settings, onInputChange, onListChange, onDelete, onToggleActive, isLoading }) => {
  const renderNameInput = (item) => (
    <Input
      id={item.id}
      value={item.name}
      name="name"
      onChange={onInputChange}
      disabled={true}
      style={{ marginBottom: 0 }}
    />
  );
  const renderUniqueIdInput = (item) => (
    <Input
      id={item.id}
      value={item.uniq_id}
      name="uniq_id"
      onChange={onInputChange}
      disabled={true}
      style={{ marginBottom: 0 }}
    />
  );
  const renderSwitcher = (item) => <Switch id={item.id} isActive={item.master_terminal} onSwitch={onToggleActive} />;

  const columnConfig = [
    {
      key: 'name',
      header: 'Name',
      render: renderNameInput,
      className: styles.head,
    },
    {
      key: 'uniq_id',
      header: 'Terminal Login Unique ID',
      render: renderUniqueIdInput,
    },
    {
      key: 'master_terminal',
      header: 'Active',
      render: renderSwitcher,
    },
  ];

  return (
    <List
      data={settings}
      columnConfig={columnConfig}
      onChange={onListChange}
      isLoading={isLoading}
      isDragable={false}
      rowClassName={styles.settings_row}
    />
  );
};

export default TerminalSettingsList;
