import { z } from "zod";

export const registrationSchema = z.object({
  email: z
    .string({ required_error: "Email address is required" })
    .min(1, { message: "Email address is required" })
    .email({ message: "Please enter a valid Email Address" }),
  password: z
    .string({ required_error: "Password is required" })
    .min(1, { message: "Password is required" })
    .regex(/^[a-zA-Z0-9]+$/, {
      message: "Password must contain only English letters and Arabic numbers.",
    }),
});
