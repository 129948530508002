import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getServiceCharges,
  addServiceCharge,
  updateServiceCharge,
  deleteServiceCharge,
  updateServiceChargeStatus,
} from '../api/service_charges';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchServiceCharges = createAsyncThunk('charges/fetchCharges', async (token) => {
  const response = await getServiceCharges(token);
  return response.data;
});

export const createServiceCharge = createAsyncThunk(
  'charges/addCharge',
  async ({ newChargeDetails, token }, { dispatch }) => {
    try {
      const response = await addServiceCharge(newChargeDetails, token);
      dispatch(
        addNotification({
          message: 'Service Charge was created successfully',
          status: 'succeeded',
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: 'Failed to create service charge',
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateExistingServiceCharge = createAsyncThunk(
  'charges/updateExistingCharge',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateServiceCharge(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Service Charge was updated successfully',
          status: 'succeeded',
        }),
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: 'Failed to update service charge',
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeServiceCharge = createAsyncThunk('charges/removeCharge', async ({ id, token }, { dispatch }) => {
  try {
    await deleteServiceCharge(id, token);
    dispatch(
      addNotification({
        message: 'Service Charge deleted successfully',
        status: 'succeeded',
      }),
    );
    return id;
  } catch (error) {
    dispatch(
      addNotification({
        message: 'Failed to delete service charge',
        status: 'failed',
      }),
    );
    throw error;
  }
});

export const updateExistingChargeStatus = createAsyncThunk(
  'charges/updateExistingChargeStatus',
  async ({ id, is_enabled, token }, { dispatch }) => {
    try {
      await updateServiceChargeStatus(id, is_enabled, token);
      dispatch(
        addNotification({
          message: 'Service Charge status was updated successfully',
          status: 'succeeded',
        }),
      );
      return { id, is_enabled };
    } catch (error) {
      dispatch(
        addNotification({
          message: 'Failed to update service charge status',
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

const initialState = {
  service_charges: [],
  status: 'idle',
  error: null,
};

const serviceChargesSlice = createSlice({
  name: 'charges',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    };

    builder
      .addCase(fetchServiceCharges.pending, setLoading)
      .addCase(fetchServiceCharges.fulfilled, (state, action) => {
        setSucceeded(state);
        state.service_charges = action.payload;
      })
      .addCase(fetchServiceCharges.rejected, setFailed)

      .addCase(createServiceCharge.pending, setLoading)
      .addCase(createServiceCharge.fulfilled, (state, action) => {
        setSucceeded(state);
        state.service_charges.push(action.payload);
      })
      .addCase(createServiceCharge.rejected, setFailed)

      .addCase(updateExistingServiceCharge.pending, setLoading)
      .addCase(updateExistingServiceCharge.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, updatedDetails } = action.payload;
        const existingServiceCharge = state.service_charges.find((charge) => charge.id === id);
        if (existingServiceCharge) {
          Object.assign(existingServiceCharge, updatedDetails);
        }
      })
      .addCase(updateExistingServiceCharge.rejected, setFailed)

      .addCase(removeServiceCharge.fulfilled, (state, action) => {
        state.service_charges = state.service_charges.filter((charge) => charge.id !== action.payload);
      })

      .addCase(updateExistingChargeStatus.pending, setLoading)
      .addCase(updateExistingChargeStatus.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, is_active } = action.payload;
        const existingServiceCharge = state.service_charges.find((charge) => charge.id === id);
        if (existingServiceCharge) {
          existingServiceCharge.is_active = is_active;
        }
      })
      .addCase(updateExistingChargeStatus.rejected, setFailed);
  },
});

export default serviceChargesSlice.reducer;
