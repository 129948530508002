import React from "react";

import PageTitle from "../../../../utilities/page-title/page-title";
import BackdropLoading from "../../../../utilities/backdrop-loading/backdrop-loading";

import styles from "./top-selling-items.module.scss";

const TopSellingItemsWidget = ({ data, isLoading }) => {
  const { name, items } = data;

  let sortedItems = [];
  if (items && items.length > 0) {
    sortedItems = [...items].sort((a, b) => b.quantity - a.quantity);
  }

  return (
    <div className={styles.wrapper}>
      <PageTitle name={name} className={styles.subtitle} />
      <div className={styles.content}>
        {isLoading && <BackdropLoading />}
        {sortedItems && sortedItems.length > 0 ? (
          sortedItems.map((value) => (
            <div key={value.name} className={styles.item}>
              <div className={styles.name}>{value.name}</div>
              <div className={styles.price}>${value.price}</div>
              <div className={styles.quantity}>{value.quantity}</div>
            </div>
          ))
        ) : (
          <div className={styles.no_data}>
            No data for the selected time period
          </div>
        )}
      </div>
    </div>
  );
};

export default TopSellingItemsWidget;
