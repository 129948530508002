import { IoIosArrowDown } from 'react-icons/io';

import Input from '../../../../utilities/input/input';
import Selector from '../../../../utilities/selector/selector';

import styles from '../employees.module.scss';

const EmployeeForm = ({ employee, roles, onInputChange, onRoleChange, errors }) => {
  return (
    <div className={styles.fields}>
      <div className={styles.row}>
        <div className={styles.field}>
          <Input
            label={'First name'}
            value={employee.first_name}
            onChange={onInputChange}
            name={'first_name'}
            required={true}
            errors={errors.first_name?._errors}
          />
        </div>
        <div className={styles.field}>
          <Input
            label={'Last name'}
            value={employee.last_name}
            onChange={onInputChange}
            name={'last_name'}
            required={true}
            errors={errors.last_name?._errors}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.field}>
          <Input
            label={'Email address'}
            type="email"
            value={employee.email}
            onChange={onInputChange}
            name={'email'}
            errors={errors.email?._errors}
          />
        </div>
        <div className={styles.field}>
          <Input
            label={'Mobile number'}
            type="text"
            value={employee.phone_number}
            onChange={onInputChange}
            name={'phone_number'}
            errors={errors.phone_number?._errors}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.field}>
          <Input
            label={'Passcode'}
            type="text"
            value={employee.passcode}
            onChange={onInputChange}
            name={'passcode'}
            required={true}
            errors={errors.passcode?._errors}
          />
        </div>
        <div className={styles.field}>
          <Input
            label={'Hourly Rate'}
            type="number"
            step={0.01}
            value={employee.hourly_wages}
            onChange={onInputChange}
            name={'hourly_wages'}
            errors={errors.hourly_wages?._errors}
          />
        </div>
      </div>

      <div className={styles.selector_field}>
        <Selector
          icon={IoIosArrowDown}
          label={'Role'}
          items={roles}
          selectedItem={employee.team_role_id}
          onSelectItem={onRoleChange}
          placeholder={'Select Role'}
          required={true}
          errors={errors.team_role_id?._errors}
        />
      </div>
    </div>
  );
};

export default EmployeeForm;
