import React from "react";
import ViewDate from "../components/view-date";


export default function OrderDetailTemplate({order}) {
    return (
        <div className="row p-3" style={{
            color: 'black',
            backgroundColor: 'white'
        }}>
            <div className="col-4 card border-0">
                <div className="card-header font-weight-bold">
                    <h5>ORDER DETAILS</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h6><b>Customer:</b> {order?.customer_name || 'N/A'}</h6>
                        </div>
                        <div className="col-12">
                            <h6><b>Order ID:</b> {order.offline_id}</h6>
                        </div>
                        <div className="col-12">
                            <h6><b>Order Date:</b> <ViewDate date={order.created_at}/></h6>
                        </div>
                        <div className="col-12">
                            <h6><b>Type:</b> {order.open_order_type}</h6>
                        </div>
                        <div className="col-12">
                            <h6><b>Status: </b>{order.order_status}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-4 card border-0">
                <div className="card-header font-weight-bold">
                    <h5>EMPLOYEE</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h5>{order.employee_name}</h5>
                        </div>
                        <div className="col-12">

                        </div>

                    </div>
                </div>
            </div>
            <div className="col-4 card border-0">
                <div className="card-header font-weight-bold">
                    <h5>TOTAL</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h6><b>Total: </b>{order.total_amount || 0}</h6>
                        </div>
                        <div className="col-12">
                            <h6><b>Discount: </b>{order.total_discount || 0}</h6>
                        </div>
                        <div className="col-12">
                            <h6><b>Tip: </b>{order.total_tips || 0}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 p-4">
                <h5>ORDER ITEMS</h5>
            </div>
            <div className="col-12">
                <table className="table table-bordered">
                    <thead className="thead-light">
                    <tr>
                        <th>Item Name</th>
                        <th>Category</th>
                        <th>Quantity</th>
                        <th>Discount</th>
                        <th>Price</th>
                        <th>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {order.order_items && order.order_items.map((item) => (
                        <tr key={item.id}>
                            <td>{item.item_name}</td>
                            <td>{item.category_id || 'N/A'}</td>
                            <td>{item.quantity}</td>
                            <td>{item.discount_amount}</td>
                            <td>{item.price}</td>
                            <td>{item.total_price}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}