import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Search from '../../../../utilities/search/search';
import Button from '../../../../utilities/button/button';

import {DropdownTypes} from './DropdownTypes';
import {fetchAllEmployee} from "../../../../../redux/employeesSlice";

import "./../typographyReport.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./../commonReports.scss";
import styles from "./OrderFilters.module.scss"

import closeRed from "./../../../../../assets/svg/close-blue.svg";
import {useAppContext} from "../../../../../context/context";
import {fetchTaxes} from "../../../../../redux/taxesSlice";
import {fetchModifiers} from "../../../../../redux/modifierSetsSlice";
import {fetchCategories} from "../../../../../redux/categoriesSlice";
import {fetchAllItems} from "../../../../../redux/itemsSetSlice";


const FiltersForReports = ({
                           dropdowns = [],
                           searchShow = true,
                           searchTitle = '',
                           additionalFilter= null,
                           onUpdatedParams,
                          }) => {

    const { token } = useAppContext();
    const dispatch = useDispatch();

    const employees = useSelector((state) => state.employees.employees);
    const taxes = useSelector((state) => state.taxes.taxes);
    const modifiers = useSelector((state) => state.modifierSets.modifierSets);
    const categories = useSelector((state) => state.categories.categories);
    const items = useSelector((state) => state.itemsSet.items);

   const paramsInit = {
       search: "",
       employee_id: "",
       payment_type: "",
       order_type_id: "",
       terminal_id: "",
       action: "website_orders",
       tax_id: "",
       modifier_id: "",
       category_id: "",
       item_id: "",
       captured_id: "",
       card_type: "",
       teamId: "",
       roleId: "",
       cashDiscountSurChargeId: "",
   };
    const [params, setParams] = useState(paramsInit);


    const data = {
        paymentTypes: [
            { id: 1, name: "All" },
            { id: 2, name: "Cash" },
            { id: 3, name: "Card" },
        ],
        orderTypes: [
            { id: 1, name: "Order Type 1" },
            { id: 2, name: "Order Type 2" },
        ],
        terminals: [
            { id: 1, name: "Terminal 1" },
            { id: 2, name: "Terminal 2" },
        ],
        captured: [
            { id: "", name: "All Status" },
            { id: true, name: "Captured" },
            { id: false, name: "Not Captured" },
        ],
        cardType: [
            { id: "", name: "All Card Types" },
            { id: "Debit", name: "Debit" },
            { id: "Credit", name: "Credit" },
        ],
        dataRange: [
            { id: 1, name: "Today" },
            { id: 2, name: "Yesterday" },
            { id: 3, name: "This Week" },
            { id: 4, name: "Last Week" },
            { id: 5, name: "This Month" },
            { id: 6, name: "Last Month" },
        ],
        team: [
            { id: 1, name: "Team 1" },
            { id: 2, name: "Team 2" },
        ],
        roles: [
            { id: 1, name: "Role 1" },
            { id: 2, name: "Role 2" },
        ],
        cashDiscounts: [
            { id: 1, name: "Cash Discount" },
            { id: 2, name: "SurCharge" },
            { id: 3, name: "All" },
        ]
    };

    useEffect(() => {
        if (dropdowns.includes(DropdownTypes.employee)){
            dispatch(fetchAllEmployee({ token }));
        }
        if (dropdowns.includes(DropdownTypes.tax_id)){
            dispatch(fetchTaxes(token));
        }
        if (dropdowns.includes(DropdownTypes.modifier_id)){
            dispatch(fetchModifiers({token}));
        }
        if(dropdowns.includes(DropdownTypes.category_id)){
            dispatch(fetchCategories(token));
        }
        if (dropdowns.includes(DropdownTypes.item_id)){
            dispatch(fetchAllItems({token}));
        }
    }, []);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setParams(prevParams => ({ ...prevParams, [name]: value }));
        onUpdatedParams({[name]: value})
    };

    const dropDownTemplate = ({name, label, items, selectedId}) => {
        return (
            <div className="col-lg-6 col-md-12 col-sm-6">
                <div className="form-group mb-3">
                    <label>{label}</label>
                    <div className="down-arrow w-100 mr-2">
                        <select
                            name={name}
                            value={selectedId}
                            onChange={handleInputChange}
                            className="form-control"
                        >
                            <option key={'def'} value={''} selected={selectedId === ''}>All</option>
                            {items.map(item => (
                                <option key={item.id} value={item.id} selected={selectedId === item.id}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        )
    };

    const configs = [
        {name: 'employee_id', label: 'Employee', items: employees, selectedId: params.employee_id},
        {name: 'payment_type', label: 'Payment Type', items: data.paymentTypes, selectedId: params.payment_type},
        {name: 'order_type_id', label: 'Order Type', items: data.orderTypes, selectedId: params.order_type_id},
        {name: 'terminal_id', label: 'Terminal', items: data.terminals, selectedId: params.terminal_id},
        {name: 'tax_id', label: 'Tax', items: taxes, selectedId: params.tax_id},
        {name: 'modifier_id', label: 'Modifier', items: modifiers, selectedId: params.modifier_id},
        {name: 'category_id', label: 'Category', items: categories, selectedId: params.category_id},
        {name: 'item_id', label: 'Item', items: items, selectedId: params.item_id},
        {name: 'captured_id', label: 'Is Captured', items: data.captured, selectedId: params.captured_id},
        {name: 'card_type', label: 'Card Type', items: data.cardType, selectedId: params.card_type},
        {name: 'date_range', label: 'Date Range', items: data.dataRange, selectedId: params.date_range},
        {name: 'teamId', label: 'Team', items: data.team, selectedId: params.teamId},
        {name: 'roleId', label: 'Role', items: data.roles, selectedId: params.roleId},
        {name: 'cashDiscountSurChargeId', label: 'Cash Discount/SurCharge', items: data.cashDiscounts, selectedId: params.cashDiscountSurChargeId},
    ];

    return (
        <div className="row">
            {searchShow && (
                <>
                    <div className="col-md-12">
                        <div className="d-sm-flex align-items-center justify-content-between pb-2 mb-4">
                            <h4 className="fw-500 fs-18 pb-2 mt-2 pl-0 ml-0">Search</h4>
                            <a>
                                <div className={styles.actionArea}>
                                    <div className="small-circle-icon mr-2">
                                        <img src={closeRed} alt="" height="40"/>
                                    </div>
                                    <span className="fs-16 fw-500 text-blue1">Clear Filters</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-10">
                        <div className="row align-items-end">
                            <div className="col-12 d-inline-flex justify-content-around ">
                                <Search inputPlaceholder={searchTitle} />
                                <Button title={'Search'} className={"mt-2 ml-4"}/>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {
                dropdowns.length > 0 && (
                    <>
                        {searchShow ? (
                            <div className="col-md-12 mt-4">
                                <h4 className="fw-500 fs-18 pb-3 mt-2 pl-0 ml-0 border-bottom">Filter By</h4>
                            </div>
                        ): (
                            <div className="col-md-12">
                                <div className="d-sm-flex align-items-center justify-content-between pb-2 mb-4">
                                    <h4 className="fw-500 fs-18 pb-2 mt-2 pl-0 ml-0">Filter By</h4>
                                    <a>
                                        <div className={styles.actionArea}>
                                            <div className="small-circle-icon mr-2">
                                                <img src={closeRed} alt="" height="40"/>
                                            </div>
                                            <span className="fs-16 fw-500 text-blue1">Clear Filters</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        )}
                    </>
                )
            }


            <div className="col-xl-10" key="col">
                <div className="row">
                    {configs.filter(c => dropdowns.includes(c.name))
                        .map(config => dropDownTemplate(config))}
                </div>
            </div>
            {additionalFilter}
        </div>
    );
};

export default FiltersForReports;
