import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    getSalesReport,
    getEODReport,
    getBatchCloseReport,
    getCancelOrdersReport,
    getCashDiscountReport,
    getCategorySalesReport,
    getTransactionsReport,
    getDeletedTransactionsReport,
    getDiscountsReport,
    getEmployeeTipSummaryReport,
    getDetailBatchCloseReport,
    getHourlySalesReport,
    getOrdersReport,
    getOrderItemsReport, getOrderDetailsReport,
} from "../api/reports";

import { addNotification } from "./tooltipSlice";

// Thunks
export const fetchSalesReport = createAsyncThunk(
  "reports/fetchSalesReport",
  async ({ token, startDate, endDate, terminalId, page }, { dispatch }) => {
    try {
      const response = await getSalesReport({ token, startDate, endDate, terminalId, page });
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch sales report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchEODReport = createAsyncThunk(
  "reports/fetchEODReport",
  async ({ token, startDate, endDate }, { dispatch }) => {
    try {
      const response = await getEODReport({ token, startDate, endDate });
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch EOD report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchBatchCloseReport = createAsyncThunk(
  "reports/fetchBatchCloseReport",
  async ({ token, startDate, endDate, page, perPage }, { dispatch }) => {
    try {
      const response = await getBatchCloseReport({ token, startDate, endDate, page, perPage });
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch batch close report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchCancelOrdersReport = createAsyncThunk(
  "reports/fetchCancelOrdersReport",
  async ({ token, startDate, endDate, page, perPage }, { dispatch }) => {
    try {
      const response = await getCancelOrdersReport({ token, startDate, endDate, page, perPage });
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch cancel orders report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchCashDiscountReport = createAsyncThunk(
  "reports/fetchCashDiscountReport",
  async (params, { dispatch }) => {
    try {
      const response = await getCashDiscountReport(params);
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch cash discount report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchCategorySalesReport = createAsyncThunk(
  "reports/fetchCategorySalesReport",
  async ({ token, startDate, endDate, page, perPage }, { dispatch }) => {
    try {
      const response = await getCategorySalesReport({ token, startDate, endDate, page, perPage });
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch category sales report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchTransactionsReport = createAsyncThunk(
  "reports/fetchTransactionsReport",
  async (params, { dispatch }) => {
    try {
      const response = await getTransactionsReport(params);
      return response.data.transactions;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch transactions report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchDeletedTransactionsReport = createAsyncThunk(
  "reports/fetchDeletedTransactionsReport",
  async (params, { dispatch }) => {
    try {
      const response = await getDeletedTransactionsReport(params);
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch deleted transactions report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchDiscountsReport = createAsyncThunk(
  "reports/fetchDiscountsReport",
  async (params, { dispatch }) => {
    try {
      const response = await getDiscountsReport(params);
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch discounts report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchEmployeeTipSummaryReport = createAsyncThunk(
  "reports/fetchEmployeeTipSummaryReport",
  async (params, { dispatch }) => {
    try {
      const response = await getEmployeeTipSummaryReport(params);
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch employee tip summary report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchDetailBatchCloseReport = createAsyncThunk(
  "reports/fetchDetailBatchCloseReport",
  async (params, { dispatch }) => {
    try {
      const response = await getDetailBatchCloseReport(params);
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch detail batch close report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchHourlySalesReport = createAsyncThunk(
  "reports/fetchHourlySalesReport",
  async ({ token, dateFilter, startDate, endDate, employeeId }, { dispatch }) => {
    try {
      const response = await getHourlySalesReport({ token, dateFilter, startDate, endDate, employeeId });
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch hourly sales report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchOrdersReport = createAsyncThunk(
  "reports/fetchOrdersReport",
  async (params, { dispatch }) => {
    try {
      const response = await getOrdersReport(params);
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch orders report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchOrderItemsReport = createAsyncThunk(
  "reports/fetchOrderItemsReport",
  async (params, { dispatch }) => {
    try {
      const response = await getOrderItemsReport(params);
      return response.data;
    } catch (error) {
      dispatch(addNotification({ message: "Failed to fetch order items report", status: "failed" }));
      throw error;
    }
  }
);

export const fetchOrderDetails = createAsyncThunk(
    "reports/fetchOrderDetails",
    async ({token, orderId}, { dispatch }) => {
        try {
            const response = await getOrderDetailsReport({token, orderId});
            return response.data.order;
        } catch (error) {
            dispatch(addNotification({ message: "Failed to fetch order items report", status: "failed" }));
            throw error;
        }
    }
);

const initialState = {
  salesReport: null,
  eodReport: null,
  batchCloseReport: null,
  cancelOrdersReport: {
      orders: [],
  },
  cashDiscountReport: null,
  categorySalesReport: null,
  transactionsReport: [],
  deletedTransactionsReport: [],
  discountsReport: null,
  employeeTipSummaryReport: null,
  detailBatchCloseReport: null,
  hourlySalesReport: null,
  ordersReport: [],
  orderItemsReport: {
      order_items: []
  },
  orderDetails: null,
  status: "idle",
  error: null,
};

const reportsSetSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = "loading";
    };

    const setSucceeded = (state) => {
      state.status = "succeeded";
    };

    const setFailed = (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    };

    builder
      .addCase(fetchSalesReport.pending, setLoading)
      .addCase(fetchSalesReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.salesReport = action.payload;
      })
      .addCase(fetchSalesReport.rejected, setFailed)

      .addCase(fetchEODReport.pending, setLoading)
      .addCase(fetchEODReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.eodReport = action.payload;
      })
      .addCase(fetchEODReport.rejected, setFailed)

      .addCase(fetchBatchCloseReport.pending, setLoading)
      .addCase(fetchBatchCloseReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.batchCloseReport = action.payload;
      })
      .addCase(fetchBatchCloseReport.rejected, setFailed)

      .addCase(fetchCancelOrdersReport.pending, setLoading)
      .addCase(fetchCancelOrdersReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.cancelOrdersReport = action.payload;
      })
      .addCase(fetchCancelOrdersReport.rejected, setFailed)

      .addCase(fetchCashDiscountReport.pending, setLoading)
      .addCase(fetchCashDiscountReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.cashDiscountReport = action.payload;
      })
      .addCase(fetchCashDiscountReport.rejected, setFailed)

      .addCase(fetchCategorySalesReport.pending, setLoading)
      .addCase(fetchCategorySalesReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.categorySalesReport = action.payload;
      })
      .addCase(fetchCategorySalesReport.rejected, setFailed)

      .addCase(fetchTransactionsReport.pending, setLoading)
      .addCase(fetchTransactionsReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.transactionsReport = action.payload;
      })
      .addCase(fetchTransactionsReport.rejected, setFailed)

      .addCase(fetchDeletedTransactionsReport.pending, setLoading)
      .addCase(fetchDeletedTransactionsReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.deletedTransactionsReport = action.payload;
      })
      .addCase(fetchDeletedTransactionsReport.rejected, setFailed)

      .addCase(fetchDiscountsReport.pending, setLoading)
      .addCase(fetchDiscountsReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.discountsReport = action.payload;
      })
      .addCase(fetchDiscountsReport.rejected, setFailed)

      .addCase(fetchEmployeeTipSummaryReport.pending, setLoading)
      .addCase(fetchEmployeeTipSummaryReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.employeeTipSummaryReport = action.payload;
      })
      .addCase(fetchEmployeeTipSummaryReport.rejected, setFailed)

      .addCase(fetchDetailBatchCloseReport.pending, setLoading)
      .addCase(fetchDetailBatchCloseReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.detailBatchCloseReport = action.payload;
      })
      .addCase(fetchDetailBatchCloseReport.rejected, setFailed)

      .addCase(fetchHourlySalesReport.pending, setLoading)
      .addCase(fetchHourlySalesReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.hourlySalesReport = action.payload;
      })
      .addCase(fetchHourlySalesReport.rejected, setFailed)

      .addCase(fetchOrdersReport.pending, setLoading)
      .addCase(fetchOrdersReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.ordersReport = action.payload.orders;
      })
      .addCase(fetchOrdersReport.rejected, setFailed)

      .addCase(fetchOrderItemsReport.pending, setLoading)
      .addCase(fetchOrderItemsReport.fulfilled, (state, action) => {
        setSucceeded(state);
        state.orderItemsReport = action.payload;
      })
      .addCase(fetchOrderItemsReport.rejected, setFailed)

      .addCase(fetchOrderDetails.pending, setLoading)
      .addCase(fetchOrderDetails.fulfilled, (state, action) => {
          setSucceeded(state);
          state.orderDetails = action.payload;
      })
      .addCase(fetchOrderDetails.rejected, setFailed);
  },
});

export default reportsSetSlice.reducer;