import { IoTrash } from 'react-icons/io5';
import { MdModeEditOutline } from 'react-icons/md';
import List from '../../../utilities/list/list';

import styles from './option-sets-list.module.scss';

const OrderTypesList = ({ options, onEdit, onDelete, isLoading }) => {
  const renderOptions = (options) => <span>{options.options?.map((option) => option.name).join(', ')}</span>;

  const columnConfig = [
    {
      key: 'name',
      header: 'Option Set Name',
    },
    {
      key: 'options',
      header: 'Options',
      render: renderOptions,
    },
  ];

  const rowActions = [
    { icon: <MdModeEditOutline />, onClick: onEdit, label: 'Edit employee', className: styles.row_actions_button },
    { icon: <IoTrash />, onClick: onDelete, label: 'Delete employee', className: styles.row_actions_button },
  ];

  return <List data={options} columnConfig={columnConfig} rowActions={rowActions} isLoading={isLoading} />;
};

export default OrderTypesList;
