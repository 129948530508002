import ReactDatePicker from 'react-datepicker';
import clsx from 'clsx';

import styles from './date-picker.module.scss';

const DatePicker = ({
  selected,
  label,
  name,
  dateFormat,
  showTimeSelect,
  timeFormat,
  timeIntervals,
  onChange,
  placeholderText,
  containerClassName,
  wrapperClassName,
  className,
  required,
  errors = [],
}) => {
  return (
    <div className={clsx(styles.container, containerClassName)}>
      {label && (
        <label className={clsx(styles.label, required && styles.asterisk)} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={styles.date_picker}>
        <ReactDatePicker
          selected={selected}
          label={label}
          name={name}
          onChange={onChange}
          dateFormat={dateFormat}
          showTimeSelect={showTimeSelect}
          timeFormat={timeFormat}
          timeIntervals={timeIntervals}
          className={className}
          placeholderText={placeholderText}
          wrapperClassName={wrapperClassName}
        />
      </div>
      {!!errors.length && (
        <div className={styles.error_container}>
          {errors.map((error, index) => (
            <div key={index} className={styles.error}>
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
