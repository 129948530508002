import React from 'react';
import { displayTwoDigitWithDelimiter } from '../components/helper';
import { Link } from 'react-router-dom';

const ReportTable = ({ giftCardCustomerUsage, startDate, endDate }) => {
    return (
        <table className="table table-bordered">
            <thead className="thead-light">
            <tr>
                <th>Order ID</th>
                <th>Date Time</th>
                <th>Amount</th>
                <th>Balance</th>
            </tr>
            </thead>
            <tbody>
            {giftCardCustomerUsage && giftCardCustomerUsage.length > 0 ? (
                giftCardCustomerUsage.map((usage, index) => (
                    <tr key={index}>
                        <td>
                            <Link
                                to={{
                                    pathname: `/order-details/${usage.order_original_id}`,
                                    search: `?start_date=${startDate}&end_date=${endDate}`
                                }}
                                className="text-blue1"
                            >
                                {usage.order_id}
                            </Link>
                        </td>
                        <td>{usage.date_time}</td>
                        <td>${displayTwoDigitWithDelimiter(usage.amount)}</td>
                        <td>${displayTwoDigitWithDelimiter(usage.balance)}</td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td className="text-center" colSpan="4">No Records found</td>
                </tr>
            )}
            </tbody>
        </table>
    );
};

export default ReportTable;
