import React from 'react';
import {displayTwoDigit, } from '../components/helper';
import ViewDate from "../components/view-date";

export default function ReportTable({payments}) {

    const getLast4DigitOfCard = (payment) => {
        // Mock function to get last 4 digits of card
        return '1234';
    };

    return (
        <table className="table table-bordered">
            <thead className="thead-light">
            <tr>
                <th>Created</th>
                <th>Trans#</th>
                <th>Tender Type</th>
                <th>Order Type</th>
                <th>Amount</th>
                <th>Taxes and Fees</th>
                <th>Tip Amount</th>
                <th>Employee</th>
                <th>CashDiscount / SurCharge</th>
                <th>CashDiscount / SurCharge Amount</th>
                <th>Terminal</th>
                <th>Customer</th>
            </tr>
            </thead>
            <tbody className="cash_discounts_list">
            {payments.length > 0 ? (
                payments.map((payment) => {
                    const order = payment.order;
                    return (
                        <tr key={payment.id}>
                            <td>
                                <div className="connected-printer text-left">
                                    <h6 className="text-gray2 fw-600 mb-1">
                                        <ViewDate date={payment.created_at} />
                                    </h6>
                                    <p>ID: {payment.offline_id}</p>
                                </div>
                            </td>
                            <td>{payment.id}</td>
                            <td>{payment.payment_type} {payment.payment_type === 'Card' ? `(${getLast4DigitOfCard(payment)})` : ''}</td>
                            <td>{order.order_type_name}</td>
                            <td>${displayTwoDigit(payment.amount + payment.tips)}</td>
                            <td>${displayTwoDigit(payment.tax_amount)}</td>
                            <td>${displayTwoDigit(payment.tips)}</td>
                            <td>{payment.employee?.full_name}</td>
                            <td>{payment.cash_discount_type}</td>
                            <td>${displayTwoDigit(payment.cash_discount_or_surcharge)}</td>
                            <td>{payment.terminal?.name}</td>
                            <td>{payment.payable_type === 'Invoice' ? 'Customer Name' : order.customer?.full_name}</td>
                        </tr>
                    );
                })
            ) : (
                <tr>
                    <td className="text-center" colSpan="12">No Records found</td>
                </tr>
            )}
            </tbody>
        </table>
    );
}
