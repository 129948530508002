import React from "react";

import clsx from "clsx";
import styles from "./textarea.module.scss";

const Textarea = ({ label, value, onChange, required, className, style }) => {
  return (
    <div 
        className={clsx(
            styles.wrapper,
            className ? className : ""
        )}
        style={style}
    >
        <label className={styles.label}>
            {label}
            {required ? <span className={styles.starlet}>*</span> : null}
        </label>
        <textarea 
            className={styles.description} 
            value={value} 
            onChange={onChange}
        ></textarea>
    </div>
  );
};

export default Textarea;
