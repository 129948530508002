import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function Widget (
    {
        totalPaymentsCount,
        capturedPaymentsCount,
        totalAmountCollectedByCard,
        totalCapturedAmount
    }) {
    return (
        <div className="row mb-4">
            <div className="col-1"></div>
            <div className="col-5">
                <h4>Total Payments Count: &nbsp;&nbsp;{totalPaymentsCount}</h4>
                <h4>Captured Payments Count: &nbsp;&nbsp;{capturedPaymentsCount}</h4>
            </div>
            <div className="col-1"></div>
            <div className="col-5">
                <h4>Total Amount Collected by Card: &nbsp;&nbsp;${totalAmountCollectedByCard}</h4>
                <h4>Total Captured Amount: &nbsp;&nbsp;${totalCapturedAmount}</h4>
            </div>
        </div>
    )
}
