import { z } from 'zod';

export const registrationSchema = z.object({
  name: z
    .string({ required_error: 'Please enter Tax Name' })
    .min(1, { message: 'Tax Name must contain at least 1 characters' })
    .max(60, { message: 'Name can contain a maximum of 60 characters' })
    .regex(/^[-a-zA-Z0-9-()]+(\s[-a-zA-Z0-9-()]+)*$/, { message: 'Please enter valid value' }),

  rate: z
    .number({ required_error: 'Please enter Service Rate' })
    .min(0.01, { message: 'Service Rate should be greater than 0.01' })
    .max(100, { message: 'Service Rate should not exceed 100' }),

  tax_type: z.string({ required_error: 'Please select Tax Type' }),
});
