import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAppContext } from '../../../../context/context';

import {
  fetchNotes,
  addNote,
  updateExistingNote,
  removeNote,
  updateExistingNoteStatus,
  updateExistingNoteOrder,
} from '../../../../redux/dynamicNotesSlice';
import { addNotification } from '../../../../redux/tooltipSlice';
import { showModal, hideModal } from '../../../../redux/modalSlice';

import PageTitle from '../../../utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Button from '../../../utilities/button/button';

import { sortItems } from '../../../utilities/utils';
import useFormValidation from '../../../utilities/useFormValidation';
import { registrationSchema } from './validation';

import DynamicNotesList from './order-notes-list';

import styles from './order-notes.module.scss';

function OrderNotes() {
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();
  const notes = useSelector((state) => state.notes.notes);
  const notesStatus = useSelector((state) => state.notes.status);

  // Add new tax
  const [newNote, setNewNote] = useState('');

  // Edit note
  const [editedNotes, setEditedNotes] = useState({});

  // Validation hook
  const { errors, validate } = useFormValidation(registrationSchema);

  useEffect(() => {
    if (token) {
      dispatch(fetchNotes(token));
    }
  }, [dispatch, token]);

  const handleAdd = useCallback(() => {
    const newDetails = {
      name: newNote,
      location_id: locationId,
      is_active: true,
    };

    if (validate(newDetails)) {
      dispatch(addNote({ newDetails, token }));
      setNewNote('');
    }
  }, [dispatch, locationId, newNote, token, validate]);

  const handleUpdate = async () => {
    try {
      await Promise.all(
        Object.entries(editedNotes).map(([id, updatedDetails]) =>
          dispatch(updateExistingNote({ id, updatedDetails, token })),
        ),
      );
      setEditedNotes({});
    } catch (error) {
      dispatch(
        addNotification({
          message: 'Notes wasn`t updated, something went wrong: ' + error,
          status: 'failed',
        }),
      );
    }
  };

  const handleInputChange = useCallback(
    (id, field, value) => {
      const existingNote = notes.find((note) => note.id === id);

      if (existingNote) {
        setEditedNotes((prev) => ({
          ...prev,
          [id]: {
            ...existingNote,
            ...prev[id],
            [field]: value,
          },
        }));
      }
    },
    [notes],
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(
        showModal({
          modalId: 'modal-delete-order-note',
          data: {
            type: 'confirmation',
            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete this order note',
            actions: [
              {
                title: 'Delete',
                onAction: () => {
                  dispatch(removeNote({ id, token }));
                  dispatch(hideModal('modal-delete-order-note'));
                },
              },
              {
                title: 'Cancel',
                button_variant: 'grey',
                onAction: () => dispatch(hideModal('modal-delete-order-note')),
              },
            ],
          },
        }),
      );
    },
    [dispatch, token],
  );

  const handleToggleActive = useCallback(
    (id, value) => {
      dispatch(updateExistingNoteStatus({ id, is_active: value, token }));
    },
    [dispatch, token],
  );

  const handleReorderNotes = (item) => {
    const { sourceId, index } = item;

    const old_position = notes.find((reason) => reason.id === sourceId)?.sort;
    const new_position = sortItems(notes, 'sort')[index]?.sort;

    if (old_position !== new_position) {
      dispatch(updateExistingNoteOrder({ id: sourceId, updatedDetails: { old_position, new_position }, token }));
    }
  };

  return (
    <div className={styles.dynamic_notes}>
      <PageTitle name={'Dynamic Notes'} />
      <div className={styles.add_note}>
        <div className={styles.section}>
          <Input
            label={'Note Name'}
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            placeholder={'Note Name'}
            required={true}
            className={styles.new_name_input}
            errors={errors.name?._errors}
          />
        </div>
        <Button className={styles.add_button} title={'Add Order Note'} onClick={handleAdd} />
      </div>
      <DynamicNotesList
        items={notes}
        onEdit={handleInputChange}
        editedNotes={editedNotes}
        setEditedNotes={setEditedNotes}
        onDelete={handleDelete}
        onToggleActive={handleToggleActive}
        isLoading={notesStatus === 'loading'}
        isFailed={notesStatus === 'failed'}
        onDrag={handleReorderNotes}
      />
      <Button className={styles.update_button} title={'Update'} onClick={handleUpdate} />
    </div>
  );
}

export default OrderNotes;
