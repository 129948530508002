import React from 'react';
import {displayTwoDigitWithDelimiter} from '../components/helper'
import ViewDate from "../components/view-date";


export default function ReportTable({refundRecords}) {
    return (
        <table className="table table-bordered">
            <thead className="thead-dark">
            <tr>
                <th>Created</th>
                <th>Trans#</th>
                <th>Tender Type</th>
                <th>Original Payment</th>
                <th>Refunded Amount</th>
                <th>Refunded Taxes and Fees</th>
                <th>Refunded Tip Amount</th>
                <th>Employee</th>
                <th>Customer</th>
                <th>Details</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody id="refund_body">
            {refundRecords.length > 0 ? (
                refundRecords.map((refund, index) => {
                    const { order, payment } = refund;
                    if (!order) return null;
                    return (
                        <tr key={index}>
                            <td>
                                <div className="connected-printer text-left">
                                    <h6 className="text-gray2 fw-600 mb-1">
                                        <ViewDate date={order.created_at} />
                                    </h6>
                                    <p>Order ID: {order.id}</p>
                                </div>
                            </td>
                            <td>{payment.id}</td>
                            <td>
                                {payment.payment_type} {payment.Card ? `(${payment.id.slice(-4)})` : ''}
                            </td>
                            <td className="text-left">
                                ${displayTwoDigitWithDelimiter(payment.amount)}
                            </td>
                            <td className="text-red">
                                ${displayTwoDigitWithDelimiter(refund.amount)}
                            </td>
                            <td>
                                ${displayTwoDigitWithDelimiter(refund.tax_refunded)}
                            </td>
                            <td>
                                ${displayTwoDigitWithDelimiter(refund.tips_refunded)}
                            </td>
                            <td>
                                {order.employee && (
                                    <a href={`/employees/${order.employee.id}/edit`} className="fs-14 text-underline fw-500">
                                        {order.employee.full_name}
                                    </a>
                                )}
                            </td>
                            <td>{order.customer?.full_name || '-'}</td>
                            <td>
                                <a href={`/orders/${order.id}/details`}>Details</a><br />
                                <a href={order.short_receipt_url} target="_blank" rel="noopener noreferrer">Receipt</a>
                            </td>
                            <td>{refund.reason_for_refund || '-'}</td>
                        </tr>
                    );
                })
            ) : (
                <tr>
                    <td colSpan="11">
                        <center>No Records available.</center>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    );
}
