import React, { useState } from "react";

import List from "../../../../utilities/list/list";
import Label from "../../../../utilities/label/label";

import { IoTrash } from "react-icons/io5";

import styles from "./modifiers-list.module.scss";

const ModifiersList = ({ items, onEdit, onDelete }) => {
  const renderName = (item) => (
    <span className={styles.modifier_name} id={item.id}>
      {`${item.name} (${item.modifiers.map(modifier => modifier.name)})`}
    </span>
  );

  const columnConfig = [
    {
      key: "name",
      render: renderName,
    }
  ];

  const rowActions = [
    {
      icon: <IoTrash />,
      onClick: onDelete,
      label: "Delete item",
      className: styles.variation_action,
    },
  ];

  let sortedItems = [];

  if (items && items.length) {
    sortedItems = items.sort((a, b) => a.id - b.id);
  }

  return (
    <List
      data={sortedItems}
      columnConfig={columnConfig}
      rowActions={rowActions}
      isDragable={true}
      isWithHeader={false}
      isNoDataBlock={false}
      className={styles.list_wrapper}
    />
  );
};

export default ModifiersList;
