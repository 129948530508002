import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useAppContext } from "../../../../context/context";

import {
  fetchWorkingHours,
  createWorkingHours,
  updateExistingWorkingHours,
} from "../../../../redux/workingHoursSlice";

import WorkingDayRow from "./hours-row";

import Selector from "../../../utilities/selector/selector";
import Modal from "../../../utilities/modal/modal";

import { IoIosArrowDown } from "react-icons/io";

import styles from "./hours.module.scss";

function Hours() {
  const dispatch = useDispatch();

  const { token, locationId } = useAppContext();

  const working_hours = useSelector((state) => state.hours.working_hours);
  const workingHoursStatus = useSelector((state) => state.hours.status);

  const [changedFields, setChangedFields] = useState([]);

  const [newName, setNewName] = useState("");
  const [newStatus, setNewStatus] = useState("Closed");
  const [newOpeningTime, setNewOpeningTime] = useState("");
  const [newClosingTime, setNewClosingTime] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const statusValues = [
    { value: true, label: "Open" },
    { value: false, label: "Closed" },
  ];

  useEffect(() => {
    if (workingHoursStatus === "idle") {
      dispatch(fetchWorkingHours(token));
    }
  }, [dispatch, workingHoursStatus, token]);

  const handleFieldChange = (id, updatedField) => {
    setChangedFields((prevChangedFields) => {
      const existingFieldIndex = prevChangedFields.findIndex(
        (field) => field.id === id
      );

      if (existingFieldIndex !== -1) {
        const updatedFields = [...prevChangedFields];
        updatedFields[existingFieldIndex] = {
          ...updatedFields[existingFieldIndex],
          ...updatedField,
        };
        return updatedFields;
      } else {
        return [...prevChangedFields, { id, ...updatedField }];
      }
    });
  };

  const handleSave = () => {
    changedFields.forEach((changedField) => {
      const { id, name, status, openingTime, closingTime } = changedField;
      const originalField = working_hours.find((day) => day.id === id);

      const updatedDetails = {
        week_day: name !== undefined ? name : originalField.week_day,
        is_open: status !== undefined ? status : originalField.is_open,
        opening_time:
          openingTime !== undefined ? openingTime : originalField.opening_time,
        closing_time:
          closingTime !== undefined ? closingTime : originalField.closing_time,
      };

      dispatch(updateExistingWorkingHours({ id, updatedDetails, token }));
    });
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAdd = () => {
    setIsModalOpen(true);
  };

  const booleanStatus = newStatus === "true" ? true : false;

  const newDayDetails = useMemo(
    () => ({
      week_day: newName,
      is_open: booleanStatus,
      opening_time: newOpeningTime,
      closing_time: newClosingTime,
    }),
    [newName, booleanStatus, newOpeningTime, newClosingTime]
  );

  const handleCreate = useCallback(() => {
    dispatch(createWorkingHours({ newDayDetails, token }));
    setIsModalOpen(false);
  }, [newDayDetails, dispatch, token]);

  return (
    <>
      <div className={styles.hours}>
        <div className={styles.header}>
          <h2 className={styles.title}>Business Hours</h2>
          <button
            className={styles.createButton}
            type="button"
            onClick={handleAdd}
          >
            Add Day
          </button>
        </div>

        <div className={styles.hours_table}>
          <div className={styles.table_header}>
            <div className={styles.day}>Day</div>
            <div className={styles.status}>Status</div>
            <div className={styles.opening}>Opening Time</div>
            <div className={styles.closing}>Closing Time</div>
            <div className={styles.delete}>Action</div>
          </div>
          <form className={styles.table_content}>
            {working_hours.map((day) => {
              return (
                <WorkingDayRow
                  key={day.id}
                  id={day.id}
                  initialName={day.week_day}
                  initialStatus={day.is_open}
                  initialOpeningTime={day.opening_time}
                  initialClosingTime={day.closing_time}
                  onFieldChange={handleFieldChange}
                  changedFields={changedFields}
                  setChangedFields={setChangedFields}
                />
              );
            })}
          </form>
          <button
            className={styles.submitButton}
            type="button"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        className={styles.working_hours_add_modal}
      >
        <div className={styles.modal_content}>
          <h3 className={styles.add_title_modal}>Add day</h3>
          <div className={styles.wrapper_modal}>
            <div className={styles.top_row}>
              <div className={styles.name_modal}>
                <label>Day Name:</label>
                <input
                  type="text"
                  placeholder="Monday"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                />
              </div>
              <div className={styles.status_modal}>
                <label>Status:</label>
                <Selector
                  placeholder={newStatus}
                  icon={IoIosArrowDown}
                  items={statusValues}
                  selectedItem={newStatus}
                  onSelectItem={setNewStatus}
                  initialPlaceholderVisibility={false}
                  className={styles.status_selector}
                />
              </div>
            </div>
            <div className={styles.bottom_row}>
              <div className={styles.opening_modal}>
                <label>Opening Time:</label>
                <input
                  type="text"
                  placeholder="09:00 AM"
                  value={newOpeningTime}
                  onChange={(e) => setNewOpeningTime(e.target.value)}
                />
              </div>
              <div className={styles.closing_modal}>
                <label>Closing Time:</label>
                <input
                  type="text"
                  placeholder="22:00 PM"
                  value={newClosingTime}
                  onChange={(e) => setNewClosingTime(e.target.value)}
                />
              </div>
            </div>
          </div>
          <button onClick={handleCreate}>Add</button>
        </div>
      </Modal>
    </>
  );
}

export default Hours;
