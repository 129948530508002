import {displayTwoDigitWithDelimiter} from "../../components/helper";
import React from "react";

export default function OrderSalesDetails({shiftReportData}) {
    shiftReportData = shiftReportData || {
        order_sales_details: {
            data: [
                {
                    "Order Id": 1,
                    "Tip": 0,
                    "Service Charge": 0,
                    "Pay Type": "Cash",
                    "Amount": 0
                },
                {
                    "Order Id": 2,
                    "Tip": 0,
                    "Service Charge": 0,
                    "Pay Type": "Cash",
                    "Amount": 0
                },
                {
                    "Order Id": 3,
                    "Tip": 0,
                    "Service Charge": 0,
                    "Pay Type": "Cash",
                    "Amount": 0
                },
                {
                    "Order Id": 4,
                    "Tip": 0,
                    "Service Charge": 0,
                    "Pay Type": "Cash",
                    "Amount": 0
                },
                {
                    "Order Id": 5,
                    "Tip": 0,
                    "Service Charge": 0,
                    "Pay Type": "Cash",
                    "Amount": 0
                },
                {
                    "Order Id": 6,
                    "Tip": 0,
                    "Service Charge": 0,
                    "Pay Type": "Cash",
                    "Amount": 0
                },
                {
                    "Order Id": 7,
                    "Tip": 0,
                    "Service Charge": 0,
                    "Pay Type": "Cash",
                    "Amount": 0
                },
                {
                    "Order Id": 8,
                    "Tip": 0,
                    "Service Charge": 0,
                    "Pay Type": "Cash",
                    "Amount": 0
                },
                {
                    "Order Id": 9,
                    "Tip": 0,
                    "Service Charge": 0,
                    "Pay Type": "Cash",
                    "Amount": 0
                },
                {
                    "Order Id": 10,
                    "Tip": 0,
                    "Service Charge": 0,
                    "Pay Type": "Cash",
                    "Amount": 0
                }
            ]
        }
    };
    return (
        <>
            <h3 className="admin-main-title text-darkgray1 fw-700 text-center fs-20 py-3 bg-custom-gray border-bottom mb-4 pb-2 bg-custom-gray">
                Order Sales Details
            </h3>
            <div className="admin-table bg-white table-responsive mt-4">
                <table className="table table-bordered">
                    <thead className="thead-light">
                    <tr>
                        <th width="10%">Order Id</th>
                        <th width="25%">Tip</th>
                        <th width="25%">Service Charge</th>
                        <th width="25%">Pay Type</th>
                        <th width="25%">Amount</th>
                        <th width="25%" className="no-wrap">Terminal</th>
                    </tr>
                    </thead>
                    <tbody className="eod_shift_report">
                    {shiftReportData.order_sales_details.data && shiftReportData.order_sales_details.data.length > 0 ? (
                        shiftReportData.order_sales_details.data.map((data, index) => (
                            <tr key={index}>
                                <td>{data["Order Id"]}</td>
                                <td>${displayTwoDigitWithDelimiter(data["Tip"])}</td>
                                <td>${displayTwoDigitWithDelimiter(data["Service Charge"])}</td>
                                <td>{data["Pay Type"]}</td>
                                <td>${displayTwoDigitWithDelimiter(data["Amount"])}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6">No data available</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </>
    )
}
