import React, {useState} from 'react';
import styles from './loyalty-report.module.scss';
import HeaderReport from "../components/HeaderReport/header-report";
import clsx from "clsx";
import {DateRange} from "../components/DateRange";
import ReportTable from "./ReportTable";

function LoyaltyReport() {
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);

    const downloadPdf = () => {}
    const downloadCsv = () => {}

    const fakeData = [
        {
            id: 1,
            customer: { first_name: 'John', last_name: 'Doe' },
            total_amount: 100.0,
            loyalty_amount: 10.0,
            used_reward_points: 50,
            loyalty_balance: 40,
            created_at: '2023-10-01T12:00:00Z'
        },
        {
            id: 2,
            customer: { first_name: 'Jane', last_name: 'Smith' },
            total_amount: 200.0,
            loyalty_amount: 20.0,
            used_reward_points: 100,
            loyalty_balance: 80,
            created_at: '2023-10-02T12:00:00Z'
        }
    ];

  return (
    <div className={styles.contentWrapper}>
        <HeaderReport
            title={"Loyalty Report"}
            downloadPdf={downloadPdf}
            downloadCsv={downloadCsv}
        />
        <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
            <DateRange
                endDate={endDate}
                startDate={startDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
            />
        </div>
        <div className="bg-white table-responsive mt-4">
            <ReportTable data={fakeData} currentLocation={"test"} />
        </div>
    </div>
  )
}

export default LoyaltyReport;
