import React from 'react';
import { Link } from 'react-router-dom';
import clsx from "clsx";
import {
    displayTwoDigitWithDelimiter,
    displayOnlyDateFromString,
    displayOnlyTimeFromString
} from '../components/helper';

import style from './DiscountReportBody.module.scss';

const DiscountReportBody = () => {

   const discountRecords = {
        discount_named_records: [
            {
                'Discount 1': [
                    [
                        {
                            id: 1,
                            custome_id: '123456',
                            date: '2021-08-01T10:00:00Z',
                            order_discount_amount: 0,
                            order_discount_count: 0,
                            item_discount_amount: 0,
                            item_discount_count: 0,
                            total_discount: 0,
                            total_count: 0,
                            order_employee_name: 'John Doe',
                        },
                    ],
                    [
                        {
                            total_order_amount: 0,
                            total_order_count: 0,
                            total_item_amount: 0,
                            total_item_count: 0,
                            total_amount: 0,
                            total_count: 0,
                        },
                    ],
                ],
            },
        ],
        manual_discount: [
            [
                {
                    id: 1,
                    custome_id: '123456',
                    date: '2021-08-01T10:00:00Z',
                    order_discount_amount: 0,
                    order_discount_count: 0,
                    item_discount_amount: 0,
                    item_discount_count: 0,
                    total_discount: 0,
                    total_count: 0,
                    order_employee_name: 'John Doe',
                },
            ],
            [
                {
                    total_order_amount: 0,
                    total_order_count: 0,
                    total_item_amount: 0,
                    total_item_count: 0,
                    total_amount: 0,
                    total_count: 0,
                },
            ],
        ],
       invoice_discount: [
              [
                {
                     id: 1,
                     date: '2021-08-01T10:00:00Z',
                     order_discount_amount: 0,
                     order_discount_count: 0,
                     item_discount_amount: 0,
                     item_discount_count: 0,
                     total_discount: 0,
                     total_count: 0,
                     order_employee_name: 'John Doe',
                },
              ],
              [
                {
                     total_order_amount: 0,
                     total_order_count: 0,
                     total_item_amount: 0,
                     total_item_count: 0,
                     total_amount: 0,
                     total_count: 0,
                },
              ],
       ],
         discount_on_orders: 0,
            discount_on_orders_count: 0,
            discount_on_items: 0,
            discount_on_items_count: 0,
            discount_on_total: 0,
            discount_on_total_count: 0,
    };

    return (
        <>
            <div className="admin-table bg-white table-responsive mt-4 transaction-table ">
                <table className="table table-bordered text-center">
                    <thead className="thead-light">
                    <tr>
                        <th width="12.5%" rowSpan="2">Discount Name</th>
                        <th width="25%" className={style.tableBorder} colSpan="2">
                            Discount on Orders
                        </th>
                        <th width="25%" className={style.tableBorder} colSpan="2">
                            Discount on Items
                        </th>
                        <th width="25%" className={style.tableBorder} colSpan="2">
                            Total Discounts
                        </th>
                        <th width="12.5%" rowSpan="2">Employee </th>
                    </tr>
                    <tr>
                        <th className={clsx(style.tableBorder, "text-center")}>
                            Amount Discounted
                        </th>
                        <th className={style.rightBorder}>
                            Number Applicable</th>
                        <th className={clsx(style.tableBorder, "text-center")}>
                            Amount Discounted
                        </th>
                        <th className={style.rightBorder}>
                            Number Applicable
                        </th>
                        <th className={clsx(style.tableBorder, "text-center")}>
                            Amount Discounted
                        </th>
                        <th className={style.rightBorder}>
                            Number Applicable
                        </th>
                    </tr>
                    </thead>
                </table>
            </div>
            <div className={clsx("accordion", style.discountBody)} id="discount_body">
                {discountRecords.discount_named_records
                    && discountRecords.discount_named_records.length > 0
                    && discountRecords.discount_named_records.map((discount, index) => (
                        <div className="card" key={index}>
                            <div className="card-head" id="headingOne">
                                <h2 className="mb-0" data-toggle="collapse"
                                    data-target={`#collapse_${Object.keys(discount)[0]}`}
                                    aria-expanded="false"
                                    aria-controls={`collapse_${Object.keys(discount)[0]}`}
                                    data-parent="#discount_body">{Object.keys(discount)[0]}</h2>
                            </div>
                            <div id={`collapse_${Object.keys(discount)[0]}`}
                                 className="collapse show"
                                 aria-labelledby="headingOne"
                                 data-parent="#accordionExample">
                                <div className="card-body">
                                    <div className="admin-table bg-white table-responsive mt-1 transaction-table">
                                        <table className="table table-bordered text-center">
                                            {Object.values(discount)[0][0].map((order, orderIndex) => (
                                                <tr key={orderIndex}>
                                                    <td className="fw-500 text-left" width="12.5%">Order:
                                                        <Link to={`/order_details?order_id=${order.id}`}>
                                                            {order.custome_id}<br/>
                                                            <span className="text-blue1">
                                                        {displayOnlyDateFromString(order.date)}
                                                    </span>
                                                            <div className="d-block text-blue1">
                                                                {displayOnlyTimeFromString(order.date)}
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td width="12.5%" className="text-right">
                                                        ${displayTwoDigitWithDelimiter(order.order_discount_amount)}</td>
                                                    <td width="12.5%"
                                                        className="text-right">{order.order_discount_count}</td>
                                                    <td width="12.5%" className="text-right">
                                                        ${displayTwoDigitWithDelimiter(order.item_discount_amount)}</td>
                                                    <td width="12.5%"
                                                        className="text-right">{order.item_discount_count}</td>
                                                    <td width="12.5%"
                                                        className="text-right">${displayTwoDigitWithDelimiter(order.total_discount)}</td>
                                                    <td width="12.5%" className="text-right">{order.total_count}</td>
                                                    <td width="12.5%"
                                                        className="text-right">{order.order_employee_name}</td>
                                                </tr>
                                            ))}
                                            <tr className="bg-light">
                                                {Object.values(discount)[0][1] && Object.values(discount)[0][1][0] && (
                                                    <>
                                                        <td width="12.5%"
                                                            className="fw-500 text-left text-darkgray1">Total
                                                        </td>
                                                        <td width="12.5%" className="text-right text-darkgray1">
                                                            ${displayTwoDigitWithDelimiter(Object.values(discount)[0][1][0].total_order_amount)}</td>
                                                        <td width="12.5%"
                                                            className="text-right text-darkgray1">{Object.values(discount)[0][1][0].total_order_count}</td>
                                                        <td width="12.5%" className="text-right text-darkgray1">
                                                            ${displayTwoDigitWithDelimiter(Object.values(discount)[0][1][0].total_item_amount)}</td>
                                                        <td width="12.5%"
                                                            className="text-right text-darkgray1">{Object.values(discount)[0][1][0].total_item_count}</td>
                                                        <td width="12.5%" className="text-right text-darkgray1">
                                                            ${displayTwoDigitWithDelimiter(Object.values(discount)[0][1][0].total_amount)}</td>
                                                        <td width="12.5%"
                                                            className="text-right text-darkgray1">{Object.values(discount)[0][1][0].total_count}</td>
                                                        <td width="12.5%" className="text-right"></td>
                                                    </>
                                                )}
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                {discountRecords.manual_discount && discountRecords.manual_discount[0] && (
                    <div className="card">
                        <div className="card-head" id="headingOne">
                            <h2 className="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
                                aria-controls="collapseOne" data-parent="#discount_body">Manual</h2>
                        </div>
                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                             data-parent="#accordionExample">
                            <div className="card-body">
                                <div className="admin-table bg-white table-responsive mt-1 transaction-table">
                                    <table className="table table-bordered text-center">
                                        {discountRecords.manual_discount[0].map((order, orderIndex) => (
                                            <tr key={orderIndex}>
                                                <td className="fw-500 text-left" width="12.5%">Order:
                                                    <Link to={`/order_details?order_id=${order.id}`}>
                                                        {order.custome_id}<br/>
                                                        <span
                                                            className="text-blue1">{displayOnlyDateFromString(order.date)}</span>
                                                        <div className="d-block text-blue1">
                                                            {displayOnlyTimeFromString(order.date)}
                                                        </div>
                                                    </Link>
                                                </td>
                                                <td width="12.5%" className="text-right">
                                                    ${displayTwoDigitWithDelimiter(order.order_discount_amount)}</td>
                                                <td width="12.5%"
                                                    className="text-right">{order.order_discount_count}</td>
                                                <td width="12.5%" className="text-right">
                                                    ${displayTwoDigitWithDelimiter(order.item_discount_amount)}</td>
                                                <td width="12.5%"
                                                    className="text-right">{order.item_discount_count}</td>
                                                <td width="12.5%"
                                                    className="text-right">${displayTwoDigitWithDelimiter(order.total_discount)}</td>
                                                <td width="12.5%" className="text-right">{order.total_count}</td>
                                                <td width="12.5%"
                                                    className="text-right">{order.order_employee_name}</td>
                                            </tr>
                                        ))}
                                        <tr className="bg-light">
                                            {discountRecords.manual_discount[1] && discountRecords.manual_discount[1][0] && (
                                                <>
                                                    <td width="12.5%"
                                                        className="fw-500 text-left text-darkgray1">Total
                                                    </td>
                                                    <td width="12.5%" className="text-right text-darkgray1">
                                                        ${displayTwoDigitWithDelimiter(discountRecords.manual_discount[1][0].total_order_amount)}</td>
                                                    <td width="12.5%"
                                                        className="text-right text-darkgray1">{discountRecords.manual_discount[1][0].total_order_count}</td>
                                                    <td width="12.5%" className="text-right text-darkgray1">
                                                        ${displayTwoDigitWithDelimiter(discountRecords.manual_discount[1][0].total_item_amount)}</td>
                                                    <td width="12.5%"
                                                        className="text-right text-darkgray1">{discountRecords.manual_discount[1][0].total_item_count}</td>
                                                    <td width="12.5%" className="text-right text-darkgray1">
                                                        ${displayTwoDigitWithDelimiter(discountRecords.manual_discount[1][0].total_amount)}</td>
                                                    <td width="12.5%"
                                                        className="text-right text-darkgray1">{discountRecords.manual_discount[1][0].total_count}</td>
                                                    <td width="12.5%" className="text-right"></td>
                                                </>
                                            )}
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {discountRecords.invoice_discount && discountRecords.invoice_discount[0] && (
                    <div className="card">
                        <div className="card-head" id="headingOne">
                            <h2 className="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
                                aria-controls="collapseOne" data-parent="#discount_body">Invoice</h2>
                        </div>
                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                             data-parent="#accordionExample">
                            <div className="card-body">
                                <div className="admin-table bg-white table-responsive mt-1 transaction-table">
                                    <table className="table table-bordered text-center">
                                        {discountRecords.invoice_discount[0].map((inv, invIndex) => (
                                            <tr key={invIndex}>
                                                <td className="fw-500 text-left" width="12.5%">Invoice:
                                                    {inv.id}<br/>
                                                    <span
                                                        className="text-blue1">{displayOnlyDateFromString(inv.date)}</span>
                                                    <div className="d-block text-blue1">
                                                        {displayOnlyTimeFromString(inv.date)}
                                                    </div>
                                                </td>
                                                <td width="12.5%" className="text-right">
                                                    ${displayTwoDigitWithDelimiter(inv.order_discount_amount)}</td>
                                                <td width="12.5%" className="text-right">{inv.order_discount_count}</td>
                                                <td width="12.5%" className="text-right">
                                                    ${displayTwoDigitWithDelimiter(inv.item_discount_amount)}</td>
                                                <td width="12.5%" className="text-right">{inv.item_discount_count}</td>
                                                <td width="12.5%"
                                                    className="text-right">${displayTwoDigitWithDelimiter(inv.total_discount)}</td>
                                                <td width="12.5%" className="text-right">{inv.total_count}</td>
                                                <td width="12.5%" className="text-right">{inv.order_employee_name}</td>
                                            </tr>
                                        ))}
                                        <tr className="bg-light">
                                            {discountRecords.invoice_discount[1] && discountRecords.invoice_discount[1][0] && (
                                                <>
                                                    <td width="12.5%"
                                                        className="fw-500 text-left text-darkgray1">Total
                                                    </td>
                                                    <td width="12.5%" className="text-right text-darkgray1">
                                                        ${displayTwoDigitWithDelimiter(discountRecords.invoice_discount[1][0].total_order_amount)}</td>
                                                    <td width="12.5%"
                                                        className="text-right text-darkgray1">{discountRecords.invoice_discount[1][0].total_order_count}</td>
                                                    <td width="12.5%" className="text-right text-darkgray1">
                                                        ${displayTwoDigitWithDelimiter(discountRecords.invoice_discount[1][0].total_item_amount)}</td>
                                                    <td width="12.5%"
                                                        className="text-right text-darkgray1">{discountRecords.invoice_discount[1][0].total_item_count}</td>
                                                    <td width="12.5%" className="text-right text-darkgray1">
                                                        ${displayTwoDigitWithDelimiter(discountRecords.invoice_discount[1][0].total_amount)}</td>
                                                    <td width="12.5%"
                                                        className="text-right text-darkgray1">{discountRecords.invoice_discount[1][0].total_count}</td>
                                                    <td width="12.5%" className="text-right"></td>
                                                </>
                                            )}
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {discountRecords.discount_on_total_count === 0 ? (
                    <tr>
                        <td>
                            <center className="fs-14">No Records Found.</center>
                        </td>
                    </tr>
                ) : (
                    <div className="admin-table bg-white table-responsive mt-1 transaction-table">
                        <table className="table table-bordered text-center" style={{minWidth: '600px'}}>
                            <tbody>
                            <tr className="bg-light">
                                <td width="12.5%" className="fw-600 text-left text-darkgray1">Total</td>
                                <td width="12.5%" className="fw-600 text-right text-darkgray1">
                                    ${displayTwoDigitWithDelimiter(discountRecords.discount_on_orders)}</td>
                                <td width="12.5%"
                                    className="fw-600 text-right text-darkgray1">{discountRecords.discount_on_orders_count}</td>
                                <td width="12.5%" className="fw-600 text-right text-darkgray1">
                                    ${displayTwoDigitWithDelimiter(discountRecords.discount_on_items)}</td>
                                <td width="12.5%"
                                    className="fw-600 text-right text-darkgray1">{discountRecords.discount_on_items_count}</td>
                                <td width="12.5%" className="fw-600 text-right text-darkgray1">
                                    ${displayTwoDigitWithDelimiter(discountRecords.discount_on_total)}</td>
                                <td width="12.5%"
                                    className="fw-600 text-right text-darkgray1">{discountRecords.discount_on_total_count}</td>
                                <td width="12.5%" className="text-right fw-600"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </>
    );
};

export default DiscountReportBody;
