import { api } from "./base";

export const logIn = async (email, password) => {
  try {
    const response = await api.post("users/log_in", { email, password });
    return response.data;
  } catch (error) {
    throw error.response?.data || "Error logging in";
  }
};

export const logOut = async (email, terminal_id, token) => {
  try {
    const response = await api.post(
      "users/log_out",
      { email, terminal_id },
      {
        headers: {
          TOKEN: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || "Error logging out";
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await api.post("users/forgot_password", { email });
    return response.data;
  } catch (error) {
    throw (
      error.response?.data ||
      "This email address is not associated with any account"
    );
  }
};

export const resetPassword = async (email, new_password, token) => {
  try {
    const response = await api.post(
      "users/update_password",
      { email, new_password },
      {
        headers: {
          TOKEN: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || "Error resetting password";
  }
};
