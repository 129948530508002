import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getPrinterQueues,
  getPrinterQueue,
  addPrinterQueue,
  updatePrinterQueue,
  deletePrinterQueue,
} from '../api/printer_queues';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchPrinterQueues = createAsyncThunk('printerQueues/fetchPrinterQueues', async ({ token }) => {
  const response = await getPrinterQueues(token);
  return response.data;
});

export const fetchPrinterQueue = createAsyncThunk('printerQueues/fetchPrinterQueue', async ({ id, token }) => {
  const response = await getPrinterQueue(id, token);
  return response.data;
});

export const addNewPrinterQueue = createAsyncThunk(
  'printerQueues/addPrinterQueue',
  async ({ newLoyaltyProgramDetails, token }, { dispatch }) => {
    try {
      const response = await addPrinterQueue(newLoyaltyProgramDetails, token);
      dispatch(
        addNotification({
          message: 'Printer queue successfully created',
          status: 'succeeded',
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to create printer queue, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateExistingPrinterQueue = createAsyncThunk(
  'printerQueues/updatePrinterQueue',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updatePrinterQueue(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Printer queue successfully updated',
          status: 'succeeded',
        }),
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to update printer queue, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removePrinterQueue = createAsyncThunk(
  'printerQueues/removePrinterQueue',
  async ({ id, token }, { dispatch }) => {
    try {
      await deletePrinterQueue(id, token);
      dispatch(
        addNotification({
          message: 'Printer queue successfully deleted',
          status: 'succeeded',
        }),
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to delete printer queue, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

const initialState = {
  printerQueues: [],
  currentPinterQueue: {},
  queryDetails: {},
  status: 'idle',
  error: null,
};

const printerQueuesSlice = createSlice({
  name: 'printerQueues',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action?.error.message;
    };

    builder
      .addCase(fetchPrinterQueues.pending, setLoading)
      .addCase(fetchPrinterQueues.fulfilled, (state, action) => {
        setSucceeded(state);
        state.pinterQueues = action.payload;
      })
      .addCase(fetchPrinterQueues.rejected, setFailed)

      .addCase(fetchPrinterQueue.pending, setLoading)
      .addCase(fetchPrinterQueue.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentPinterQueue = action.payload;
      })
      .addCase(fetchPrinterQueue.rejected, setFailed)

      .addCase(addNewPrinterQueue.pending, setLoading)
      .addCase(addNewPrinterQueue.fulfilled, (state, action) => {
        setSucceeded(state);
        state.pinterQueues.push(action.payload);
      })
      .addCase(addNewPrinterQueue.rejected, setFailed)

      .addCase(updateExistingPrinterQueue.pending, setLoading)
      .addCase(updateExistingPrinterQueue.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedDetails } = action.payload;
        const index = state.pinterQueues.findIndex((queue) => queue.id === updatedDetails.id);

        if (index !== -1) {
          state.pinterQueues[index] = updatedDetails;
        }
      })
      .addCase(updateExistingPrinterQueue.rejected, setFailed)

      .addCase(removePrinterQueue.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;
        state.pinterQueues = state.pinterQueues.filter((queue) => queue.id !== id);
      })
      .addCase(removePrinterQueue.pending, setLoading)
      .addCase(removePrinterQueue.rejected, setFailed);
  },
});

export const { setQueryDetails } = printerQueuesSlice.actions;
export default printerQueuesSlice.reducer;
