import { api, handleApiResponse } from "./base";

export const getBusinessInformation = (token) =>
  handleApiResponse(() =>
    api.get("web/locations", {
      headers: {
        TOKEN: token,
      },
    })
  );

export const updateBusinessInformation = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`web/locations/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    })
  );
