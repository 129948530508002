import SelectItem from './select-item';
import Button from '../../../../utilities/button/button';

import styles from './team-roles-modals.module.scss';

const AssignModal = ({ id, roleItems, onItemClick, onSaveChanges, assignType, assignName }) => {
  return (
    <div className={styles.modal_content}>
      <h3 className={styles.edit_title_modal}>Assign {assignName}s</h3>
      <div className={styles.edit_wrapper_modal}>
        <SelectItem
          roleId={id}
          items={roleItems.find((item) => item.id === id)[assignType]}
          itemName={assignName}
          category={assignType}
          handleItemClick={onItemClick}
        />
      </div>
      <Button title="Save Changes" onClick={() => onSaveChanges(id, 'assign')} />
    </div>
  );
};

export default AssignModal;
