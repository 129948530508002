import React from 'react';
import { Link } from 'react-router-dom';

const formatAmount = (amount) => {
    return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

const GiftCardPurchaseTable = ({ giftCardPurchases }) => {

    return (
        <table className="table table-bordered">
            <thead className="thead-light">
            <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Amount</th>
                <th>Number</th>
                <th>Purchase Date</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {giftCardPurchases && giftCardPurchases.length > 0 ? (
                giftCardPurchases.map((giftCardPurchase) => (
                    <tr key={giftCardPurchase.id}>
                        <td>{giftCardPurchase.first_name}</td>
                        <td>{giftCardPurchase.last_name}</td>
                        <td>${formatAmount(giftCardPurchase.amount)}</td>
                        <td>{giftCardPurchase.number}</td>
                        <td>{giftCardPurchase.purchase_date}</td>
                        <td>
                            <Link to={`/send_gift_card_sms_and_email/${giftCardPurchase.id}/email`} data-remote="true">
                                Send Email
                            </Link>
                            &nbsp; / &nbsp;
                            <Link to={`/send_gift_card_sms_and_email/${giftCardPurchase.id}/sms`} data-remote="true">
                                Send SMS
                            </Link>
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td className="text-center" colSpan="6">No Records found</td>
                </tr>
            )}
            </tbody>
        </table>
    );
};

export default GiftCardPurchaseTable;
