import { z } from 'zod';

export const registrationSchema = z
  .object({
    newPassword: z
      .string({ required_error: 'Please enter New password' })
      .min(5, { message: 'Password must be at least 5 characters long' })
      .regex(/^[a-zA-Z0-9]+$/, { message: 'Password must contain only English letters and Arabic numbers.' }),
    confirmPassword: z.string({ required_error: 'Please enter Confirm password' }),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  });
