import { api } from './base';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getPrinterQueues = (token) => {
  return handleApiResponse(() =>
    api.get('printer_queues', {
      headers: {
        TOKEN: token,
      },
    }),
  );
};

export const getPrinterQueue = (id, token) =>
  handleApiResponse(() =>
    api.get(`printer_queues/${id}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const addPrinterQueue = (printerQueueDetails, token) =>
  handleApiResponse(() =>
    api.post('printer_queues', printerQueueDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const updatePrinterQueue = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`printer_queues/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const deletePrinterQueue = (id, token) =>
  handleApiResponse(() =>
    api.delete(`printer_queues/${id}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );
