import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import HeaderReport from "../components/HeaderReport/header-report";
import clsx from "clsx";
import DateRange from "../components/DateRange/date-range";
import NoRecords from "../components/NoRecords/no-records";
import OrderItem from "./orderItem"
import FiltersForReports from "../components/OrderFilters/FiltersForReports";
import {fetchOrdersReport } from "../../../../redux/reportsSetSlice";
import {useAppContext} from "../../../../context/context";
import {useDispatch, useSelector} from "react-redux";
import usePagination from "../../business/order-types/usePagination";
import {DEFAULT_PAGINATION, formatDateTime, PAGE_SIZE_OPTIONS} from "../components/helper";
import {DropdownTypes} from "../components/OrderFilters";
import Pagination from "../../../utilities/pagination/pagination";

import styles from './orders.module.scss';

function Orders() {
    const { token } = useAppContext();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [search, setSearch] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [itemId, setItemId] = useState('');
    const [modifierId, setModifierId] = useState('');
    const [taxId, setTaxId] = useState('');

    const [dateFilter, setDateFilter] = useState('');

    const [selectedOrderId, setSelectedOrderId] = useState(null);

    const orders = useSelector((state) => state.reports.ordersReport);
    const selectedOrder = useSelector((state) => state.reports.orderDetails);
    const {
        currentPageItems,
        page,
        perPage,
        isPrevActive,
        isNextActive,
        handleSelectPageCount,
        handleClickPrev,
        handleClickNext,
    } = usePagination(orders, DEFAULT_PAGINATION.currentPage, DEFAULT_PAGINATION.itemsPerPage);

    useEffect(() => {
        dispatch(fetchOrdersReport({
            token,
            dateFilter,
            startDate: formatDateTime(startDate),
            endDate: formatDateTime(endDate),
            search,
            employeeId,
            categoryId,
            itemId,
            modifierId,
            taxId,
            page,
            perPage
        }));
    }, [startDate, endDate, page, perPage, search, employeeId, categoryId, itemId, modifierId, taxId, dateFilter]);

    const downloadPdf = () => {};
    const downloadCsv = () => {};

    const openOrderDetails = (order) => {
        if (order.id) {
           navigate(`/reporting/order-details/${order.id}`);
        }
    };

  return (
      <div className={styles.contentWrapper}>
          <HeaderReport
              title={"Orders"}
              downloadPdf={downloadPdf}
              downloadCsv={downloadCsv}
          />
          <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
                <FiltersForReports
                    searchShow={false}
                    dropdowns={
                        [
                            DropdownTypes.employee
                        ]
                    }
                    onUpdatedParams={(params) => {
                        if (params.hasOwnProperty('employee_id')) setEmployeeId(params.employee_id);
                    }}
                />
          </div>
          <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
              <DateRange
                  endDate={endDate}
                  startDate={startDate}
                  showPreset={true}
                  onChange={(data) => {
                      setStartDate(data.startDate);
                      setEndDate(data.endDate);
                  }}
              />
          </div>
          <div className="row">
              <table className="table table-bordered">
                  <thead className="thead-light">
                      <tr>
                          <th>ID</th>
                          <th>Date</th>
                          <th>Customer</th>
                          <th>Employee</th>
                          <th>Total</th>
                          <th>Status</th>
                          <th>Details</th>
                      </tr>
                  </thead>
                  {orders && orders.length > 0 ? (
                      orders.map((order) => <OrderItem
                          order={order}
                          openOrderDetails={() => openOrderDetails(order)} />)
                  ) : <NoRecords/>}
              </table>
          </div>
          <Pagination
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              numberOfItems={orders?.length}
              currentPage={page}
              currentPageSize={perPage}
              onPageSizeChange={handleSelectPageCount}
              inputPaginationControl={{
                  isActive: true,
                  isPrevActive,
                  isNextActive,
              }}
              onPrev={handleClickPrev}
              onNext={handleClickNext}
          />
      </div>
  )
}

export default Orders;
