import React, {useState} from 'react';
import HeaderReport from "../components/HeaderReport/header-report";
import clsx from "clsx";
import {FiltersForReports} from "../components/OrderFilters";
import ReportTable from "./ReportTable";
import {DateRange} from "../components/DateRange";

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './gift-card-purchase.module.scss';


function GiftCardPurchase() {
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);

    const downloadPdf = () => {}
    const downloadCsv = () => {}

    const giftCardPurchases = [
        {
            id: 1,
            first_name: 'John',
            last_name: 'Doe',
            amount: 100,
            number: '1234567890',
            purchase_date: '2020-01-01'
        },
        {
            id: 2,
            first_name: 'Jane',
            last_name: 'Doe',
            amount: 200,
            number: '0987654321',
            purchase_date: '2020-01-02'
        },
        {
            id: 3,
            first_name: 'John',
            last_name: 'Smith',
            amount: 300,
            number: '1234567890',
            purchase_date: '2020-01-03'
        },
    ];

  return (
      <div className={styles.contentWrapper}>
          <HeaderReport
              title={"Export Gift Card Purchase"}
              downloadPdf={downloadPdf}
              downloadCsv={downloadCsv}
          />
          <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
              <FiltersForReports
                  dropdown={[]}
              />
          </div>
          <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
              <DateRange
                  endDate={endDate}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
              />
          </div>
          <ReportTable giftCardPurchases={giftCardPurchases} />
       </div>
    );
}

export default GiftCardPurchase;
