import {displayTwoDigitWithDelimiter} from '../components/helper';

export default function ReportTable({laborSummary}) {
    return (
        <table className="table table-bordered">
            <thead className="thead-light">
            <tr>
                <th>Employee Name</th>
                <th>Job Title</th>
                <th>Regular Hours</th>
                <th>Overtime Hours</th>
                <th>Regular Pay</th>
                <th>Overtime Pay</th>
                <th>Total Pay</th>
                <th>Sales</th>
                <th>Orders</th>
                <th>Guests</th>
                <th>Tips</th>
                <th>Gratuity</th>
                <th>Declared Tips</th>
                <th>Total Pay / Sales</th>
                <th>Total Sales / Employee Hour</th>
            </tr>
            </thead>
            <tbody id="labor_summary_body">
            {laborSummary && laborSummary.length > 0 ? (
                laborSummary.map((laborSummary, index) => (
                    <tr key={index}>
                        <td>{laborSummary[0]}</td>
                        <td>{laborSummary[1].job_title}</td>
                        <td>{laborSummary[1].regular_hours}</td>
                        <td>{laborSummary[1].overtime_hours}</td>
                        <td>${displayTwoDigitWithDelimiter(laborSummary[1].regular_pay)}</td>
                        <td>${displayTwoDigitWithDelimiter(laborSummary[1].overtime_pay)}</td>
                        <td>${displayTwoDigitWithDelimiter(laborSummary[1].total_pay)}</td>
                        <td>${displayTwoDigitWithDelimiter(laborSummary[1].sales)}</td>
                        <td>{laborSummary[1].orders}</td>
                        <td>{laborSummary[1].guests}</td>
                        <td>${displayTwoDigitWithDelimiter(laborSummary[1].tips)}</td>
                        <td>${displayTwoDigitWithDelimiter(laborSummary[1].gratuity)}</td>
                        <td>${displayTwoDigitWithDelimiter(laborSummary[1].declared_tips)}</td>
                        <td>${displayTwoDigitWithDelimiter(laborSummary[1].total_pay_per_sales)}</td>
                        <td>${displayTwoDigitWithDelimiter(laborSummary[1].total_sales_per_employee_hour)}</td>
                    </tr>
                ))) : (
                <tr>
                    <td className="text-center" colSpan="15">No Records found</td>
                </tr>
            )}
            </tbody>
        </table>
    );
}
