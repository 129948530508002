export default function ViewDate({date, timezone = 'America/New_York', locales = 'en-US'}) {
    return (
        <>
            {new Date(date).toLocaleString(locales, {
                timeZone: timezone,
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            })}
        </>
    );
}
