import React, { useState, useEffect } from "react";

import List from "../../../utilities/list/list";

import { IoTrash } from "react-icons/io5";
import styles from "./reporting-list.module.scss";

const EmailAddressesList = ({ emails, onDelete, isLoading, onEmailChange }) => {
  const [updatedEmails, setUpdatedEmails] = useState({});

  useEffect(() => {
    setUpdatedEmails(
      emails.reduce((acc, email) => ({ ...acc, [email.id]: email.email }), {})
    );
  }, [emails]);

  const handleUpdateEmailAddress = (id, newEmail) => {
    setUpdatedEmails((prevState) => ({
      ...prevState,
      [id]: newEmail,
    }));

    onEmailChange(id, newEmail);
  };

  const renderInput = (item) => (
    <input
      type="text"
      id={item.id}
      value={updatedEmails[item.id] || ""}
      placeholder="Add Email Address"
      onChange={(e) => handleUpdateEmailAddress(item.id, e.target.value)}
      className={styles.edit_email_input}
    />
  );

  const columnConfig = [
    {
      key: "email",
      header: "Email Addresses",
      render: renderInput,
    },
  ];

  const rowActions = [
    {
      icon: <IoTrash />,
      //header: "Action",
      onClick: onDelete,
      label: "Delete category",
      className: styles.row_actions_button,
    },
  ];

  return (
    <List
      data={emails}
      columnConfig={columnConfig}
      rowActions={rowActions}
      isLoading={isLoading}
    />
  );
};

export default EmailAddressesList;
