import { api, handleApiResponse } from "./base";


export const getDiscounts = (token) =>
  handleApiResponse(() =>
    api.get("discounts", {
      headers: {
        TOKEN: token,
      },
    })
  );

export const addDiscount = (newDiscountDetails, token) =>
  handleApiResponse(() =>
    api.post("discounts", newDiscountDetails, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const updateDiscount = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`discounts/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const deleteDiscount = (id, token) =>
  handleApiResponse(() =>
    api.delete(`discounts/${id}`, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const updateDiscountStatus = (id, is_active, token) =>
  handleApiResponse(() =>
    api.put(`discounts/${id}/active?is_active=${is_active}`, {
      headers: {
        TOKEN: token,
      },
    })
  );
