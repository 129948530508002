import React, {useState} from 'react';
import Button from '../../../utilities/button/button';

export default function TransactionsRange({payment_amount_min, payment_amount_max, onChange}) {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onChange({ [name]: value });
    };

    return (
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="form-inline d-sm-flex flex-wrap align-items-center">
                <p className="fs-14 pt-3 mt-lg-0">
                    Show transactions with total between:
                </p>
                <div className="relative input-group mb-0 ml-0 ml-sm-3 mt-2 mt-lg-0">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">$</span>
                    </div>
                    <input
                        type="number"
                        name="payment_amount_min"
                        value={payment_amount_min}
                        onChange={handleInputChange}
                        className="form-control value-amount fs-16"
                        step="0.01"
                        placeholder="From"
                    />
                </div>
                <p className="fs-14 px-3 pt-3 mt-lg-0 text-center">
                    and
                </p>
                <div className="relative input-group mb-0 d-flex align-items-center mt-2 mt-lg-0">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">$</span>
                    </div>
                    <input
                        type="number"
                        name="payment_amount_max"
                        value={payment_amount_max}
                        onChange={handleInputChange}
                        className="form-control value-amount fs-16"
                        step="0.01"
                        placeholder="To"
                    />
                </div>
                <Button title={"Update"} className="update-btn disabled btn btn-admin fs-16 btn-small min-w-auto ml-0 ml-sm-3 mt-2 mt-lg-0" />
            </div>
        </div>
    );
}
