import clsx from 'clsx';
import styles from './team-roles-modals.module.scss';

const SelectItem = ({ roleId, items = [], itemName = 'Item', category, handleItemClick }) => {
  return (
    <div className={styles.select_item}>
      <label>Select {itemName}:</label>
      <div className={styles.wrapper}>
        <div className={styles.item_list}>
          {items
            .slice()
            .sort((a, b) => a.id - b.id)
            .map((item) => (
              <div
                key={item.id}
                onClick={() => handleItemClick(roleId, item.id, category)}
                className={clsx(styles.item, {
                  [styles.item_selected]: item.selected,
                })}
              >
                {item.name}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SelectItem;
