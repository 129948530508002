import React from 'react';
import { FaCheck } from 'react-icons/fa';

import styles from './checkbox.module.scss';

const Checkbox = ({ item = { name: 'default', id: 0 }, label, handleOnChange, isChecked = false }) => {
  return (
    <div className={styles.checkbox_wrapper}>
      <input
        type="checkbox"
        id={`checkbox_${item.name + item.id}`}
        checked={isChecked}
        onChange={(e) => handleOnChange(item.id, e.target.checked)}
        className={styles.checkbox_input}
      />
      <label htmlFor={`checkbox_${item.name + item.id}`} className={styles.checkbox_label}>
        <span className={styles.custom_checkbox}>
          {isChecked && <FaCheck className={styles.check_icon} width="20px" />}
        </span>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
