import { IoTrash } from 'react-icons/io5';
import { MdModeEditOutline } from 'react-icons/md';
import Switch from '../../../utilities/switch/switch';
import List from '../../../utilities/list/list';

import styles from './order-types-list.module.scss';

const OrderTypesList = ({ orders, onEdit, onDelete, onToggleActive, isLoading }) => {
  const renderSwitcher = (item) => <Switch id={item.id} isActive={item.is_active} onSwitch={onToggleActive} />;

  const columnConfig = [
    {
      key: 'name',
      header: 'Order Type Name',
    },
    {
      key: 'order_type',
      header: 'Order Type',
    },
    {
      key: 'is_active',
      header: 'Active',
      render: renderSwitcher,
    },
  ];

  const rowActions = [
    { icon: <MdModeEditOutline />, onClick: onEdit, label: 'Edit employee', className: styles.row_actions_button },
    { icon: <IoTrash />, onClick: onDelete, label: 'Delete employee', className: styles.row_actions_button },
  ];

  return <List data={orders} columnConfig={columnConfig} rowActions={rowActions} isLoading={isLoading} />;
};

export default OrderTypesList;
