import React from "react";

import Checkbox from "../../../../utilities/checkbox/checkbox";
import List from "../../../../utilities/list/list";

import { IoTrash } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";

import styles from "./options-list.module.scss";


const OptionsList = ({
  items,
  //onEdit,
  onDelete,
  checkedOptionsIds,
  setCheckedOptionsIds,
}) => {

  const renderCheckbox = (item) => (
    <Checkbox
      item={item}
      handleOnChange={handleCheckboxChange}
      isChecked={checkedOptionsIds.includes(item.id)}
    />
  );

  const handleCheckboxChange = (id, value) => {
    setCheckedOptionsIds((prev) => {
      if (value) {
        return [...prev, id];
      } else {
        return prev.filter((item) => item !== id);
      }
    });
  };

/*   const renderPrice = (item) => (
    <span className={styles.variation_price}>${item.price}</span>
  ); */

  
  const columnConfig = [
    {
      key: "sort",
      header: "Status",
      render: renderCheckbox,
    },
    {
      key: "name",
      header: "Selected options",
    },
/*     {
      key: "sku",
      header: "SKU",
    }, */
/*     {
      key: "price",
      header: "Price",
      render: renderPrice,
    }, */
/*     {
      key: "quantity",
      header: "Stock",
    } */
  ];

  const rowActions = [
/*     {
      icon: <MdModeEditOutline />,
      onClick: onEdit,
      label: "Edit item",
      className: styles.variation_action,
    }, */
    {
      icon: <IoTrash />,
      onClick: onDelete,
      label: "Delete item",
      className: styles.variation_action,
    },
  ];

  let sortedItems = [];

  if(items && items.length) {
    sortedItems =  items.sort((a, b) => a.id - b.id);
  }
  

  return (
    <List
      data={sortedItems}
      columnConfig={columnConfig}
      rowActions={rowActions}
      noDataMessage={"No Records found."}
      className={styles.list_wrapper}
      style={{"marginTop": "10px"}}
    />
  );
};

export default OptionsList;
