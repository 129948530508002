import axios from 'axios';
import store from '../redux/store';
import { clearAuth } from '../redux/authSlice';

export const api = axios.create({
  baseURL: 'https://crunchposdataservice.azurewebsites.net/api/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 403 || error.response.status === 401) {
        store.dispatch(clearAuth());
        console.log(`${error.response.status}: ${error.response.data.message}`);
      }
    }
    return Promise.reject(error);
  },
);
