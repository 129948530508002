import React from 'react';

const TotalCashPayments = ({ shiftReportData }) => {
    shiftReportData = shiftReportData || {
        total_cash_payments: [
            {
                key: "Total Cash Payments",
                value: 0
            },
            {
                key: "Total Refund",
                value: 0
            }
        ]
    };
    const displayTwoDigitWithDelimiter = (value) => {
        return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    };

    return (
        <div>
            <h4 className="admin-main-title text-darkgray1 fw-700 text-center fs-20 py-3 bg-custom-gray border-bottom mb-4 pb-2">
                TOTAL CASH PAYMENTS
            </h4>
            <div className="admin-table bg-white table-responsive mt-4">
                <table className="table table-bordered">
                    <thead className="thead-dark">
                    <tr>
                        <th width="25%">Detail</th>
                        <th width="25%"></th>
                        <th width="25%"></th>
                        <th width="25%"></th>
                        <th width="25%">Amount</th>
                    </tr>
                    </thead>
                    <tbody className="eod_shift_report">
                    {shiftReportData.total_cash_payments && shiftReportData.total_cash_payments.length > 0 ? (
                        shiftReportData.total_cash_payments.map((data, index) => {
                            const klasss = data.key === "Total Cash Payments" ? "fw-700" : "";
                            return (
                                <tr key={index}>
                                    <td className={klasss}>
                                        {data.key}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className={`${klasss} ${data.key.includes("Refund") ? "text-red" : ""}`}>
                                        ${displayTwoDigitWithDelimiter(data.value)}
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td className="text-center" colSpan="4">No Records found</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TotalCashPayments;
