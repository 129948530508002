import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getWorkingHours,
  addWorkingHours,
  updateWorkingHours,
  deleteWorkingHours,
} from "../api/working_hours";

import { addNotification } from "./tooltipSlice";

// Thunks
export const fetchWorkingHours = createAsyncThunk(
  "hours/fetchHours",
  async (token) => {
    const response = await getWorkingHours(token);
    return response.data;
  }
);

export const createWorkingHours = createAsyncThunk(
  "hours/addHours",
  async ({ newDayDetails, token }, { dispatch }) => {
    try {
      const response = await addWorkingHours(newDayDetails, token);
      dispatch(
        addNotification({
          message: "Working hours were created successfully",
          status: "succeeded",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to create working hours",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

export const updateExistingWorkingHours = createAsyncThunk(
  "hours/updateExistingHours",
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateWorkingHours(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: "Working hours were updated successfully",
          status: "succeeded",
        })
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to update working hours",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

export const removeWorkingHours = createAsyncThunk(
  "hours/removeHours",
  async ({ id, token }, { dispatch }) => {
    try {
      await deleteWorkingHours(id, token);
      dispatch(
        addNotification({
          message: "Working hours were deleted successfully",
          status: "succeeded",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to delete working hours",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

const initialState = {
  working_hours: [],
  status: "idle",
  error: null,
};

const workingHoursSlice = createSlice({
  name: "hours",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = "loading";
    };

    const setSucceeded = (state) => {
      state.status = "succeeded";
    };

    const setFailed = (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    };

    builder
      .addCase(fetchWorkingHours.pending, setLoading)
      .addCase(fetchWorkingHours.fulfilled, (state, action) => {
        setSucceeded(state);
        state.working_hours = action.payload;
      })
      .addCase(fetchWorkingHours.rejected, setFailed)

      .addCase(createWorkingHours.pending, setLoading)
      .addCase(createWorkingHours.fulfilled, (state, action) => {
        setSucceeded(state);
        state.working_hours.push(action.payload);
      })
      .addCase(createWorkingHours.rejected, setFailed)

      .addCase(updateExistingWorkingHours.pending, setLoading)
      .addCase(updateExistingWorkingHours.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, updatedDetails } = action.payload;
        const existingWorkingHour = state.working_hours.find(
          (hour) => hour.id === id
        );
        if (existingWorkingHour) {
          Object.assign(existingWorkingHour, updatedDetails);
        }
      })
      .addCase(updateExistingWorkingHours.rejected, setFailed)

      .addCase(removeWorkingHours.fulfilled, (state, action) => {
        state.working_hours = state.working_hours.filter(
          (hour) => hour.id !== action.payload
        );
      });
  },
});

export default workingHoursSlice.reducer;
