import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../../../context/context';
import { fetchModifier, updateExistingModifier } from '../../../../redux/modifierSetsSlice';
import { addNotification } from '../../../../redux/tooltipSlice';

import ModifierForm from './forms/modifier-form';

import useFormValidation from '../../../utilities/useFormValidation';
import { registrationSchema } from './validation';

import PageTitle from '../../../utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Button from '../../../utilities/button/button';
import DynamicFormFields from '../../../utilities/dynamic-form-fields/dynamic-form-fields ';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import { isObjectEmpty, isArrayEmpty } from '../../../utilities/utils';

import clsx from 'clsx';
import styles from './modifiers.module.scss';

const getModifierSet = (modifiers, id) => {
  return modifiers.find((modifier) => modifier.id === parseFloat(id));
};

const EditModifierSet = () => {
  const { id } = useParams();
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const modifierSetsStatus = useSelector((state) => state.modifierSets.status);
  const modifierSets = useSelector((state) => state.modifierSets.modifierSets);
  const currentModifierSet = useSelector((state) => state.modifierSets.currentmodifierSet);
  const [modifier, setModifier] = useState(getModifierSet(modifierSets, id) || currentModifierSet);

  const { errors, validate } = useFormValidation(registrationSchema);

  const statusLoading = modifierSetsStatus === 'loading';

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (modifierSetsStatus === 'idle') {
          await dispatch(fetchModifier({ id, token })).unwrap();
        }
      } catch (error) {
        dispatch(addNotification({ message: `Error fetching data: ${error}`, status: 'failed' }));
      }
    };

    fetchData();
  }, [dispatch, token, id, modifierSetsStatus]);

  useEffect(() => {
    if (isObjectEmpty(modifier) && !isObjectEmpty(currentModifierSet)) {
      setModifier(currentModifierSet);
    }
  }, [currentModifierSet, modifier]);

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setModifier((prev) => {
        if (isArrayEmpty(value)) {
          const { [name]: _, ...rest } = prev;
          return rest;
        }
        return {
          ...prev,
          [name]: value,
        };
      });
    },
    [setModifier],
  );

  const memoizedOnChange = useCallback(
    (value) => {
      handleInputChange({ target: { value, name: 'modifiers' } });
    },
    [handleInputChange],
  );

  const handleCancel = () => {
    navigate('/menu/modifiers');
  };

  const handleSaveChanges = async () => {
    if (validate(modifier)) {
      try {
        await dispatch(updateExistingModifier({ id, updatedDetails: modifier, token })).unwrap();
        navigate('/menu/modifiers');
      } catch {}
    }
  };

  if (!modifier) {
    return null;
  }

  return (
    <section className={styles.option_edit_section}>
      <div className={styles.content}>
        <PageTitle name={'Edit Modifier'} />
        <div className={styles.form_container}>
          <div className={styles.fields}>
            {statusLoading ? <BackdropLoading /> : null}
            <div className={styles.row}>
              <div className={styles.field}>
                <Input
                  label={'Modifier Set Name'}
                  value={modifier.name}
                  onChange={handleInputChange}
                  name={'name'}
                  required={true}
                  errors={errors.name?._errors}
                />
              </div>
              <div className={clsx(styles.field, styles.block)}>
                <DynamicFormFields
                  value={modifier.modifiers}
                  label={'Modifiers'}
                  fieldItem={ModifierForm}
                  fieldType={'text'}
                  fieldProps={{ style: { margin: '0 auto' } }}
                  addButtonTitle={'Add Modifier'}
                  onChange={memoizedOnChange}
                  isDragable={true}
                  bottomLine={true}
                  rowClassName={'table_row'}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button className={styles.button} disabled={statusLoading} title={'Save'} onClick={handleSaveChanges} />
            <Button
              className={styles.button}
              variant={'grey'}
              disabled={statusLoading}
              title={'Cancel'}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditModifierSet;
