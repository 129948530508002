import React, { useState, useEffect } from 'react';

import { convertValueByType } from '../../../../utilities/utils';
import Input from '../../../../utilities/input/input';
import useDebounce from '../../../../utilities/useDebounce';

import styles from './option-form.module.scss';

const OptionForm = ({ data, onChange }) => {
  const [option, setOption] = useState(data ?? {});

  const debouncedAddress = useDebounce(option, 500);

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setOption((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  useEffect(() => {
    onChange(option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddress]);

  return (
    <div className={styles.section}>
      <Input type="text" name="name" placeholder="Name" value={option?.name} onChange={handleInputChange} />
      {/* // data-parsley-pattern="^[\d\+\-\.\(\)\/\s]*$"
      // data-parsley-pattern-message="Please enter only digit"
      // data-parsley-trigger="change focusout"
      // data-parsley-length="[14,14]"
      // data-parsley-length-message="Please enter 10 digits only"
      // data-parsley-required-message="Please enter Business Phone Number" */}
    </div>
  );
};

export default OptionForm;
