import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from '../../../context/context';
import { addNewCustomer } from '../../../redux/customersSlice';

import clsx from 'clsx';

import PageTitle from '../../utilities/page-title/page-title';
import Input from '../../utilities/input/input';
import Button from '../../utilities/button/button';
import DatePicker from '../../utilities/date-picker/date-picker';
import BackdropLoading from '../../utilities/backdrop-loading/backdrop-loading';
import DynamicFormFields from '../../utilities/dynamic-form-fields/dynamic-form-fields ';

import { registrationSchema } from './validation';
import useFormValidation from '../../utilities/useFormValidation';
import { transformToISOFormat, convertValueByType } from '../../utilities/utils';

import AddressForm from './forms/addresses/adrdess-form';
import PhonesForm from './forms/phones/phones-form';

import styles from './customers.module.scss';

const AddCustomer = () => {
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customersStatus = useSelector((state) => state.customers.status);
  const [customer, setCustomer] = useState({});

  const { errors, validate } = useFormValidation(registrationSchema);

  const statusLoading = customersStatus === 'loading';

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setCustomer((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  const handleSelectDate = (date, fieldName) => {
    const result = transformToISOFormat(date, true);
    setCustomer((prev) => ({
      ...prev,
      [fieldName]: result,
    }));
  };

  const handleCancel = () => {
    navigate('/customers');
  };

  const handleSaveChanges = async () => {
    if (validate(customer)) {
      try {
        await dispatch(
          addNewCustomer({
            newCustomerDetails: {
              ...customer,
              addresses_attributes: customer.addresses_attributes.map(({ id, ...rest }) => rest),
              phones_attributes: customer.phones_attributes.map(({ id, ...rest }) => rest),
            },
            token,
          }),
        ).unwrap();
        navigate('/customers');
      } catch {}
    }
  };

  const handlePhoneChange = useCallback((fields) => {
    handleInputChange({
      target: { name: 'phones_attributes', value: fields },
    });
  }, []);

  // const handleBillingAddressChange = useCallback(
  //   (billingFields = []) => {
  //     const deliveryFields = customer.addresses_attributes?.filter((a) => a.type_of_address === 'Shipping') || [];

  //     handleInputChange({
  //       target: {
  //         name: 'addresses_attributes',
  //         value: [...deliveryFields, ...billingFields],
  //       },
  //     });
  //   },
  //   [customer.addresses_attributes],
  // );

  const handleDeliveryAddressChange = useCallback(
    (deliveryFields = []) => {
      const billingFields = customer.addresses_attributes?.filter((a) => a.type_of_address === 'Billing') || [];

      handleInputChange({
        target: {
          name: 'addresses_attributes',
          value: [...billingFields, ...deliveryFields],
        },
      });
    },
    [customer.addresses_attributes],
  );

  return (
    <section className={styles.customer_edit_section}>
      <div className={styles.content}>
        <PageTitle name={'Add Customer'} />
        <div className={styles.form_container}>
          <div className={styles.fields}>
            {statusLoading ? <BackdropLoading /> : null}
            <div className={clsx(styles.name_fields, styles.block)}>
              <div className={styles.field}>
                <Input
                  label={'First name'}
                  value={customer.first_name}
                  onChange={handleInputChange}
                  name={'first_name'}
                  required={true}
                  errors={errors.first_name?._errors}
                />
              </div>
              <div className={styles.field}>
                <Input
                  label={'Last name'}
                  value={customer.last_name}
                  onChange={handleInputChange}
                  name={'last_name'}
                  errors={errors.last_name?._errors}
                />
              </div>
            </div>
            <div className={clsx(styles.field, styles.block)}>
              <DynamicFormFields
                value={customer.phones_attributes}
                label={'Phone Numbers'}
                fieldItem={PhonesForm}
                fieldType={'text'}
                fieldProps={{ style: { margin: '0 auto' } }}
                maxFields={1}
                addButtonTitle={'Add Phone'}
                onChange={handlePhoneChange}
                className={styles.phone_container}
                bottomLine={true}
              />
            </div>
            <div className={clsx(styles.field, styles.block)}>
              <Input
                label={'Email Address'}
                value={customer.email}
                onChange={handleInputChange}
                name={'email'}
                required={true}
                errors={errors.email?._errors}
              />
            </div>
            <div className={clsx(styles.field, styles.block)}>
              <DynamicFormFields
                value={customer.addresses_attributes?.filter((a) => a.type_of_address === 'Shipping')}
                label={'Delivery Address'}
                fieldItem={AddressForm}
                fieldType={'text'}
                fieldProps={{ style: { margin: '0 auto' } }}
                addItemAttributes={{ type_of_address: 'Shipping' }}
                addButtonTitle={'Add Address'}
                onChange={handleDeliveryAddressChange}
                bottomLine={true}
                errors={errors?.addresses_attributes}
              />
            </div>
            {/* <div className={clsx(styles.field, styles.block)}>
              <DynamicFormFields
                value={customer.addresses_attributes?.filter((a) => a.type_of_address === 'Billing')}
                label={'Billing Address'}
                fieldItem={AddressForm}
                fieldType={'text'}
                fieldProps={{ style: { margin: '0 auto' } }}
                addItemAttributes={{ type_of_address: 'Billing' }}
                addButtonTitle={'Add Address'}
                onChange={handleBillingAddressChange}
                bottomLine={true}
                errors={errors?.addresses_attributes}
              />
            </div> */}
            <div className={clsx(styles.field, styles.block)}>
              <Input label={'Company'} value={customer.company} onChange={handleInputChange} name={'company'} />
            </div>
            <div className={clsx(styles.field, styles.block)}>
              <DatePicker
                selected={customer.birth_date}
                label="Birthday"
                name="birth_date"
                onChange={(date) => handleSelectDate(date, 'birth_date')}
                dateFormat="yyyy-MM-dd"
                className={styles.datepicker}
                placeholderText="Select a date"
                errors={errors.birth_date?._errors}
                wrapperClassName={styles.picker_wrapper}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              disabled={statusLoading}
              title={'Save Customer'}
              onClick={handleSaveChanges}
            />
            <Button
              className={styles.button}
              variant={'grey'}
              disabled={statusLoading}
              title={'Cancel'}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddCustomer;
