import React from "react";
import {displayTwoDigitWithDelimiter} from "../components/helper";
import ViewDate from "../components/view-date";

export default function ReportTable({orderItems, invoiceItems}) {

    const orderItemModifierDetail = (orderItem) => [{ name: 'Modifier 1', quantity: 1, amount: 5 }];

    const invoiceItemModifierDetail = (invoiceItem) => [{ name: 'Modifier 2', amount: 10 }];

    return (
        <table className="table table-bordered">
            <thead className="thead-light">
            <tr>
                <th>Created</th>
                <th>Item Name</th>
                <th>Price</th>
                <th>Category</th>
                <th>Modifiers</th>
                <th>Discounts</th>
                <th>Tax</th>
                <th>Order</th>
            </tr>
            </thead>
            <tbody className="cash_discounts_list">
            {(orderItems.length > 0 || (invoiceItems && invoiceItems.length > 0)) ? (
                <>
                    {orderItems.length > 0 && orderItems.map(orderItem => (
                        <tr key={orderItem.id}>
                            <td>
                                <div className="connected-printer text-left">
                                    <h6 className="text-gray2 fs-14 fw-600">
                                        <ViewDate date={orderItem.created_at} />
                                    </h6>
                                    <p>ID: {orderItem.id}</p>
                                </div>
                            </td>
                            <td>{orderItem.item_name} x {orderItem.quantity || 0}</td>
                            <td>${displayTwoDigitWithDelimiter(orderItem.price)}</td>
                            <td>{orderItem.category?.name || 'N/A'}</td>
                            <td>
                                {orderItemModifierDetail(orderItem).length > 0 ? (
                                    orderItemModifierDetail(orderItem).map((itemModifier, index) => (
                                        <div key={index}>
                                            <p className="fs-16">{itemModifier.name} x {itemModifier.quantity}</p>
                                            <span className="fs-14 text-gray">${displayTwoDigitWithDelimiter(itemModifier.amount)}</span>
                                        </div>
                                    ))
                                ) : (
                                    '-'
                                )}
                            </td>
                            <td>${displayTwoDigitWithDelimiter(orderItem.discount_amount)}</td>
                            <td>${displayTwoDigitWithDelimiter(orderItem.order_item_taxes.reduce((sum, tax) => sum + tax.tax_total_amount, 0))}</td>
                            <td>
                                <div>
                                    {orderItem.order_id && <a href={`/order_details?order_id=${orderItem.order_id}`} className="text-blue1 text-underline">Order</a>}
                                </div>
                            </td>
                        </tr>
                    ))}
                    {invoiceItems.length > 0 && invoiceItems.map(invoiceItem => (
                        <tr key={invoiceItem.id}>
                            <td>
                                <div className="connected-printer text-left">
                                    <h6 className="text-gray2 fs-14 fw-600">
                                        <ViewDate date={invoiceItem.created_at} />
                                    </h6>
                                    <p>ID: {invoiceItem.id}</p>
                                </div>
                            </td>
                            <td>{invoiceItem.item_name}</td>
                            <td>${displayTwoDigitWithDelimiter(invoiceItem.total_price)}</td>
                            <td>{invoiceItem.category?.name}</td>
                            <td>
                                {invoiceItemModifierDetail(invoiceItem).length > 0 ? (
                                    invoiceItemModifierDetail(invoiceItem).map((itemModifier, index) => (
                                        <div key={index}>
                                            <p className="fs-16">{itemModifier.name}</p>
                                            <span className="fs-14 text-gray">${displayTwoDigitWithDelimiter(itemModifier.amount)}</span>
                                        </div>
                                    ))
                                ) : (
                                    '-'
                                )}
                            </td>
                            <td>${displayTwoDigitWithDelimiter(invoiceItem.discount_amount)}</td>
                            <td>${displayTwoDigitWithDelimiter(invoiceItem.ordered_item_taxes.reduce((sum, tax) => sum + tax.tax_total_amount, 0))}</td>
                            <td>
                                <div>
                                    {invoiceItem.invoice && (
                                        <a href={`/receipt_invoice/${invoiceItem.invoice.id}`} className="fs-14 change-default text-blue1 text-underline" target="_blank" rel="noopener noreferrer">
                                            <i className="fas fa-file-alt mr-2"></i>View Invoice Receipt
                                        </a>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </>
            ) : (
                <tr>
                    <td className="text-center" colSpan="7">No Records found</td>
                </tr>
            )}
            </tbody>
        </table>
    );
}
