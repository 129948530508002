import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { useAppContext } from '../../../../context/context';

import {
  fetchServiceCharges,
  createServiceCharge,
  updateExistingServiceCharge,
  removeServiceCharge,
  updateExistingChargeStatus,
} from '../../../../redux/serviceChargesSlice';

import useFormValidation from '../../../utilities/useFormValidation';
import { registrationSchema } from './validation';

import Input from '../../../utilities/input/input';
import Button from '../../../utilities/button/button';
import Checkbox from '../../../utilities/checkbox/checkbox';

import styles from './service-charges.module.scss';

const ServiceChargeForm = ({
  id,
  typeName,
  initialName = '',
  initialRate = '',
  initialStatus = false,
  initialMinGuestCount,
  initialMaxGuestCount,
  isFormForCreating = false,
  setFormForNewCharge = () => {},
}) => {
  const dispatch = useDispatch();

  const { token, locationId } = useAppContext();

  const [name, setName] = useState(initialName);
  const [rate, setRate] = useState(initialRate);
  const [status, setStatus] = useState(initialStatus);
  const [minGuest, setMinGuest] = useState(initialMinGuestCount);
  const [maxGuest, setMaxGuest] = useState(initialMaxGuestCount);
  const [formStatus, setFormStatus] = useState(initialStatus);
  const [redactedTypeName, setRedactedTypeName] = useState(
    typeName === 'TakeOutAndParkOrder' ? 'Take Out & Open Order' : typeName,
  );

  const { errors, validate } = useFormValidation(registrationSchema);

  useEffect(() => {
    setName(initialName);
    setRate(initialRate);
    setStatus(initialStatus);
    setMinGuest(initialMinGuestCount);
    setMaxGuest(initialMaxGuestCount);
  }, [initialName, initialRate, initialStatus, initialMinGuestCount, initialMaxGuestCount]);

  const handleCheckboxChange = useCallback(() => {
    dispatch(updateExistingChargeStatus({ id, is_enabled: !status, token }));
    setStatus((prevStatus) => {
      dispatch(fetchServiceCharges(token));
      return !prevStatus;
    });
  }, [dispatch, id, status, token]);

  const handleCheckboxSwitch = () => {
    setFormStatus((prevStatus) => !prevStatus);
  };

  const chargeDetails = {
    name: name,
    percentage: +rate,
    is_enabled: status,
    min_guest_count: +minGuest,
    max_guest_count: +maxGuest,
    location_id: locationId,
    order_type: typeName,
  };

  const handleSave = () => {
    const { min_guest_count, max_guest_count, ...rest } = chargeDetails;

    if (validate(rest)) {
      dispatch(
        updateExistingServiceCharge({
          id,
          updatedDetails: rest,
          token,
        }),
      );
    }
  };

  const handleCreateNewCharge = () => {
    if (validate(chargeDetails)) {
      dispatch(
        createServiceCharge({
          newChargeDetails: chargeDetails,
          token: token,
        }),
      );
      setFormForNewCharge([]);
    }
  };

  const handleDelete = useCallback(() => {
    dispatch(removeServiceCharge({ id, token }));
  }, [dispatch, id, token]);

  return (
    <form className={styles.form}>
      {typeName !== 'DineIn' && <h4 className={styles.subtitle}>Setup Service Charge for {redactedTypeName}</h4>}

      <div className={styles.form_fields}>
        <div className={styles.checkbox_wrapper}>
          <Checkbox
            item={{ name: 'enable_for', id }}
            label={`Enable Service Charge for ${redactedTypeName}`}
            isChecked={isFormForCreating ? formStatus : status}
            handleOnChange={isFormForCreating ? handleCheckboxSwitch : handleCheckboxChange}
          />
        </div>

        <div className={styles.inputs}>
          <div className={styles.input_wrapper}>
            <Input
              type="text"
              name="name"
              label="Service Charge Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              errors={errors.name?._errors}
            />
          </div>
          <div className={styles.input_wrapper}>
            <Input
              type="number"
              name="percentage"
              label="Service Rate (%)"
              value={rate}
              onChange={(e) => setRate(e.target.value)}
              required
              errors={errors.percentage?._errors}
            />
          </div>
          {typeName === 'DineIn' && (
            <>
              <div className={styles.input_wrapper}>
                <Input
                  type="number"
                  name="min_guest_count"
                  label="Min guest"
                  value={minGuest}
                  onChange={(e) => setMinGuest(e.target.value)}
                  // required
                  placeholder="100000"
                  errors={errors.min_guest_count?._errors}
                />
              </div>
              <div className={styles.input_wrapper}>
                <Input
                  type="number"
                  name="max_guest_count"
                  label="Max guest"
                  value={maxGuest}
                  onChange={(e) => setMaxGuest(e.target.value)}
                  // required
                  placeholder="500000"
                  errors={errors.max_guest_count?._errors}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles.buttons_wrapper}>
          <Button title="Save" type="button" onClick={isFormForCreating ? handleCreateNewCharge : handleSave} />
          {typeName === 'DineIn' && !isFormForCreating && (
            <Button title="Delete" type="button" onClick={handleDelete} />
          )}
        </div>
      </div>
    </form>
  );
};

ServiceChargeForm.propTypes = {
  id: PropTypes.number,
  typeName: PropTypes.string,
  initialName: PropTypes.string,
  initialRate: PropTypes.number,
  initialStatus: PropTypes.bool,
  initialMinGuestCount: PropTypes.number,
  initialMaxGuestCount: PropTypes.number,
  setFormForNewCharge: PropTypes.func,
  isFormForCreating: PropTypes.bool,
};

export default ServiceChargeForm;
