import React, { useEffect, useState } from 'react';
import Input from '../../../../utilities/input/input';
import Selector from '../../../../utilities/selector/selector';
import { IoIosArrowDown } from 'react-icons/io';

import { convertValueByType } from '../../../../utilities/utils';
import useDebounce from '../../../../utilities/useDebounce';

import styles from './address-form.module.scss';

const COUNTRY_OPTIONS = [
  { value: '1', label: 'USA' },
  { value: '2', label: 'UK' },
];

const AddressForm = ({ data, onChange, errors = {} }) => {
  const [address, setAddress] = useState(data ?? {});

  const debouncedAddress = useDebounce(address, 500);

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setAddress((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  useEffect(() => {
    onChange(debouncedAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddress]);

  return (
    <div className={styles.section}>
      <label className={styles.label}>Address</label>
      <div className={styles.field}>
        <Selector
          name="country"
          items={COUNTRY_OPTIONS}
          icon={IoIosArrowDown}
          placeholder={'Select Country'}
          selectedItem={address?.country}
          onSelectItem={(value) => handleInputChange({ target: { value, name: 'country' } }, 'text')}
        />
      </div>
      <div className={styles.field}>
        <Input
          type="text"
          name="address1"
          placeholder="Street"
          value={address?.address1}
          onChange={handleInputChange}
          errors={errors.address1?._errors}
        />
      </div>
      <div className={styles.field}>
        <Input
          type="text"
          name="address2"
          placeholder="Apt/Suit"
          value={address?.address2}
          onChange={handleInputChange}
          errors={errors.address2?._errors}
        />
      </div>
      <div className={styles.additional_info}>
        <div className={styles.field}>
          <Input
            type="text"
            name="city"
            placeholder="City"
            value={address?.city}
            onChange={handleInputChange}
            errors={errors.city?._errors}
          />
        </div>
        <div className={styles.field}>
          <Input
            type="text"
            name="state"
            placeholder="State"
            value={address?.state}
            onChange={handleInputChange}
            errors={errors.state?._errors}
          />
        </div>
        <div className={styles.field}>
          <Input
            type="text"
            name="postcode"
            placeholder="ZipCode"
            value={address?.postcode}
            onChange={handleInputChange}
            errors={errors.postcode?._errors}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
