import React from 'react';
import {displayTwoDigitWithDelimiter} from "../components/helper";
import icInfo from "./../../../../assets/svg/ic_info.svg";

export default function ReportTable ({salesReport}) {
    return (
        <>
            <div className="box p-3">
                <div className="admin-table table-striped-main bg-white table-responsive">
                    <table className="table fs-14 table-striped table-striped-reverse">
                        <tbody>
                        <tr>
                            <td className="text-left fw-600">Gross Sales
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right"
                                     title="Total amount of item sales, including extras such as modifiers. This is the gross subtotal before discounts and refunds. Does not include tips, taxes, service charges."/>
                            </td>
                            <td className="text-right fw-600">${displayTwoDigitWithDelimiter(salesReport.gross_sale)}</td>
                        </tr>
                        <tr>
                            <td className="text-left">Discounts
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right"
                                     title="Total amount of discounts applied to orders and/or individual items. Does not include refunded discounts."/>
                            </td>
                            <td className="text-right text-red">-${displayTwoDigitWithDelimiter(salesReport.discount)}</td>
                        </tr>
                        <tr>
                            <td className="text-left">Refunds
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right"
                                     title="Total amount of refunds, including manual refunds, applied against orders and/or individual items. Does not include voided payments."/>
                            </td>
                            <td className="text-right text-red">${displayTwoDigitWithDelimiter(salesReport.refund)}</td>
                        </tr>
                        <tr>
                            <td className="text-left">Wastage</td>
                            <td className="text-right text-red">-${displayTwoDigitWithDelimiter(salesReport.wastage)}</td>
                        </tr>
                        <tr>
                            <td className="text-left fw-600">Net Sales
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right"
                                     title="Amount of item sales remaining after all discounts, refunds have been deducted. Does not include tips, taxes, service charges."/>
                            </td>
                            <td className="text-right fw-600">${displayTwoDigitWithDelimiter(salesReport.net_sales + (salesReport.tax_refunded + salesReport.service_charge_refunded))}</td>
                        </tr>
                        <tr>
                            <td className="text-left">Taxes & Fees
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right"
                                     title="Total amount of taxes and flat fees collected on all items revenue."/>
                            </td>
                            <td className="text-right">${displayTwoDigitWithDelimiter(salesReport.total_tax)}</td>
                        </tr>
                        <tr>
                            <td className="text-left">Refunded Tax & Fees
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right" title="Total Tax & Fees Refunded."/>
                            </td>
                            <td className="text-right text-red">-${displayTwoDigitWithDelimiter(salesReport.tax_refund)}</td>
                        </tr>
                        <tr>
                            <td className="text-left fw-600">Tips
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right"
                                     title="Total amount of tips collected, after tips that were refunded. Does not include service charges or other fees. Also referred to as 'Net Tips'."/>
                            </td>
                            <td className="text-right fw-600">${displayTwoDigitWithDelimiter(salesReport.total_tips)}</td>
                        </tr>
                        <tr>
                            <td className="text-left">Refunded Tips
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right" title="Total tips Refunded."/>
                            </td>
                            <td className="text-right text-red">-${displayTwoDigitWithDelimiter(salesReport.tips_refund)}</td>
                        </tr>
                        <tr>
                            <td className="text-left">Service Charges
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right"
                                     title="Total amount of service charges. Does not include tips."/>
                            </td>
                            <td className="text-right">${displayTwoDigitWithDelimiter(salesReport.total_service_charge)}</td>
                        </tr>
                        <tr>
                            <td className="text-left">Service Charges Refunded
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right"
                                     title="Total amount of service charges that were refunded."/>
                            </td>
                            <td className="text-right text-red">-${displayTwoDigitWithDelimiter(salesReport.service_charge_refund)}</td>
                        </tr>
                        <tr>
                            <td className="text-left">Loyalty Rewards</td>
                            <td className="text-right text-red">-${displayTwoDigitWithDelimiter(salesReport.loyalty_rewards)}</td>
                        </tr>
                        {salesReport.total_cash_discount > 0.0 && (
                            <tr>
                                <td className="text-left">Non-Cash Adjustments</td>
                                <td className="text-right text-red">-${displayTwoDigitWithDelimiter(salesReport.total_cash_discount)}</td>
                            </tr>
                        )}
                        {salesReport.total_surcharge > 0.0 && (
                            <tr>
                                <td className="text-left">Sur Charge</td>
                                <td className="text-right">${displayTwoDigitWithDelimiter(salesReport.total_surcharge)}</td>
                            </tr>
                        )}
                        <tr>
                            <td className="text-left fw-600">Amount Collected
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right"
                                     title="The final sale amount collected including taxes, tips, Sur Charge, Non-Cash Adjustments and service charges."/>
                            </td>
                            <td className="text-right fw-600">${displayTwoDigitWithDelimiter(salesReport.total_amount_collected)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <h5 className="text-center">Tax Summary</h5>
            <div style={{minHeight: '50px'}} className="box p-3">
                <div className="admin-table table-striped-main bg-white table-responsive">
                    <table className="table fs-14 table-striped table-striped-reverse">
                        <tbody>
                        <tr>
                            <td className="text-left fw-600">Tax Name</td>
                            <td className="text-left fw-600">Taxable Amount</td>
                            <td className="text-left fw-600">Tax Rate or Fee</td>
                            <td className="text-left fw-600">Tax Collected</td>
                        </tr>
                        {salesReport.tax_summary[0].map((taxRecord, index) => (
                            <tr key={index}>
                                <td className="text-left fw-400">{taxRecord[0]}</td>
                                <td className="text-left fw-400">${displayTwoDigitWithDelimiter(taxRecord[1][3])}</td>
                                <td className="text-left fw-400">
                                    {taxRecord[1][2] === "Percentage" ? (
                                        `${displayTwoDigitWithDelimiter(taxRecord[1][1])}%`
                                    ) : (
                                        `$${displayTwoDigitWithDelimiter(taxRecord[1][1])}`
                                    )}
                                </td>
                                <td className="text-left fw-400">${displayTwoDigitWithDelimiter(taxRecord[1][0])}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <h5 className="text-center">Discount Summary</h5>
            <div style={{minHeight: '200px'}} className="box p-3">
                <div className="admin-table table-striped-main bg-white table-responsive">
                    <table className="table fs-14 table-striped table-striped-reverse">
                        <tbody>
                        <tr>
                            <td className="text-left fw-600">Discount Name</td>
                            <td className="text-right fw-600">Discount Amount</td>
                        </tr>
                        {salesReport.discount_summary.map((discountRecord, index) => (
                            <tr key={index}>
                                <td className="text-left fw-400">{discountRecord[0]}</td>
                                <td className="text-right fw-400">${displayTwoDigitWithDelimiter(discountRecord[1])}</td>
                            </tr>
                        ))}
                        <tr>
                            <td className="text-left fw-600">
                                Total Discounts
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right"
                                     title="Total amount of discounts applied to orders and/or individual items. Does not include refunded discounts."/>
                            </td>
                            <td className="text-right text-red">-${displayTwoDigitWithDelimiter(salesReport.discount)}</td>
                        </tr>
                        <tr>
                            <td className="text-left fw-600">
                                Gross Sales
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right"
                                     title="Total amount of item sales, including extras such as modifiers. This is the gross subtotal before discounts and refunds. Does not include tips, taxes, service charges."/>
                            </td>
                            <td className="text-right fw-600">${displayTwoDigitWithDelimiter(salesReport.gross_sale)}</td>
                        </tr>
                        <tr>
                            <td className="text-left fw-600">
                                Net Sales
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right"
                                     title="Total amount of refunds, including manual refunds, applied against orders and/or individual items. Does not include voided payments."/>
                            </td>
                            <td className="text-right">${displayTwoDigitWithDelimiter(salesReport.gross_sale - salesReport.discount)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={{minHeight: '200px'}} className="box p-3">
                <div className="admin-table table-striped-main bg-white table-responsive">
                    <table className="table fs-14 table-striped table-striped-reverse">
                        <tbody>
                        <tr>
                            <td className="text-left fw-400">Cash Before Tipouts
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right" title="Total amount collected by cash."/>
                            </td>
                            <td className="text-right fw-400">${displayTwoDigitWithDelimiter(salesReport.cash_before_tipouts)}</td>
                        </tr>
                        <tr>
                            <td className="text-left fw-400">Cash Service Charge
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right" title="Total amount of service charge collected by cash."/>
                            </td>
                            <td className="text-right fw-400">${displayTwoDigitWithDelimiter(salesReport.cash_service_charge)}</td>
                        </tr>
                        <tr>
                            <td className="text-left fw-400">Credit/Non-cash Service Charge
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right" title="Total amount of service charge collected by card."/>
                            </td>
                            <td className="text-right fw-400">${displayTwoDigitWithDelimiter(salesReport.card_service_charge)}</td>
                        </tr>
                        <tr>
                            <td className="text-left fw-400">Credit/Non-cash Tips
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right" title="Total amount of tips collected by card."/>
                            </td>
                            <td className="text-right fw-400">${displayTwoDigitWithDelimiter(salesReport.credit_tips)}</td>
                        </tr>
                        <tr>
                            <td className="text-left fw-600">Total Cash
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right"
                                     title="Total amount of cash after exclude cash and non-cash service charge, non-cash tips."/>
                            </td>
                            <td className="text-right fw-600">${displayTwoDigitWithDelimiter(salesReport.total_cash)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div style={{minHeight: '200px'}} className="box p-3">
                <div className="admin-table table-striped-main bg-white table-responsive">
                    <table className="table fs-14 table-striped table-striped-reverse">
                        <tbody>
                        <tr>
                            <td className="text-left fw-600"></td>
                            <td className="text-left fw-600">Count</td>
                            <td className="text-left fw-600">Amount(Tax + SC)</td>
                            <td className="text-left fw-600">Tips</td>
                            <td className="text-left fw-600">Service Charge</td>
                            <td className="text-left fw-600">Tips/Grat %</td>
                            <td className="text-left fw-600">Refunds</td>
                            <td className="text-left fw-600">Total</td>
                        </tr>
                        {salesReport.payment_data.map((record, index) => {
                            const klass = record[0].value === "Total" ? "fw-600" : "fw-400";
                            return (
                                <tr key={index}>
                                    <td className={`text-left ${klass}`}>{record[0].value}</td>
                                    <td className={`text-left ${klass}`}>{record[1].value}</td>
                                    <td className={`text-left ${klass}`}>${displayTwoDigitWithDelimiter(record[2].value)}</td>
                                    <td className={`text-left ${klass}`}>${displayTwoDigitWithDelimiter(record[3].value)}</td>
                                    <td className={`text-left ${klass}`}>${displayTwoDigitWithDelimiter(record[4].value)}</td>
                                    <td className={`text-left ${klass}`}>{record[5].value}</td>
                                    <td className={`text-left ${klass}`}>${displayTwoDigitWithDelimiter(record[6].value)}</td>
                                    <td className={`text-left ${klass}`}>${displayTwoDigitWithDelimiter(record[7].value)}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>

            <div style={{minHeight: '200px'}} className="box p-3">
                <div className="admin-table table-striped-main bg-white table-responsive">
                    <table className="table fs-14 table-striped table-striped-reverse">
                        <tbody>
                        <tr>
                            <td className="text-left fw-400">Total Guests
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right" title="Total number of guest."/>
                            </td>
                            <td className="text-right fw-400">{salesReport.total_guests}</td>
                        </tr>
                        <tr>
                            <td className="text-left fw-400">Avg/Guest
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right" title="Average amount spent by a Guest."/>
                            </td>
                            <td className="text-right fw-400">${displayTwoDigitWithDelimiter(salesReport.avg_per_guest)}</td>
                        </tr>
                        <tr>
                            <td className="text-left fw-400">Total Payments
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right" title="Total number of payments."/>
                            </td>
                            <td className="text-right fw-400">{salesReport.total_payments}</td>
                        </tr>
                        <tr>
                            <td className="text-left fw-400">Avg/Payment
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right" title="Average amount collected in a payment."/>
                            </td>
                            <td className="text-right fw-400">${displayTwoDigitWithDelimiter(salesReport.avg_per_payment)}</td>
                        </tr>
                        <tr>
                            <td className="text-left fw-400">Total Orders
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right" title="Total number of orders."/>
                            </td>
                            <td className="text-right fw-400">{salesReport.total_orders}</td>
                        </tr>
                        <tr>
                            <td className="text-left fw-400">Avg/Order
                                <img src={icInfo} className="ml-1" alt="" data-toggle="tooltip"
                                     data-placement="right" title="Average amount collected on a order."/>
                            </td>
                            <td className="text-right fw-400">${displayTwoDigitWithDelimiter(salesReport.avg_per_order)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
