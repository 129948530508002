import { IoTrash } from 'react-icons/io5';

import Switch from '../../../utilities/switch/switch';
import Input from '../../../utilities/input/input';
import List from '../../../utilities/list/list';

import styles from './wastage-reasons-list.module.scss';

const WastageReasonList = ({
  wastages,
  onInputChange,
  onListChange,
  onDelete,
  onToggleActive,
  onDrag,
  isLoading,
  isFailed,
}) => {
  const renderInput = (item) => (
    <Input id={String(item.id)} value={item.name} name="name" onChange={onInputChange} style={{ marginBottom: 0 }} />
  );
  const renderSwitcher = (item) => <Switch id={item.id} isActive={item.is_active} onSwitch={onToggleActive} />;

  const columnConfig = [
    {
      key: 'name',
      header: 'Wastage Name',
      render: renderInput,
    },
    {
      key: 'is_active',
      header: 'Active',
      render: renderSwitcher,
    },
  ];

  const rowActions = [
    { icon: <IoTrash />, onClick: onDelete, label: 'Delete wastage', className: styles.row_actions_button },
  ];

  return (
    <List
      data={wastages}
      columnConfig={columnConfig}
      rowActions={rowActions}
      onChange={onListChange}
      isLoading={isLoading}
      isFailed={isFailed}
      isDragable={true}
      onDragged={onDrag}
    />
  );
};

export default WastageReasonList;
