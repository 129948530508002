import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { addNotification } from './tooltipSlice';

import {
  getModifierSets,
  getModifierSet,
  addModifierSet,
  updateModifierSet,
  deleteModifierSet,
  reorderModifierSet,
} from '../api/modifier_sets';

// Thunks
export const fetchModifiers = createAsyncThunk('modifierSets/fetchModifiers', async ({ token }) => {
  const response = await getModifierSets(token);
  return response.data;
});

export const fetchModifier = createAsyncThunk('modifierSets/fetchModifier', async ({ id, token }) => {
  const response = await getModifierSet(id, token);
  return response.data;
});

export const addModifier = createAsyncThunk(
  'modifierSets/addModifier',
  async ({ newModifierDetails, token }, { dispatch }) => {
    try {
      const response = await addModifierSet(newModifierDetails, token);
      dispatch(
        addNotification({
          message: 'Modifier Set successfully created',
          status: 'succeeded',
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to create Modifier Set, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateExistingModifier = createAsyncThunk(
  'modifierSets/updateExistingModifier',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateModifierSet(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Modifier Set successfully updated',
          status: 'succeeded',
        }),
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to update Modifier Set, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeModifier = createAsyncThunk('modifierSets/removeModifier', async ({ id, token }, { dispatch }) => {
  try {
    await deleteModifierSet(id, token);
    dispatch(
      addNotification({
        message: 'Modifier Set successfully deleted',
        status: 'succeeded',
      }),
    );
    return id;
  } catch (error) {
    dispatch(
      addNotification({
        message: `Failed to delete Modifier Set, ${error?.message}`,
        status: 'failed',
      }),
    );
    throw error;
  }
});

export const reorderModifier = createAsyncThunk(
  'modifierSets/reorderModifier',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await reorderModifierSet(id, updatedDetails, token);
      dispatch(fetchModifiers({ token }));
      dispatch(addNotification({ message: 'Modifier was changed successfully reordered', status: 'succeeded' }));
      return updatedDetails;
    } catch (error) {
      dispatch(
        addNotification({ message: `Failed to reorder modifier: ${error?.message || error}`, status: 'failed' }),
      );
      throw error;
    }
  },
);

const initialState = {
  modifierSets: [],
  currentmodifierSet: {},
  queryDetails: {},
  status: 'idle',
  error: null,
};

const modifierSetsSlice = createSlice({
  name: 'modifierSets',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action?.error.message;
    };

    builder
      .addCase(fetchModifiers.pending, setLoading)
      .addCase(fetchModifiers.fulfilled, (state, action) => {
        setSucceeded(state);
        state.modifierSets = action.payload;
      })
      .addCase(fetchModifiers.rejected, setFailed)

      .addCase(fetchModifier.pending, setLoading)
      .addCase(fetchModifier.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentmodifierSet = action.payload;
      })
      .addCase(fetchModifier.rejected, setFailed)

      .addCase(addModifier.pending, setLoading)
      .addCase(addModifier.fulfilled, (state, action) => {
        setSucceeded(state);
        state.modifierSets.push(action.payload);
      })
      .addCase(addModifier.rejected, setFailed)

      .addCase(updateExistingModifier.pending, setLoading)
      .addCase(updateExistingModifier.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedDetails } = action.payload;
        const index = state.modifierSets.findIndex((option) => option.id === updatedDetails.id);

        if (index !== -1) {
          state.modifierSets[index] = updatedDetails;
        }
      })
      .addCase(updateExistingModifier.rejected, setFailed)

      .addCase(removeModifier.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;
        state.modifierSets = state.modifierSets.filter((option) => option.id !== id);
      })
      .addCase(removeModifier.pending, setLoading)
      .addCase(removeModifier.rejected, setFailed)

      .addCase(reorderModifier.pending, setLoading)
      .addCase(reorderModifier.fulfilled, setSucceeded)
      .addCase(reorderModifier.rejected, setFailed);
  },
});

export const { setQueryDetails } = modifierSetsSlice.actions;
export default modifierSetsSlice.reducer;
