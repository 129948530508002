import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { fetchEmployeeTimesheetDetails } from "../../../../redux/timesheetSlice";

import { useAppContext } from "../../../../context/context";
import { transformToISOFormat } from "../../../utilities/utils";

import PageTitle from "../../../../components/utilities/page-title/page-title";
import TimesheetDetailsList from "./timesheet-details-list";

import styles from "./timesheet.module.scss";


function TimesheetDetails() {
  // Hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAppContext();

  // Store
  const status = useSelector((state) => state.timesheet.status);
  const timesheet_details_records = useSelector(
    (state) => state.timesheet.timesheet_details_records
  );

  // Local state
  const [employeeName, setEmployeeName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [timesheetRecords, setTimesheetRecords] = useState([]);

  // Effects
  useEffect(() => {
    if (!fromDate || !toDate) return;

    dispatch(
      fetchEmployeeTimesheetDetails({
        token,
        start_date: fromDate,
        end_date: toDate,
        employee_id: id,
      })
    );
  }, [dispatch, token, fromDate, toDate, id]);

  useEffect(() => {
    setTimesheetRecords(timesheet_details_records);
  }, [timesheet_details_records]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setFromDate(params.get("from_date"));
    setToDate(params.get("to_date"));
    setEmployeeName(params.get("employee_name"));
  }, [location]);

  // Handlers
  const handleSelectDate = (date, setter) => {
    const result = transformToISOFormat(date, true);
    setter(result);
  };

  const handleEditTimesheetDetailsClick = (recordId) => {
    const path = `/employee/${id}/edit_timesheet_details?from_date=${fromDate}&to_date=${toDate}&employee_name=${employeeName}&record_id=${recordId}`;
    navigate(path);
  };

  return (
    <div className={styles.timesheet}>
      <PageTitle name={`${employeeName}s Timecard`} />
      <div className={styles.filters}>
        <div className={styles.filter_wrapper}>
          <label>From Date</label>
          <DatePicker
            selected={fromDate}
            onChange={(date) => handleSelectDate(date, setFromDate)}
            dateFormat="yyyy-MM-dd"
            className={styles.datepicker}
            placeholderText="Select a date"
          />
        </div>

        <div className={styles.filter_wrapper}>
          <label>To Date</label>
          <DatePicker
            selected={toDate}
            onChange={(date) => handleSelectDate(date, setToDate)}
            dateFormat="yyyy-MM-dd"
            className={styles.datepicker}
            placeholderText="Select a date"
          />
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.container} role="table" aria-label="Data list">
          <TimesheetDetailsList
            employeeRecords={timesheetRecords}
            onEdit={handleEditTimesheetDetailsClick}
            isLoading={status === "loading"}
          />
        </div>
      </div>
    </div>
  );
}

export default TimesheetDetails;
