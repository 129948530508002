import React from 'react';
import styles from './taxes.module.scss';
import HeaderReport from "../components/HeaderReport/header-report";
import clsx from "clsx";
import DateRange from "../components/DateRange/date-range";
import ReportTable from "./report-table";

function Taxes() {
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const fakeData = [
        ["Tax1", ["100.00", "10.00", "Percentage", "110.00"]],
        ["Tax2", ["200.00", "20.00", "Fixed", "220.00"]],
    ];

    const downloadPdf = () => {
        console.log("Download PDF");
    }
    const downloadCsv = () => {};

  return (
      <div className={styles.contentWrapper}>
          <HeaderReport
              title={"Taxes"}
              downloadPdf={downloadPdf}
              downloadCsv={downloadCsv}
          />
          <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
              <DateRange
                  setEndDate={setEndDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  startDate={startDate}
              />
          </div>
          <div className="bg-white table-responsive mt-4">
              <ReportTable taxesRecords={fakeData} title={"Payments"} />
              <ReportTable taxesRecords={fakeData} title={"Refunds"} />
          </div>
      </div>
  )
}

export default Taxes;
