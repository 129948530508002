import { useState } from 'react';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { useNavigate } from 'react-router-dom';

import Input from '../../utilities/input/input';
import Checkbox from '../../utilities/checkbox/checkbox';
import Button from '../../utilities/button/button';

import { FaEyeSlash } from 'react-icons/fa6';
import { FaEye } from 'react-icons/fa';

import Logo from '../../../assets/img/snack-logo.svg';

import styles from './new-registration.module.scss';

const registrationSchema = z
  .object({
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    email: z.string().email('Invalid email format'),
    password: z.string().min(5, 'Password must be at least 6 characters long'),
    confirmPassword: z.string().min(5, 'Confirm password must be at least 6 characters long'),
    iAgree: z.boolean().refine((val) => val === true, 'You must agree to the terms'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  });

const checkValueForEmpty = (value) => value === '' || value === undefined || value === null;

const NewRegistration = () => {
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    iAgree: false,
  });
  const [errors, setErrors] = useState({});

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const result = registrationSchema.safeParse(formState);

    if (!result.success) {
      const errorMessages = result.error.format();
      setErrors(errorMessages);
    } else {
      setErrors({});
      console.log(formState);
      navigate('/business-info', {
        state: { fromNavigation: true },
      });
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <img className={styles.logo} src={Logo} draggable={false} alt="Snack logo" />
      </div>
      <div className={styles.content}>
        <h1>Let's create your account.</h1>
        <div className={styles.terms_info}>
          <h4>Signing up for Slack is fast and free.</h4>
          <h4>No commitmnets or long-term contracts</h4>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <Input
              value={formState.firstName}
              id="firstName"
              name="firstName"
              type="text"
              autoComplete="firstName"
              onChange={handleFormChange}
              activePlaceholder={true}
              placeholder={'First Name'}
              required={true}
              errors={errors.firstName?._errors}
            />
          </div>
          <div className={styles.formGroup}>
            <Input
              value={formState.lastName}
              id="lastName"
              name="lastName"
              type="text"
              autoComplete="lastName"
              onChange={handleFormChange}
              activePlaceholder={true}
              placeholder={'Last Name'}
              required={true}
              errors={errors.lastName?._errors}
            />
          </div>
          <div className={styles.formGroup}>
            <Input
              value={formState.email}
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              onChange={handleFormChange}
              activePlaceholder={true}
              placeholder={'Email Address'}
              required={true}
              errors={errors.email?._errors}
            />
          </div>
          <div className={styles.formGroup}>
            <Input
              value={formState.password}
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="password"
              onChange={handleFormChange}
              activePlaceholder={true}
              placeholder={'Passsword'}
              suffixIcon={!checkValueForEmpty(formState.password) ? showPassword ? <FaEye /> : <FaEyeSlash /> : null}
              suffixButtonCallback={() => setShowPassword((prev) => !prev)}
              required={true}
              errors={errors.password?._errors}
            />
          </div>
          <div className={styles.formGroup}>
            <Input
              value={formState.confirmPassword}
              id="confirmPassword"
              name="confirmPassword"
              type={showConfirmedPassword ? 'text' : 'password'}
              autoComplete="confirmPassword"
              onChange={handleFormChange}
              activePlaceholder={true}
              placeholder={'Confirm Passsword'}
              suffixIcon={
                !checkValueForEmpty(formState.confirmPassword) ? (
                  showConfirmedPassword ? (
                    <FaEye />
                  ) : (
                    <FaEyeSlash />
                  )
                ) : null
              }
              suffixButtonCallback={() => setShowConfirmedPassword((prev) => !prev)}
              required={true}
              errors={errors.confirmPassword?._errors}
            />
          </div>
          <div className={styles.i_agree}>
            <div className={styles.checkbox}>
              <Checkbox
                isChecked={formState.iAgree}
                handleOnChange={(_, isChecked) => handleFormChange({ target: { name: 'iAgree', value: isChecked } })}
              />
              <p>
                I agree to Snack POS's <Link to="/terms">Terms</Link> & <Link to="/privacy-policy">Privacy Policy</Link>
              </p>
            </div>
            {errors.iAgree?._errors && <div className={styles.error}>{errors.iAgree._errors[0]}</div>}
          </div>
          <Button
            title="Continue"
            type="submit"
            className={styles.submit_button}
            style={{ width: '100%', fontSize: '1rem' }}
          />
          <div className={styles.has_account}>
            <p>
              Already have a Snack POS account? <Link to="/login">Sign In</Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewRegistration;
