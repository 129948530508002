export const navConfig = [
  {
    isParent: false,
    name: 'Dashboard',
    link: '/dashboard',
    subItems: [],
  },
  {
    isParent: true,
    name: 'Employees',
    link: '/employee',
    subItems: [
      { name: 'Employees', link: '/employees' },
      { name: 'Roles', link: '/roles' },
      { name: 'Timesheet', link: '/timesheet' },
      { name: 'Inactive Employees', link: '/inactive_employees' },
    ],
  },
  {
    isParent: false,
    name: 'Customers',
    link: '/customers',
    subItems: [],
  },
  {
    isParent: true,
    name: 'Reporting',
    link: '/reporting',
    subItems: [
      { name: 'Orders', link: '/orders' },
      // { name: 'Dine in Orders', link: '/dine-in-orders' },
      // { name: 'Order Items', link: '/order-items' },
      // { name: 'Canceled orders', link: '/canceled-orders' },
      // { name: 'Sales Overview', link: '/sales-overview' },
      // { name: 'Sales Report', link: '/sales-report' },
      // { name: 'Sales by Category', link: '/sales-by-category' },
      // { name: 'Taxes', link: '/taxes' },
      // { name: 'Tips', link: '/tips' },
      { name: 'Transactions', link: '/transactions' },
      { name: 'Deleted Transactions', link: '/deleted-transactions' },
      // { name: 'Discounts', link: '/discounts' },
      // { name: 'Top Items', link: '/top-items' },
      // { name: 'Refunds', link: '/refunds' },
      // { name: 'Employee Shift Report', link: '/employee-shift-report' },
      // { name: 'Report by Modifiers', link: '/report-by-modifiers' },
      // { name: 'Kitchen Report', link: '/kitchen-report' },
      // { name: 'Non-Cash Adjustments', link: '/non-cash-adjustments' },
      // { name: 'Labor Summary', link: '/labor-summary' },
      // { name: 'Gift Card Purchase', link: '/gift-card-purchase' },
      // { name: 'Gift Card Customer Usage', link: '/gift-card-customer-usage' },
      // { name: 'Loyalty Report', link: '/loyalty-report' },
      // { name: 'Batch Close Report', link: '/batch-close-report' },
    ],
  },
  {
    isParent: true,
    name: 'Menu',
    link: '/menu',
    subItems: [
      { name: 'Items', link: '/items', itemCountName: 'active_items' },
      { name: 'Categories', link: '/categories', itemCountName: 'categories' },
      { name: 'Modifiers', link: '/modifiers', itemCountName: 'modifier_sets' },
      { name: 'Options', link: '/options', itemCountName: 'option_sets' },
      { name: 'Hidden Items', link: '/hidden_items', itemCountName: 'hidden_items' },
      //{ name: 'Hidden Items on Website', link: '/hidden_items_website', itemCountName: 'hidden_items_website' },
      { name: 'Hidden Categories', link: '/hidden_categories', itemCountName: 'hidden_categories' },
      { name: 'Settings', link: '/settings' },
    ],
  },
  {
    isParent: true,
    name: 'Business Setup',
    link: '/business',
    subItems: [
      { name: 'About your Business', link: '' },
      { name: 'Business Information', link: '/information' },
      { name: 'Business Hours', link: '/hours' },
      { name: 'Business Operations', link: '' },
      { name: 'Reporting', link: '/reporting' },
      { name: 'Tips', link: '/tips' },
      { name: 'Service Charges', link: '/service_charges' },
      { name: 'Sales Taxes', link: '/sales_taxes' },
      { name: 'Discounts', link: '/discounts' },
      { name: 'Printers', link: '/printers' },
      { name: 'Printers Queue', link: '/printer_queues' },
      { name: 'Other Settings', link: '' },
      { name: 'Batch Close Time', link: '/batch_close_time' },
      { name: 'Cancel Order Reasons', link: '/cancel_order_reasons' },
      { name: 'Wastage Reason', link: '/wastage_reasons' },
      { name: 'Order Notes', link: '/order_notes' },
      { name: 'Order Types', link: '/order_types' },
      { name: 'Loyalty Programs', link: '/loyalty_programs' },
      { name: 'Terminal Settings', link: '/terminal_settings' },
      { name: 'Customer Receipt Settings', link: '/customer_receipt_settings' },
      { name: 'Kitchen Receipt Settings', link: '/kitchen_receipt_settings' },
      { name: 'End of Day Report Settings', link: '/end_of_day_report_settings' },
      { name: 'Shift Receipt Settings', link: '/shift_receipt_settings' },
      { name: 'Customer Display Settings', link: '/customer_display_settings' },
      { name: 'Other Settings', link: '/other_settings' },
    ],
  },
  // ----------------------------------------------------------------------------------------------------------
  // |-----These sections have been commented due to incomplete features that are not included in the MVP-----|
  // ----------------------------------------------------------------------------------------------------------
  // {
  //   isParent: false,
  //   name: 'Kitchen Display',
  //   link: '/kitchen-display',
  //   subItems: [],
  // },
  // {
  //   isParent: true,
  //   name: 'Online Website',
  //   link: '/online-website',
  //   subItems: [
  //     { name: 'Online Website', link: '' },
  //     { name: 'Dashboard', link: '/dashboard' },
  //     { name: 'Online Ordering Hours', link: '/online-ordering-hours' },
  //     { name: '3rd Party Online Ordering', link: '/3rd-party-online-ordering' },
  //     { name: 'Banner Details', link: '/banner-details' },
  //     { name: 'Page Content', link: '/page-content' },
  //     { name: 'About Bisiness', link: '/about-business' },
  //     { name: 'Gallery', link: '/gallery' },
  //     { name: 'Terminal to Receive Orders', link: '/terminal-receive-orders' },
  //     { name: 'Section Sequence', link: '/section-sequence' },
  //     { name: 'Reservations', link: '/reservations' },
  //     { name: 'Events', link: '/events' },
  //     { name: 'Customer Messages', link: '/customer-messages' },
  //     { name: 'Coupon', link: '/coupon' },
  //     { name: 'Pages', link: '/pages' },
  //     { name: 'Other Settings', link: '/other-settings' },
  //   ],
  // },
  // {
  //   isParent: true,
  //   name: 'Floor Plan',
  //   link: '/floor-plan',
  //   subItems: [
  //     { name: 'Floor plan', link: '/floor-plan' },
  //     { name: 'Clear Tables', link: '/clear-tables' },
  //     { name: 'Settings', link: '/settings' },
  //   ],
  // },
  // {
  //   isParent: false,
  //   name: 'Happy Hour',
  //   link: '/happy-hour',
  //   subItems: [],
  // },
  // {
  //   isParent: false,
  //   name: 'Invoices',
  //   link: '/invoices',
  //   subItems: [],
  // },
  // {
  //   isParent: false,
  //   name: 'Gift Card',
  //   link: '/gift-card',
  //   subItems: [],
  // },
  // {
  //   isParent: false,
  //   name: 'Events',
  //   link: '/events',
  //   subItems: [],
  // },
];
