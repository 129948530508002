import React from 'react';
import Button from '../../../utilities/button/button';

export default function TransactionsFilter({onUpdate}) {
    const [from, setFrom] = React.useState('');
    const [to, setTo] = React.useState('');

    const handleUpdate = () => {
        onUpdate({from, to});
    };

    return (
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row">
                <div className="col-3 align-content-center">Show transactions with total between:</div>
                <div className="col-2 input-group align-content-center">
                    <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                    </div>
                    <input type={"number"} min={0} value={from} className="form-control" />
                </div>
                <div className="col-1 align-content-center">and</div>
                <div className="col-2 input-group align-content-center">
                    <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                    </div>
                    <input type={"number"} min={0} value={to} className="form-control" />
                </div>
                <div className="col-2 justify-content-end">
                    <Button title={'Update'} onClick={handleUpdate} />
                </div>
            </div>
        </div>
    );
}
