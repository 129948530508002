import { z } from 'zod';

export const registrationSchema = z.object({
  mac_address: z
    .string({ required_error: 'MAC Address is required' })
    .min(1)
    .regex(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/, 'Incorrect MAC address format'),
  model_name: z.string({ required_error: 'Model name is required' }).min(1),
  name: z.string({ required_error: 'Printer name is required' }).min(1),
  receipt_print_type: z.string({ required_error: 'Printer type is required' }).min(1),
});
