import React from "react";
import {displayTwoDigitWithDelimiter} from '../components/helper'

export default function ReportTable({ categorySales }) {
    return (
        <table className="table table-bordered">
            <thead className="thead-light">
            <tr>
                <th>Category Name</th>
                <th>Item Count</th>
                <th>Net Sales</th>
                <th>Discount</th>
                <th>Gross Sales</th>
                <th>Tax Amount</th>
            </tr>
            </thead>
            <tbody id="category_sales_body">
                {categorySales.category_sales.length > 0 ? (
                    categorySales.category_sales.map((record, index) => {
                        const totalRecord = record[1][record[1].length - 1];
                        return (
                            <>
                                <tr>
                                    <td className="fw-600">{record[0]}</td>
                                    <td className="fw-600">{totalRecord[1]}</td>
                                    <td className="fw-600">${displayTwoDigitWithDelimiter(totalRecord[2])}</td>
                                    <td className="fw-600">${displayTwoDigitWithDelimiter(totalRecord[3])}</td>
                                    <td className="fw-600">${displayTwoDigitWithDelimiter(totalRecord[4])}</td>
                                    <td className="fw-600">${displayTwoDigitWithDelimiter(totalRecord[5])}</td>
                                </tr>
                                {record[1].map((innerRecord, innerIndex) => (
                                    innerRecord[0] !== "Total" && (
                                        <tr key={innerIndex}>
                                            <td className="pl-4">{innerRecord[0]}</td>
                                            <td>{innerRecord[1]}</td>
                                            <td>${displayTwoDigitWithDelimiter(innerRecord[2])}</td>
                                            <td>${displayTwoDigitWithDelimiter(innerRecord[3])}</td>
                                            <td>${displayTwoDigitWithDelimiter(innerRecord[4])}</td>
                                            <td>${displayTwoDigitWithDelimiter(innerRecord[5])}</td>
                                        </tr>
                                    )
                                ))}
                                <tr>
                                    <td colSpan="6"></td>
                                </tr>
                            </>
                        );
                    })
                ) : (
                    <tr>
                        <td className="text-center" colSpan="6">No Records found</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
