import styles from './kitchen-receipt-settings.module.scss';

const KitchenReceiptExample = ({ settings }) => {
  return (
    <section className={styles.receipt_example}>
      <div className={styles.example_content}>
        <div className={styles.header}>
          {settings.show_category && <h3 className={styles.title}>Dine In</h3>}
          <ul className={styles.information}>
            <li>
              <strong>Order ID: ORD564454</strong>
            </li>
            <li>
              <strong>Receipt ID: REC87564454</strong>
            </li>
            {settings.show_team_member && (
              <li>
                <strong>Employee: David Miller</strong>
              </li>
            )}
            <li>April-29-2021 07:15 PM</li>
          </ul>
        </div>

        <div className={styles.dish_section}>
          {settings.show_order_type && <h4 className={styles.dish_name}>Soup</h4>}
          <div className={styles.dish_details}>
            <p className={styles.ingredient}>1 x Chicken Meals</p>
            <ul className={styles.additional_info_list}>
              <li>Extra spices</li>
              <li>Extra spices</li>
              <li>Extra spices</li>
            </ul>
          </div>
        </div>

        {settings.show_order_note && (
          <div className={styles.order_note}>
            <h4 className={styles.note_title}>Order Note</h4>
            <p className={styles.note_text}>Sugar Free, No Herbs & Spices</p>
          </div>
        )}

        {!settings.show_customer_name && !settings.show_customer_phone && !settings.show_customer_address ? null : (
          <div className={styles.customer_section}>
            <h4 className={styles.section_title}>Customer Details</h4>
            <address className={styles.customer_info}>
              {settings.show_customer_name && <p className={styles.customer_name}>David Miller</p>}
              {settings.show_customer_phone && <p className={styles.customer_phone}>(635) 987-3354</p>}
              {settings.show_customer_address && (
                <p className={styles.customer_address}>77450 DW 51 FH AT, Suite 503</p>
              )}
            </address>
          </div>
        )}
      </div>
    </section>
  );
};

export default KitchenReceiptExample;
