import React from 'react';
import styles from './shift-receipt-settings.module.scss';

function ShiftReceiptSettings() {
  return (
    <>
      <div className={styles.dashboard} style={{fontFamily: '"SFProDisplayRegular", sans-serif'}}>ShiftReceiptSettings Content</div>
      <p style={{fontFamily: '"SFProDisplayRegular", sans-serif'}}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corporis reprehenderit temporibus maiores ullam neque, dignissimos soluta suscipit pariatur laudantium praesentium? Labore deleniti omnis voluptatem, officia neque nesciunt, saepe id aliquid doloribus esse, velit possimus magni. Consequatur deleniti architecto voluptates dignissimos reprehenderit numquam voluptatum asperiores mollitia quasi nihil qui repellendus sapiente perspiciatis, labore ipsam error hic iure quis maiores libero recusandae dicta eveniet dolor in! Quod maxime cumque ab ad impedit.</p>
    </>
  )
}

export default ShiftReceiptSettings;