import { IoTrash } from 'react-icons/io5';
import { MdModeEditOutline } from 'react-icons/md';

import List from '../../../utilities/list/list';

import { format } from 'date-fns';

import styles from './loyalty-programs-list.module.scss';

const DATE_FROMAT = "MMMM d, yyyy 'at' hh:mm a";

const LoyaltyProgramsList = ({ loyalties, onDelete, onEdit, isLoading }) => {
  const renderRewardAmount = (item) => (
    <span>
      {item.reward_point
        ? item.reward_type === '$'
          ? `$${item.reward_point}`
          : `${item.reward_point}${item.reward_type}`
        : ''}
    </span>
  );
  const renderActive = (item) => <span>{item.is_enable ? 'Yes' : 'No'}</span>;
  const renderTime = (item) => <span>{format(item.created_at, DATE_FROMAT)}</span>;

  const columnConfig = [
    {
      key: 'name',
      header: 'Loyalty Name',
    },
    {
      key: 'reward_point',
      header: 'Reward Amount',
      render: renderRewardAmount,
    },
    {
      key: 'amount',
      header: 'Loyalty Target',
    },
    {
      key: 'created_at',
      header: 'Created Time',
      render: renderTime,
    },
    {
      key: 'is_enable',
      header: 'Active',
      render: renderActive,
    },
  ];

  const rowActions = [
    { icon: <MdModeEditOutline />, onClick: onEdit, label: 'Edit employee', className: styles.row_actions_button },
    { icon: <IoTrash />, onClick: onDelete, label: 'Delete employee', className: styles.row_actions_button },
  ];

  return <List data={loyalties} columnConfig={columnConfig} rowActions={rowActions} isLoading={isLoading} />;
};

export default LoyaltyProgramsList;
