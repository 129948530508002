import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getEmployeesTimesheet,
  getEmployeeTimesheetDetails,
  updateEmployeeTimesheetDetails,
} from "../api/timesheet";

import { addNotification } from "./tooltipSlice";

// Thunks
export const fetchEmployeesTimesheet = createAsyncThunk(
  "timesheet/fetchTimesheet",
  async ({
    token,
    start_date,
    end_date,
    team_role_id = null,
    employee_id = null,
  }) => {
    const response = await getEmployeesTimesheet(
      token,
      start_date,
      end_date,
      team_role_id,
      employee_id
    );

    return response.data;
  }
);

export const fetchEmployeeTimesheetDetails = createAsyncThunk(
  "timesheet/fetchTimesheetDetails",
  async ({ token, start_date, end_date, employee_id = null }) => {
    const response = await getEmployeeTimesheetDetails(
      token,
      start_date,
      end_date,
      employee_id
    );

    return response.data;
  }
);

export const updateExistingTimesheetDetails = createAsyncThunk(
  "timesheet/updateTimesheetDetails",
  async ({ updatedDetails, token }, { dispatch }) => {
    try {
      const response = await updateEmployeeTimesheetDetails(
        updatedDetails,
        token
      );
      dispatch(
        addNotification({
          message: "Timesheet details were updated successfully",
          status: "succeeded",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to update timesheet details",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

const initialState = {
  timesheet_records: [],
  timesheet_details_records: [],
  status: "idle",
  error: null,
};

const timesheetSlice = createSlice({
  name: "timesheet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = "loading";
    };

    const setSucceeded = (state) => {
      state.status = "succeeded";
    };

    const setFailed = (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    };

    builder
      .addCase(fetchEmployeesTimesheet.pending, setLoading)
      .addCase(fetchEmployeesTimesheet.fulfilled, (state, action) => {
        setSucceeded(state);
        state.timesheet_records = action.payload.records;
      })
      .addCase(fetchEmployeesTimesheet.rejected, setFailed)

      .addCase(fetchEmployeeTimesheetDetails.pending, setLoading)
      .addCase(fetchEmployeeTimesheetDetails.fulfilled, (state, action) => {
        setSucceeded(state);
        state.timesheet_details_records = action.payload.records;
      })
      .addCase(fetchEmployeeTimesheetDetails.rejected, setFailed)

      .addCase(updateExistingTimesheetDetails.pending, setLoading)
      .addCase(updateExistingTimesheetDetails.fulfilled, (state, action) => {
        setSucceeded(state);
        state.timesheet_details_records = action.payload;
      })
      .addCase(updateExistingTimesheetDetails.rejected, setFailed);
  },
});

export default timesheetSlice.reducer;
