import { Outlet } from 'react-router-dom';

import Modal from '../../utilities/modal/modal-template';
import Tooltip from '../../utilities/tooltip/tooltip';

const ServiceWrapper = () => {
  return (
    <>
      <Modal />
      <Tooltip />
      <Outlet />
    </>
  );
};

export default ServiceWrapper;
