import React from 'react';

export default function NoRecords({colSpan = 9}) {
    return (
        <tr>
            <td colSpan={colSpan}>
                <center>No Records available.</center>
            </td>
        </tr>
    );
}
