import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../../../context/context';

import { fetchPrinter, updateExistingPrinter } from '../../../../redux/printersSlice';
import { fetchOrderTypes } from '../../../../redux/orderTypesSlice';
import { fetchCategories } from '../../../../redux/categoriesSlice';
import { addNotification } from '../../../../redux/tooltipSlice';

import PrinterSettingsList from './printer-list/printer-settings-list';

import PageTitle from '../../../utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Select from '../../../utilities/selector/selector';
import Button from '../../../utilities/button/button';
import Checkbox from '../../../utilities/checkbox/checkbox';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import { isObjectEmpty } from '../../../utilities/utils';

import { usePrinterSettings } from './hooks/usePrinterSettings';
import { registrationSchema } from './validation';
import useFormValidation from '../../../utilities/useFormValidation';

import styles from './printers.module.scss';

const printerTypes = [
  { label: 'Customer', value: 'Customer' },
  { label: 'Kitchen', value: 'Kitchen' },
  { label: 'Kitchen and Customer', value: 'KitchenAndCustomer' },
];

const getPrinter = (printers, id) => {
  return printers?.find((printer) => printer.id === parseFloat(id));
};

const EditPrinter = () => {
  const { token } = useAppContext();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const categoryParam = searchParams.get('category');

  const printersStatus = useSelector((state) => state.printers.status);
  const printers = useSelector((state) => state.printers.printers);
  const currentPrinter = useSelector((state) => state.printers.currentPrinter);
  const orderTypes = useSelector((state) => state.orderTypes.orderTypes);
  const orderTypesStatus = useSelector((state) => state.orderTypes.status);
  const categories = useSelector((state) => state.categories.categories);
  const categoriesStatus = useSelector((state) => state.categories.status);

  const { errors, validate } = useFormValidation(registrationSchema);

  const { printer, printerSettings, handleOrderTypes, handleInputChange, setPrinter } = usePrinterSettings(
    orderTypes,
    getPrinter(printers[categoryParam], id) || currentPrinter,
  );

  const statusLoading = printersStatus === 'loading';
  const orderTypesLoading = orderTypesStatus === 'loading';
  const categoriesLoading = categoriesStatus === 'loading';

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (printersStatus === 'idle') {
          await dispatch(fetchPrinter({ id, token })).unwrap();
        }
        if (orderTypesStatus === 'idle') {
          await dispatch(fetchOrderTypes({ token })).unwrap();
        }
        if (categoriesStatus === 'idle') {
          await dispatch(fetchCategories(token)).unwrap();
        }
      } catch (error) {
        dispatch(addNotification({ message: `Error fetching data: ${error}`, status: 'failed' }));
      }
    };

    fetchData();
  }, [dispatch, token, id, printersStatus, orderTypesStatus, categoriesStatus]);

  useEffect(() => {
    if (isObjectEmpty(printer) && !isObjectEmpty(currentPrinter)) {
      setPrinter(currentPrinter);
    }
  }, [currentPrinter, printer, setPrinter]);

  useEffect(() => {
    if (token) {
      dispatch(fetchOrderTypes({ token }));
      dispatch(fetchCategories(token));
    }
  }, [dispatch, token]);

  const handleCategoriesSet = useCallback(
    (id, value) => {
      if (id === 'All' && categories.length) {
        setPrinter((prev) => ({
          ...prev,
          category_ids: value ? categories.map((c) => c.id) : [],
        }));
        return;
      }

      setPrinter((prev) => ({
        ...prev,
        category_ids: value ? [...prev.category_ids, id] : prev.category_ids.filter((c) => c !== id),
      }));
    },
    [categories, setPrinter],
  );

  const memoizedOnChange = useCallback(
    (value) => handleInputChange({ target: { value, name: 'receipt_print_type' } }),
    [handleInputChange],
  );

  const handleCancel = () => {
    navigate('/business/printers');
  };

  const handleSaveChanges = async () => {
    if (validate(printer)) {
      try {
        console.log('Here');
        await dispatch(updateExistingPrinter({ id, updatedDetails: printer, token })).unwrap();

        navigate('/business/printers');
      } catch (e) {
        console.log('Here//// ', e);
      }
    }
  };

  if (isObjectEmpty(printer)) {
    return null;
  }

  return (
    <section className={styles.main_section}>
      <div className={styles.content}>
        <PageTitle name={'Edit Printer'} />
        <div className={styles.form_container}>
          <div className={styles.fields}>
            {statusLoading ? <BackdropLoading /> : null}
            <div className={styles.field}>
              <Input
                label={'Printer Name'}
                value={printer.name}
                onChange={handleInputChange}
                name={'name'}
                required={true}
                errors={errors.name?._errors}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'MAC Address'}
                value={printer.mac_address}
                onChange={handleInputChange}
                name={'mac_address'}
                required={true}
                errors={errors.mac_address?._errors}
              />
            </div>
            <div className={styles.field}>
              <Select
                placeholder={'Select Printer Type'}
                items={printerTypes}
                label="Printer Type"
                selectedItem={printer.receipt_print_type}
                onSelectItem={memoizedOnChange}
                initialPlaceholderVisibility={false}
                errors={errors.receipt_print_type?._errors}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'Model Name'}
                value={printer.model_name}
                onChange={handleInputChange}
                name={'model_name'}
                required={true}
                errors={errors.model_name?._errors}
              />
            </div>
          </div>
          <div className={styles.table_container}>
            {orderTypesLoading ? <BackdropLoading /> : null}
            <PrinterSettingsList printers={printerSettings} onToggleActive={handleOrderTypes} />
          </div>
          <div className={styles.section_title}>Select categories to print</div>
          <div className={styles.categories_container}>
            {categoriesLoading ? <BackdropLoading /> : null}
            <div className={styles.category_option}>
              <Checkbox
                handleOnChange={handleCategoriesSet}
                item={{ name: '', id: 'All' }}
                isChecked={printer.category_ids?.length === categories?.length}
              />
              Select All
            </div>
            <div className={styles.categories}>
              {categories.map((category) => (
                <div key={category.id} className={styles.category_option}>
                  <Checkbox
                    item={{ name: category.name, id: category.id }}
                    handleOnChange={handleCategoriesSet}
                    isChecked={!!printer.category_ids?.find((c) => c === category.id)}
                  />
                  {category.name}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.buttons}>
            <Button className={styles.button} disabled={statusLoading} title={'Save'} onClick={handleSaveChanges} />
            <Button
              className={styles.button}
              variant={'grey'}
              disabled={statusLoading}
              title={'Cancel'}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditPrinter;
