import React from 'react';

export default function ReportTable({ modifiers }) {
    return (
        <table className="table table-bordered">
            <thead className="thead-light">
            <tr>
                <th>Modifier Name</th>
                <th>Modifier Set Name</th>
                <th>Total Count</th>
            </tr>
            </thead>
            <tbody className="modifiers_report">
                {modifiers.length > 0 ? (
                    modifiers.map((modifier) => (
                        <tr key={modifier.id}>
                            <td>
                                <div className="connected-printer text-left">
                                    <h6 className="text-gray2 fw-600 mb-1">{modifier.name}</h6>
                                    <p>ID: {modifier.id}</p>
                                </div>
                            </td>
                            <td>{modifier.modifier_set}</td>
                            <td>{modifier.quantity}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className="text-center" colSpan="3">No Records found</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
