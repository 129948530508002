import clsx from 'clsx';
import styles from './button.module.scss';

const Button = ({ title, type, onClick, disabled, variant, style, className }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <button onClick={onClick} type={type} disabled={disabled} className={styles[`variant_${variant}`]} style={style}>
        {title}
      </button>
    </div>
  );
};
export default Button;
