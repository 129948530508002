import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { addNotification } from './tooltipSlice';

import { getOrders, getOrder, addOrder, updateOrder, deleteOrder } from '../api/order_types';

// Thunks
export const fetchOrderTypes = createAsyncThunk('orderTypes/fetchOrders', async ({ token }) => {
  const response = await getOrders(token);
  return response.data;
});

export const fetchOrderType = createAsyncThunk('orderTypes/fetchOrder', async ({ id, token }) => {
  const response = await getOrder(id, token);
  return response.data;
});

export const addOrderType = createAsyncThunk(
  'orderTypes/addOrder',
  async ({ newOrderDetails, token }, { dispatch }) => {
    try {
      const response = await addOrder(newOrderDetails, token);
      dispatch(
        addNotification({
          message: 'Order Type successfully created',
          status: 'succeeded',
        }),
      );
      return response; //order comes directly via response (without .data)
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to create Order Type, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateExistingOrderType = createAsyncThunk(
  'orderTypes/updateExistingOrder',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateOrder(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Order Type successfully updated',
          status: 'succeeded',
        }),
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to update Order Type, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeOrderType = createAsyncThunk('orderTypes/removeOrder', async ({ id, token }, { dispatch }) => {
  try {
    await deleteOrder(id, token);
    dispatch(
      addNotification({
        message: 'Order Type successfully deleted',
        status: 'succeeded',
      }),
    );
    return id;
  } catch (error) {
    dispatch(
      addNotification({
        message: `Failed to delete Order Type, ${error?.message}`,
        status: 'failed',
      }),
    );
    throw error;
  }
});

const initialState = {
  orderTypes: [],
  currentOrderType: {},
  queryDetails: {},
  status: 'idle',
  error: null,
};

const orderTypesSlice = createSlice({
  name: 'orderTypes',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    };

    builder
      .addCase(fetchOrderTypes.pending, setLoading)
      .addCase(fetchOrderTypes.fulfilled, (state, action) => {
        setSucceeded(state);
        state.orderTypes = action.payload;
      })
      .addCase(fetchOrderTypes.rejected, setFailed)

      .addCase(fetchOrderType.pending, setLoading)
      .addCase(fetchOrderType.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentOrderType = action.payload;
      })
      .addCase(fetchOrderType.rejected, setFailed)

      .addCase(addOrderType.pending, setLoading)
      .addCase(addOrderType.fulfilled, (state, action) => {
        setSucceeded(state);
        state.orderTypes.push(action.payload);
      })
      .addCase(addOrderType.rejected, setFailed)

      .addCase(updateExistingOrderType.pending, setLoading)
      .addCase(updateExistingOrderType.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedDetails } = action.payload;
        const index = state.orderTypes.findIndex((order) => order.id === updatedDetails.id);

        if (index !== -1) {
          state.orderTypes[index] = updatedDetails;
        }
      })
      .addCase(updateExistingOrderType.rejected, setFailed)

      .addCase(removeOrderType.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;
        state.orderTypes = state.orderTypes.filter((order) => order.id !== id);
      })
      .addCase(removeOrderType.pending, setLoading)
      .addCase(removeOrderType.rejected, setFailed);
  },
});

export const { setQueryDetails } = orderTypesSlice.actions;
export default orderTypesSlice.reducer;
