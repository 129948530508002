import { useState } from 'react';

const useFormValidation = (schema) => {
  const [errors, setErrors] = useState({});

  const validate = (data, context) => {
    const result = schema.safeParse(data);

    if (!result.success) {
      const errorMessages = result.error.format();
      setErrors((prev) => (context ? { ...prev, [context]: errorMessages } : errorMessages));
      return false;
    } else {
      setErrors((prev) => (context ? { ...prev, [context]: {} } : {}));
      return true;
    }
  };

  return { errors, validate, setErrors };
};

export default useFormValidation;
