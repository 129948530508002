import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import Selector from '../selector/selector';

import { IoIosArrowDown } from 'react-icons/io';

import styles from './pagination.module.scss';

const Pagination = ({
  pageSizeOptions,
  currentPage,
  currentPageSize,
  numberOfItems,
  onPageSizeChange,
  onPrev,
  onNext,
  inputPaginationControl,
  style,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const totalPages = Math.ceil(numberOfItems / currentPageSize);

  const isPrevActive = inputPaginationControl?.isActive ? inputPaginationControl.isPrevActive : currentPage > 1;

  const isNextActive = inputPaginationControl?.isActive
    ? inputPaginationControl.isNextActive
    : currentPage < totalPages;

  useEffect(() => {
    setSearchParams({ page: currentPage });
  }, [currentPage, setSearchParams]);

  return (
    <div className={styles.container} style={style}>
      <label className={styles.label}>
        <div className={styles.selector}>
          <Selector
            icon={IoIosArrowDown}
            items={pageSizeOptions}
            selectedItem={currentPageSize}
            initialPlaceholderVisibility={false}
            onSelectItem={onPageSizeChange}
          />
        </div>
        Results per page
      </label>
      <div id="pagination" className={styles.controls}>
        <nav className={styles.nav} role="navigation" aria-label="pagination">
          <button aria-label="previous page" onClick={onPrev} disabled={!isPrevActive}>
            Previous
          </button>
          <button aria-label="next page" onClick={onNext} disabled={!isNextActive}>
            Next
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Pagination;
