import { api, handleApiResponse } from './base';

export const getTipsList = (token) =>
  handleApiResponse(() =>
    api.get('tip_settings', {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const addNewTip = (newDetails, token) =>
  handleApiResponse(() =>
    api.post('tip_settings', newDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const updateTip = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`tip_settings/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const deleteTip = (id, token) =>
  handleApiResponse(() =>
    api.delete(`tip_settings/${id}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const updateTipStatus = (id, is_active, token) =>
  handleApiResponse(() =>
    api.put(
      `tip_settings/${id}/active?is_active=${is_active}`,
      {},
      {
        headers: {
          TOKEN: token,
        },
      },
    ),
  );

export const reorderTip = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(
      `tip_settings/${id}/reorder?old_position=${updatedDetails.old_position}&new_position=${updatedDetails.new_position}`,
      {},
      {
        headers: {
          TOKEN: token,
        },
      },
    ),
  );
