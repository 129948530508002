import { api, handleApiResponse } from './base';

export const getNotes = (token) =>
  handleApiResponse(() =>
    api.get('dynamic_notes', {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const addNewNote = (newDetails, token) =>
  handleApiResponse(() =>
    api.post('dynamic_notes', newDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const updateNote = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`dynamic_notes/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const deleteNote = (id, token) =>
  handleApiResponse(() =>
    api.delete(`dynamic_notes/${id}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const updateNoteStatus = (id, is_active, token) =>
  handleApiResponse(() =>
    api.put(`dynamic_notes/${id}/active?is_active=${is_active}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const updateNoteOrder = (id, { old_position, new_position }, token) =>
  handleApiResponse(() =>
    api.put(
      `dynamic_notes/${id}/reorder?old_position=${old_position}&new_position=${new_position}`,
      {},
      {
        headers: {
          TOKEN: token,
        },
      },
    ),
  );
