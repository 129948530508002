import React from 'react';
import styles from './terms.module.scss';

function Terms() {
  return (
    <div className={styles.terms}>
      <h1 className={styles.title} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Terms and Conditions</h1>
      
      <section className={styles.section}>
        <h2 className={styles.subTitle} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Introduction</h2>
        <p style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
          These Terms and Conditions govern your use of the CRUNCH POC website and services. By accessing or using our services, you agree to comply with these terms.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>User Responsibilities</h2>
        <p style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
          As a user of this site, you agree to use the site in a manner consistent with all applicable laws and regulations. You agree not to engage in any activity that could harm the site or disrupt the experience of other users.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Intellectual Property</h2>
        <p style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
          All content on this site, including text, graphics, logos, and software, is the property of CRUNCH POC and is protected by copyright and other intellectual property laws.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Limitation of Liability</h2>
        <p style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
          CRUNCH POC will not be liable for any damages arising from your use of the site or from any information provided through the site. Your use of the site is at your own risk.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Changes to Terms</h2>
        <p style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
          We reserve the right to modify these terms at any time. Any changes will be effective immediately upon posting on the site. Your continued use of the site after changes are posted constitutes your acceptance of the modified terms.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle} style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>Contact Us</h2>
        <p style={{ fontFamily: '"SFProDisplayRegular", sans-serif' }}>
          If you have any questions or concerns about these Terms and Conditions, please contact us at <a href="mailto:terms@crunchpoc.com" className={styles.link}>terms@crunchpoc.com</a>.
        </p>
      </section>
    </div>
  );
}

export default Terms;