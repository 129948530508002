import React from "react";
import { displayPreparationTime} from "../components/helper";
import ViewDate from "../components/view-date";

export default function ReportTable({orders}) {

    return (
        <div className="bg-white table-responsive mt-4">
            <table className="table table-bordered">
                <thead className="thead-light">
                <tr>
                    <th>Order ID</th>
                    <th>Staff</th>
                    <th>Terminal</th>
                    <th>Fired Date & Time</th>
                    <th>Fullfilled Date & Time</th>
                    <th>Fullfillment Time</th>
                </tr>
                </thead>
                <tbody>
                {orders.length > 0 ? (
                    orders.map((order, index) => {
                        const orderEmployee = order.employee;
                        return (
                            <tr key={index}>
                                <td>
                                    <div className="connected-printer text-left">
                                        <h6 className="text-gray2 fw-600 mb-1">
                                            {order.id}
                                        </h6>
                                    </div>
                                </td>
                                <td>{orderEmployee ? orderEmployee.full_name : '-'}</td>
                                <td>{order.terminal ? order.terminal.name : '-'}</td>
                                <td>
                                   <ViewDate date={order.created_at} />
                                </td>
                                <td>
                                    {order.kitchen_completion_time
                                        ? <ViewDate date={order.kitchen_completion_time} />
                                        : '-'}
                                </td>
                                <td>{displayPreparationTime(order)}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td className="text-center" colSpan="5">
                            No Records found
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}
