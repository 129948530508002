import React from 'react';

const CreditTipAudit = ({ shiftReportData }) => {

    shiftReportData = shiftReportData || {
        credit_tip_audit: [
            [
                {
                    key: "Transaction Id",
                    value: 0
                },
                {
                    key: "Last4",
                    value: 0
                },
                {
                    key: "Subtotal",
                    value: 0
                },
                {
                    key: "Tip",
                    value: 0
                },
                {
                    key: "Total",
                    value: 0
                }
            ],
            [
                {
                    key: "Total",
                    value: 0
                },
                {
                    key: "Total",
                    value: 0
                },
                {
                    key: "Total",
                    value: 0
                }
            ]
        ]
    };
    const displayTwoDigitWithDelimiter = (value) => {
        return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    };

    return (
        <div>
            <h4 className="admin-main-title text-darkgray1 fw-700 text-center fs-20 py-3 bg-custom-gray border-bottom mb-4 pb-2">
                CREDIT TIP AUDIT
            </h4>
            <div className="admin-table bg-white table-responsive mt-4">
                <table className="table table-bordered">
                    <thead className="thead-dark">
                    <tr>
                        <th width="25%">Transaction Id</th>
                        <th width="25%">Last4</th>
                        <th width="25%">Subtotal</th>
                        <th width="25%">Tip</th>
                        <th width="25%">Total</th>
                    </tr>
                    </thead>
                    <tbody className="eod_shift_report">
                    {shiftReportData.credit_tip_audit && shiftReportData.credit_tip_audit.length > 0 ? (
                        shiftReportData.credit_tip_audit.map((k, ind) => (
                            <tr align="left" key={ind}>
                                {k.length > 3 ? (
                                    <>
                                        <td>{k[0].value}</td>
                                        <td>{k[1].value}</td>
                                        <td>${displayTwoDigitWithDelimiter(k[2].value)}</td>
                                        <td>${displayTwoDigitWithDelimiter(k[3].value)}</td>
                                        <td>${displayTwoDigitWithDelimiter(k[4].value)}</td>
                                    </>
                                ) : (
                                    <>
                                        <td colSpan="2" style={{ fontWeight: 700 }}>Total</td>
                                        <td style={{ fontWeight: 700 }}>${displayTwoDigitWithDelimiter(k[0].value)}</td>
                                        <td style={{ fontWeight: 700 }}>${displayTwoDigitWithDelimiter(k[1].value)}</td>
                                        <td style={{ fontWeight: 700 }}>${displayTwoDigitWithDelimiter(k[2].value)}</td>
                                    </>
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className="text-center" colSpan="5">No Records found</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CreditTipAudit;
