import React, { useState, useEffect } from 'react';
// import { IoIosArrowDown } from 'react-icons/io';

import { convertValueByType } from '../../../../utilities/utils';
// import Selector from '../../../../utilities/selector/selector';
import Input from '../../../../utilities/input/input';
import useDebounce from '../../../../utilities/useDebounce';

import styles from './phones-form.module.scss';

const Phones = ({ data, onChange }) => {
  const [phones, setPhones] = useState(data ?? {});

  const debouncedAddress = useDebounce(phones, 500);

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setPhones((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  useEffect(() => {
    onChange(phones);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddress]);

  return (
    <div className={styles.section}>
      <label className={styles.label}>Mobile Number</label>
      {/* <div className={styles.selector}>
        <Selector
          name="country"
          items={PHONE_OPTIONS}
          icon={IoIosArrowDown}
          selectedItem={phones?.country}
          onSelectItem={(value) => handleInputChange({ target: { value, name: 'country' } }, 'text')}
          placeholder={'Select Country'}
        />
      </div> */}
      <Input
        type="text"
        name="phone_number"
        placeholder="Phone"
        value={phones?.phone_number}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default Phones;
