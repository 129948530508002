import React from 'react';
import { RxCross2 } from 'react-icons/rx';
import Modal from '../../../../components/utilities/modal/modal';
import Input from '../../../../components/utilities/input/input';

import styles from './edit-categories-modal.module.scss';

function EditCategoryModal({
  isEditCategoryModal,
  setIsEditCategoryModal,
  editableCategoryName,
  setEditableCategoryName,
  handleUpdateCategory,
  errors,
}) {
  return (
    <Modal
      isOpen={isEditCategoryModal}
      onClose={() => setIsEditCategoryModal(false)}
      className={styles.edit_category_modal}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <h3 className={styles.modal_title}>Assign Modules</h3>
          <div className={styles.cross_icon} onClick={() => setIsEditCategoryModal(false)}>
            <RxCross2 />
          </div>
        </div>
        <div className={styles.modal_body}>
          <div className={styles.edit_category_name}>
            <label>
              Name<span>*</span>
            </label>
            <Input
              type="text"
              name={'name'}
              value={editableCategoryName}
              onChange={(e) => setEditableCategoryName(e.target.value)}
              placeholder="New name"
              required={true}
              errors={errors.edit?.name?._errors}
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <button type="button" className={styles.update_button} onClick={handleUpdateCategory}>
            Update
          </button>
          <button type="button" className={styles.cancel_button} onClick={() => setIsEditCategoryModal(false)}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default EditCategoryModal;
