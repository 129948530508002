import { api } from './base';
// import { api } from './mocked/customer-receipt-settings/mockedAxiosApi';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getCustomerReceiptSettings = (token) => {
  return handleApiResponse(() =>
    api.get('customer_receipts', {
      headers: {
        TOKEN: token,
      },
    }),
  );
};

export const updateCustomerReceiptSettings = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`customer_receipts/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );
