import React, { useMemo, useEffect, useRef } from 'react';
import { IoTrash } from 'react-icons/io5';
import { nanoid } from 'nanoid';
import { isObject } from '../utils';

import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Button from '../button/button';
import ActionButton from '../action-button/action-button';

import { useFieldManagement } from './hooks/useFieldManagement';

import { GiHamburgerMenu } from 'react-icons/gi';

import clsx from 'clsx';
import styles from './dynamic-form-field.module.scss';

const ITEM_TYPE = 'ROW';

const FormField = React.memo(
  ({
    field,
    id,
    index,
    fieldProps,
    renderItem: Item,
    onChange,
    onRemove,
    onMoveField,
    isDragable,
    rowClassName,
    errors,
  }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
      accept: ITEM_TYPE,
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2 - 4; //Added additional number (4) for more correct hover positioning
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        onMoveField(dragIndex, hoverIndex);

        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: ITEM_TYPE,
      item: { id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    if (isDragable) {
      drop(preview(ref));
    }

    return (
      <div
        ref={ref}
        key={field.key}
        className={clsx(styles.row, styles[rowClassName])}
        style={{ opacity: isDragging ? 0 : 1 }}
      >
        {isDragable && (
          <div className={clsx(styles.cell, styles.drag_cell)}>
            <div ref={drag} className={styles.drag_icon}>
              <GiHamburgerMenu />
            </div>
          </div>
        )}
        <div className={styles.row_content}>
          <Item
            value={field.value}
            data={field.value}
            onChange={(e) => onChange(field.key, e)}
            style={fieldProps?.style}
            className={fieldProps?.className}
            errors={errors}
          />
        </div>
        <div className={styles.action_area}>
          <ActionButton icon={<IoTrash />} onClick={() => onRemove(field.key)} />
        </div>
      </div>
    );
  },
);

/**
 * DynamicFormFields component allows for dynamic addition, removal, and reordering of form fields
 * @component
 *
 * @param {Object} props - Component props
 * @param {Array} props.value - Initial values for the fields. Can be array of primitive types or objects
 * @param {React.ComponentType} props.fieldItem - Component to be used for rendering individual fields
 * @param {string} props.fieldType - Type of the field, used for value conversion
 * @param {Object} props.fieldProps - Additional props to be passed to each field component
 * @param {string} [props.fieldName] - Name to be used as key when formatting field values. {fieldName: value} or just value when fieldName is not defined
 * @param {number} [props.maxFields] - Maximum number of fields that can be added
 * @param {string} props.label - Label for the entire group of fields
 * @param {string} props.addButtonTitle - Text to display on the add button
 * @param {Function} props.onChange - Callback function called when fields change
 * @param {boolean} [props.bottomLine=false] - Whether to show a line at the bottom of the component
 * @param {boolean} [props.isDragable=false] - Whether fields can be reordered by dragging
 * @param {number|string} [props.fieldWidth] - Width to apply to the field container
 * @param {number|string} [props.rowClassName] - Specified className for styling a row (available: 'table_row')
 *
 * @returns {React.ReactElement} A form component with dynamically manageable fields
 *
 * @example
 * <DynamicFormFields
 *   value={[{ id: 1, name: 'Field 1' }, { id: 2, name: 'Field 2' }]}
 *   fieldItem={CustomInputComponent}
 *   fieldType="text"
 *   fieldProps={{ placeholder: 'Enter value' }}
 *   label="Dynamic Fields"
 *   addButtonTitle="Add Field"
 *   onChange={(fields) => console.log('Fields updated:', fields)}
 *   isDragable={true}
 *   rowClassName={'table_row'}
 * />
 */

const DynamicFormFields = ({
  value = [],
  fieldItem: Item,
  fieldType,
  fieldProps,
  fieldName,
  addItemAttributes,
  maxFields,
  label,
  addButtonTitle,
  onChange,
  bottomLine = false,
  isDragable = false,
  fieldWidth,
  rowClassName,
  className,
  errors,
}) => {
  const structuredData = useMemo(
    () => value?.map((item) => ({ key: nanoid(), item: Item, value: item })),
    [Item, value],
  );

  const { structuredFields, handleAddField, handleRemoveField, handleOnChange, handleMoveField, updateMaxKeys } =
    useFieldManagement(value, structuredData, Item, fieldType, isDragable, maxFields);

  useEffect(() => {
    const result = fieldName
      ? structuredFields.map(
          (field) => (isObject(field.value) ? { [fieldName]: field.value } : { [fieldName]: field.value }), //??
        )
      : structuredFields.map((field) => field.value);

    onChange(result);
    updateMaxKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, structuredFields]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={clsx(styles.container, bottomLine ? styles.bottom_line : null, className)}>
        <label className={styles.label}>{label}</label>
        <div className={styles.nested_field} style={fieldWidth ? { maxWidth: fieldWidth } : null}>
          {structuredFields.map((field, index) => (
            <FormField
              field={field}
              key={field?.key}
              id={field?.key}
              index={index}
              fieldProps={fieldProps}
              renderItem={Item}
              onChange={handleOnChange}
              onRemove={handleRemoveField}
              onMoveField={handleMoveField}
              isDragable={isDragable}
              rowClassName={rowClassName}
              errors={errors?.[index]}
            />
          ))}
        </div>
        <div className={styles.add_button}>
          <Button
            title={addButtonTitle}
            onClick={() => handleAddField(addItemAttributes)}
            disabled={maxFields <= structuredFields?.length}
          />
        </div>
      </div>
    </DndProvider>
  );
};

export default DynamicFormFields;
