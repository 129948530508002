import React, {useEffect} from 'react';
import clsx from "clsx";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import HeaderReport from "../components/HeaderReport/header-report";
import {fetchTransactionsReport} from "../../../../redux/reportsSetSlice";
import {DropdownTypes, FiltersForReports} from "../components/OrderFilters";
import DateRange from "../components/DateRange/date-range";
import ReportTable from "./report-table";
import TransactionsFilter from "./transactions-filter";
import {useAppContext} from "../../../../context/context";
import usePagination from "../../business/order-types/usePagination";
import {DEFAULT_PAGINATION, PAGE_SIZE_OPTIONS} from "../components/helper";
import Pagination from "../../../utilities/pagination/pagination";

import styles from './transactions.module.scss';

function Transactions() {

    const { token } = useAppContext();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [search, setSearch] = React.useState('');
    const [orderTypeId, setOrderTypeId] = React.useState('');
    const [openOrderTypeId, setOpenOrderTypeId] = React.useState('');
    const [paymentAmountMin, setPaymentAmountMin] = React.useState('');
    const [paymentAmountMax, setPaymentAmountMax] = React.useState('');

    const report = useSelector((state) => state.reports.transactionsReport);

    const {
        currentPageItems,
        page,
        perPage,
        isPrevActive,
        isNextActive,
        handleSelectPageCount,
        handleClickPrev,
        handleClickNext,
    } = usePagination(report, DEFAULT_PAGINATION.currentPage, DEFAULT_PAGINATION.itemsPerPage);

    const downloadPdf = () => {};
    const downloadCsv = () => {};

    useEffect(() => {
        dispatch(fetchTransactionsReport({ token,
            startDate,
            endDate,
            search,
            orderTypeId,
            openOrderTypeId,
            paymentAmountMin,
            paymentAmountMax,
            page,
            perPage
        }));
    }, []);


  return (
      <div className={styles.contentWrapper}>
          <HeaderReport
              title={"Transactions"}
              downloadPdf={downloadPdf}
              downloadCsv={downloadCsv}
          />
          <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
              <FiltersForReports
                  searchShow={false}
                  searchTitle={"Search Transactions by ID, Offline ID, Amount and last 4 digits on Payment Card ID"}
                  dropdowns={[
                      DropdownTypes.employee,
                      DropdownTypes.payment_type,
                      DropdownTypes.card_type,
                      DropdownTypes.terminal_id,
                      DropdownTypes.order_type_id,
                  ]}
                  additionalFilter={<TransactionsFilter onUpdate={(data) => console.log(data)} />}
              />
          </div>
          <div className={clsx("mb-4", styles.boxBody, styles.borderBox)}>
              <DateRange
                  setEndDate={setEndDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  startDate={startDate}
              />
          </div>
          <div className="bg-white table-responsive mt-4">
              <ReportTable payments={report}/>
              <Pagination
                  pageSizeOptions={PAGE_SIZE_OPTIONS}
                  numberOfItems={report.length}
                  currentPage={page}
                  currentPageSize={perPage}
                  onPageSizeChange={handleSelectPageCount}
                  inputPaginationControl={{
                      isActive: true,
                      isPrevActive,
                      isNextActive,
                  }}
                  onPrev={handleClickPrev}
                  onNext={handleClickNext}
              />
          </div>
      </div>
  )
}

export default Transactions;
