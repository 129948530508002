import Switch from '../../../../utilities/switch/switch';

import styles from '../kitchen-receipt-settings.module.scss';

const OptionRow = ({ label, id, isActive, onOptionChange }) => (
  <div className={styles.row}>
    <span>{label}</span>
    <Switch id={id} isActive={isActive} onSwitch={onOptionChange} />
  </div>
);

const KitchenReceiptSettingsForm = ({ receiptOptions, onOptionChange }) => {
  const generalOptions = [
    { label: 'Show Category', id: 'show_category' },
    { label: 'Show Employee', id: 'show_team_member' },
    { label: 'Show Order Note', id: 'show_order_note' },
    { label: 'Show Order Type', id: 'show_order_type' },
  ];

  const customerInfoOptions = [
    { label: 'Name', id: 'show_customer_name' },
    { label: 'Mobile', id: 'show_customer_phone' },
    { label: 'Address', id: 'show_customer_address' },
  ];

  return (
    <div className={styles.list_controller}>
      <div className={styles.settings}>
        {generalOptions.map(({ label, id }) => (
          <OptionRow key={id} label={label} id={id} isActive={receiptOptions[id]} onOptionChange={onOptionChange} />
        ))}
      </div>
      <div>
        <h3 className={styles.customer_title}>Show Customer Information</h3>
        <div className={styles.settings}>
          {customerInfoOptions.map(({ label, id }) => (
            <OptionRow key={id} label={label} id={id} isActive={receiptOptions[id]} onOptionChange={onOptionChange} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default KitchenReceiptSettingsForm;
