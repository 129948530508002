import React from "react";
import { Link } from "react-router-dom";

import styles from "./footer.module.scss";

function Footer({ termsLink, privacyPolicyLink }) {
  return (
    <footer className={styles.footer}>
      <div className={styles.infoSection}>
        <li className={styles.infoLinks}>
          <Link to="/terms">Terms</Link>
        </li>
        <li className={styles.infoLinks}>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
      </div>
      <div className="copyright">Crunch POS 2024 All rights reserved.</div>
    </footer>
  );
}

export default Footer;
