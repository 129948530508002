import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchLoyalties, removeLoyalty, setQueryDetails } from '../../../../redux/loyaltyProgramsSlice';
import { showModal, hideModal } from '../../../../redux/modalSlice';

import { useAppContext } from '../../../../context/context';
import usePagination from '../../business/order-types/usePagination';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Pagination from '../../../../components/utilities/pagination/pagination';
import Button from '../../../../components/utilities/button/button';

import LoyaltyProgramsList from './loyalty-programs-list';

import styles from './loyalty-programs.module.scss';

const PAGE_SIZE_OPTIONS = [
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];

const DEFAULT_PAGINATION = {
  currentPage: 1,
  itemsPerPage: 5,
};

function LoyaltyPrograms() {
  const loyaltyProgramsStatus = useSelector((state) => state.loyaltyPrograms.status);
  const loyaltyPrograms = useSelector((state) => state.loyaltyPrograms.loyaltyPrograms);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useAppContext();

  const {
    currentPageItems,
    page,
    perPage,
    isPrevActive,
    isNextActive,
    handleSelectPageCount,
    handleClickPrev,
    handleClickNext,
  } = usePagination(loyaltyPrograms, DEFAULT_PAGINATION.page, DEFAULT_PAGINATION.perPage);

  const [loyalties, setLoyalties] = useState(currentPageItems);

  useEffect(() => {
    setLoyalties(currentPageItems);
  }, [currentPageItems]);

  useEffect(() => {
    if (token) {
      dispatch(fetchLoyalties({ token }));
    }
  }, [dispatch, token]);

  const handleSetResult = (result) => {
    dispatch(setQueryDetails({ search: result }));
  };

  const handleSearchClean = () => {
    dispatch(setQueryDetails({ search: '' }));
  };

  const handleDeleteLoyaltyProgram = useCallback(
    (id) => {
      dispatch(
        showModal({
          modalId: 'modal-delete-loyalty',
          data: {
            type: 'confirmation',
            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete this loyalty',
            actions: [
              {
                title: 'Delete',
                onAction: () => {
                  dispatch(removeLoyalty({ id, token }));
                  dispatch(hideModal('modal-delete-loyalty'));
                },
              },
              { title: 'Cancel', button_variant: 'grey', onAction: () => dispatch(hideModal('modal-delete-loyalty')) },
            ],
          },
        }),
      );
    },
    [dispatch, token],
  );

  const handleAddNewOrderType = () => {
    navigate(`/business/loyalty_programs/new`);
  };

  const handleEditLoyaltyProgram = (id) => {
    navigate(`/business/loyalty_programs/${id}/edit`);
  };

  return (
    <>
      <PageTitle name={'Loyalty Program'} />
      <div className={styles.list_controller}>
        <div>
          <Button title={'Add Loyalty Program'} onClick={handleAddNewOrderType} />
        </div>
      </div>
      <LoyaltyProgramsList
        loyalties={loyalties}
        onEdit={handleEditLoyaltyProgram}
        onDelete={handleDeleteLoyaltyProgram}
        isLoading={loyaltyProgramsStatus === 'loading'}
      />
      <Pagination
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        numberOfItems={loyalties.length}
        currentPage={page}
        currentPageSize={perPage}
        onPageSizeChange={handleSelectPageCount}
        inputPaginationControl={{
          isActive: true,
          isPrevActive,
          isNextActive,
        }}
        onPrev={handleClickPrev}
        onNext={handleClickNext}
      />
    </>
  );
}

export default LoyaltyPrograms;
