import { api, handleApiResponse } from "./base";

export const getReportingEmails = (token) =>
  handleApiResponse(() =>
    api.get("reporting_emails", {
      headers: {
        TOKEN: token,
      },
    })
  );

export const addReportingEmail = (email, token) =>
  handleApiResponse(() =>
    api.post(
      `reporting_emails?email=${email}`,
      {},
      {
        headers: {
          TOKEN: token,
        },
      }
    )
  );

export const updateReportingEmail = (id, updatedEmail, token) =>
  handleApiResponse(() =>
    api.put(
      `reporting_emails/${id}?email=${updatedEmail}`,
      {},
      {
        headers: {
          TOKEN: token,
        },
      }
    )
  );

export const deleteReportingEmail = (id, token) =>
  handleApiResponse(() =>
    api.delete(`reporting_emails/${id}`, {
      headers: {
        TOKEN: token,
      },
    })
  );
