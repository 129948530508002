import React from 'react';

import HeaderReport from "../components/HeaderReport/header-report";
import clsx from "clsx";
import DynamicFilters from "../components/dynamic-filters";

import styles from './sales-overview.module.scss';
import ReportTable from "./report-table";

function SalesOverview() {

        const downloadPdf = () => {};
        const downloadCsv = () => {};

    const records = {
        gross_sale: 10000.00,
        discount: 500.00,
        refund: 200.00,
        wastage: 100.00,
        net_sales: 9200.00,
        total_tax: 800.00,
        tax_refunded: 50.00,
        total_tips: 300.00,
        tips_refund: 20.00,
        total_service_charge: 150.00,
        service_charge_refunded: 10.00,
        loyalty_rewards: 30.00,
        total_cash_discount: 40.00,
        total_surcharge: 60.00,
        convenience_fee: 25.00,
        delivery_fee: 15.00,
        cash_adjustment: 5.00,
        total_amount_collected: 10500.00
    };

  return (
      <div className={styles.contentWrapper}>
          <HeaderReport
              title={"Sales Overview"}
              downloadPdf={downloadPdf}
              downloadCsv={downloadCsv}
          />
          <div className={clsx("mb-4", styles.boxBody)}>
              <DynamicFilters/>
          </div>
          <div className={clsx("mb-4", styles.boxBody)}>
              <div className="col-12 card">
                  <h3 className="fs-16 fw-400 mb-3">Sales Summary</h3>
                  <div className="sales-summary">
                      <ReportTable records={records}/>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default SalesOverview;
